// @ts-check
import { Modal } from "react-bootstrap";
import { doNothing } from "./utils";

/**
 * @param {{
 *   isVisible?: boolean;
 *   onCancel?: () => void;
 *   onAccept?: () => void;
 * }} props
 */
export const ResubmitModal = ({
  isVisible = false,
  onCancel = doNothing,
  onAccept = doNothing,
}) => {
  return (
    // @ts-ignore
    <Modal show={isVisible} centered className="resubmit-modal">
      {/* @ts-ignore */}
      <Modal.Header>
        <p>Different Agent</p>
      </Modal.Header>
      {/* @ts-ignore */}
      <Modal.Body>
        <p>This quote was created by a different agent.</p>
        <p>Would you like to resubmit with you as the agent?</p>
      </Modal.Body>
      {/* @ts-ignore */}
      <Modal.Footer className="justify-center">
        <button onClick={onAccept}>Resubmit</button>
        <button onClick={onCancel}>Cancel</button>
      </Modal.Footer>
    </Modal>
  );
};
