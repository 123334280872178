import { auto, home } from "../constants";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "./styled/buttons/CustomButton";
import CustomMultiSelect from "./CustomMultiSelect";
import CustomSelect from "./styled/inputs/CustomSelect";
import { addContact } from "../redux/actions/contact";
import { capitalizeFirstChars } from "../utils/strings";
import { getCarrierList } from "../utils/quotes";
import styled from "styled-components";

export const FilterContainer = styled.div`
  background-color: ${(props) => props.theme.form_background};
  border-radius: 15px;
  margin-top: 20px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;

const QuotesFilter = ({
  selectedHomeCarriers,
  selectedAutoCarriers,
  setSelectedHomeCarriers,
  setSelectedAutoCarriers,
}) => {
  const dispatch = useDispatch();
  const contact = useSelector((store) => store.contact);
  const quote = useSelector((store) => store.quote);
  const quotes = quote?.quotes;
  const allHomeCarriers = getCarrierList(quotes, home, contact);
  const allAutoCarriers = getCarrierList(quotes, auto, contact);

  const handleChangeQuotesToShow = (e) => {
    const { value } = e.target;
    dispatch(
      addContact({
        insuredContact: value,
      })
    );
  };

  const handleFilterButtonClick = () => {
    // if selected value is null/undefined, all values are accepted
    const isHomeCarriersAll =
      !selectedHomeCarriers ||
      selectedHomeCarriers.length === allHomeCarriers.length;
    const isAutoCarriersAll =
      !selectedAutoCarriers ||
      selectedAutoCarriers.length === allAutoCarriers.length;

    dispatch(
      addContact({
        filteredHomeCarriers: isHomeCarriersAll
          ? undefined
          : selectedHomeCarriers,
        filteredAutoCarriers: isAutoCarriersAll
          ? undefined
          : selectedAutoCarriers,
      })
    );
  };

  return (
    <FilterContainer>
      <div className="d-flex align-items-center pb-3 row">
        <div className="col-1 mt-4 pt-2 ps-5">Filters:</div>
        {!!(contact.spouseHomeQuoteId || contact.spouseAutoQuoteId) && (
          <div className="col-3">
            <CustomSelect
              className="form-control"
              id="nameInsured"
              type="text"
              name="nameInsured"
              value={contact.insuredContact}
              handleChange={handleChangeQuotesToShow}
              label="First Named Insured"
              aria-label="First Named Insured"
              bordercolor="#6CB545"
            >
              <option value="best">Best</option>
              <option value="primary">{contact.firstName}</option>
              <option value="spouse">{contact.spousefirstName}</option>
            </CustomSelect>
          </div>
        )}
        {contact.homeQuoteId && (
          <div className="col-3">
            <CustomMultiSelect
              label="Home Carriers"
              allValues={allHomeCarriers}
              values={selectedHomeCarriers ?? allHomeCarriers}
              setValues={setSelectedHomeCarriers}
            />
          </div>
        )}
        {contact.autoQuoteId && (
          <div className="col-3">
            <CustomMultiSelect
              label="Auto Carriers"
              allValues={allAutoCarriers}
              values={selectedAutoCarriers ?? allAutoCarriers}
              setValues={setSelectedAutoCarriers}
            />
          </div>
        )}
        <div className="mt-4 col-2 pt-2">
          <CustomButton
            title="Apply"
            reduced
            small
            color="#6CB545"
            handleClick={handleFilterButtonClick}
          />
        </div>
      </div>
    </FilterContainer>
  );
};

export default QuotesFilter;
