import { getLobIcon } from "../utils";
import { LobIcon, QuoteRow } from "./styled/QuoteResponse";

const QuoteWrapper = ({ lob, children }) => {
  const lobIcon = getLobIcon(lob);
  return (
    <QuoteRow>
      <LobIcon src={lobIcon} alt={lob} />
      {children}
    </QuoteRow>
  );
};

export default QuoteWrapper;
