import { default as ReactSelect, components } from "react-select";

import { All } from "../constants";
import { CustomText } from "./styled/typography";
import styled from "styled-components";

const LabelWrapper = styled.div`
  margin-bottom: 10px;
`;

const getOptions = (values, { shouldAddAll = false }) => {
  const fullValues = !shouldAddAll
    ? values
    : [All, ...values.filter((value) => value !== All)];
  return (
    fullValues?.map((value) => ({
      value,
      label: value,
    })) || []
  );
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label className="ms-3">{props.label}</label>
      </components.Option>
    </div>
  );
};

const CustomMultiSelect = ({
  label,
  allValues,
  values,
  setValues,
  labelPosition = "top",
}) => {
  const isAllSelected = values.length === allValues.length;

  const options = getOptions(allValues, { shouldAddAll: true });
  const selectedOptions = getOptions(values, {
    shouldAddAll: isAllSelected,
  });

  const placeholderValue = isAllSelected
    ? "All Carriers"
    : `${selectedOptions.length} Carriers Selected`;

  const handleChange = (newOptions) => {
    const newCarriers = newOptions.map(({ value }) => value);

    const shouldSelectAll = !isAllSelected && newCarriers.includes(All);
    const shouldClearAll = isAllSelected && !newCarriers.includes(All);

    if (shouldClearAll) {
      setValues([]);
      return;
    }
    if (shouldSelectAll) {
      setValues(allValues);
      return;
    }
    setValues(newCarriers.filter((value) => value !== All));
  };

  return (
    <div
      className={`${
        labelPosition === "left"
          ? "d-flex justify-content-between align-items-center"
          : "d-flex flex-column align-items-start"
      }`}
    >
      {label && (
        <LabelWrapper
          className={`${labelPosition === "left" ? "me-4" : "ms-2"}`}
        >
          <CustomText fontSize="small" fontFamily="font_secondary">
            {label}
          </CustomText>
        </LabelWrapper>
      )}
      <ReactSelect
        options={options}
        isMulti
        placeholder={placeholderValue}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        controlShouldRenderValue={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        allowSelectAll={true}
        value={selectedOptions}
        styles={{
          option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted #6CB545",
            paddingLeft: "10px",
            backgroundColor: state.isSelected ? "white" : "",
            color: "black",
          }),
          control: (style, state) => ({
            ...style,
            minHeight: "50px",
            width: "248px",
            fontSize: "rem(15px)",
            fontWeight: "400",
            borderRadius: "25px",
            border: state.isFocused ? "2px solid #6CB545" : "1px solid #6CB545",
            padding: "5px",
            boxShadow: 0,
            outline: "none",
            "&:hover": {
              border: state.isFocused
                ? "2px solid #6CB545"
                : "1px solid #6CB545",
            },
          }),
          dropdownIndicator: (style) => ({ ...style, color: "#6CB545" }),
          clearIndicator: (style) => ({ ...style, color: "#6CB545" }),
        }}
      />
    </div>
  );
};

export default CustomMultiSelect;
