import _ from "lodash";
import { addContact } from "../redux/actions/contact";
import { calculateDwellingCoverage } from "./misc";
import { capitalizeFirstChar } from "./strings";
import { getRaterDefaults } from "../services/quote";
import moment from "moment";
import store from "../redux/store";
import {
  BASEMENT,
  foundationMap,
  wallMap,
  allowedFoundations,
} from "../constants";

/**
 * @param {object} propertyData - Property data obtained from core logic.
 * @returns {object} Property details containing appropriate fields.
 *
 *   - This function maps property details from input to appropriate fields.
 */
export const processImprovements = (propertyData) => {
  let improvements = propertyData._PROPERTY_CHARACTERISTICS._IMPROVEMENTS;
  let site = propertyData._PROPERTY_CHARACTERISTICS._SITE;
  let lastSalesDate =
    propertyData._PROPERTY_HISTORY._SALES_HISTORY._LastSalesDate;
  // const roofYear = new Date().getFullYear() - 10;
  return {
    hasPool: improvements._FEATURES._POOL._HasFeatureIndicator !== "N",
    squareFoot:
      parseInt(improvements._ROOM_COUNT._TotalLivingAreaSquareFeetNumber) ||
      undefined,
    yearBuilt:
      parseInt(improvements._GENERAL_DESCRIPTION._YearBuiltDateIdentifier) ||
      undefined,
    numStories: improvements._GENERAL_DESCRIPTION._TotalStoriesNumber || "1",
    exteriorWalls: wallMap[
      improvements._EXTERIOR_DESCRIPTION._ExteriorWallsIdentifier
    ]
      ? wallMap[improvements._EXTERIOR_DESCRIPTION._ExteriorWallsIdentifier]
      : "Brick Veneer",
    roofShape: improvements._EXTERIOR_DESCRIPTION._RoofShape || "",
    roofReplaced: undefined,
    roofMaterial: "Shingles",
    lastSalesDate: lastSalesDate,
    landUseDescription: getLandUseDescription(site),
    foundationType: getFoundationType(improvements),
  };
};

export const getLandUseDescription = (site) => {
  const landUseDescription = site?._CHARACTERISTICS?._LandUseDescription || "";

  if (
    ["MOBILE", "MANUFACTURED"].some((choice) =>
      landUseDescription.toUpperCase().includes(choice)
    )
  ) {
    return "SFR";
  }
  return landUseDescription;
};

/**
 * Returns foundation type based on improvements object from corelogic
 *
 * @param {object} improvements - Corelogic improvements object containing
 *   foundation type and basement type
 * @returns {string} Foundation type Slab, Pier & Beam, Basement or empty string
 */
export const getFoundationType = (improvements) => {
  const foundationType = improvements._FOUNDATION?._MaterialDescription ?? "";
  const basementType = improvements._BASEMENT?._Type ?? "";
  return (
    foundationMap[foundationType.toLowerCase().replace(/\s+/g, "")] ??
    (basementType
      ? foundationMap[basementType.toLowerCase().replace(/\s+/g, "")] ??
        BASEMENT
      : "")
  );
};

/**
 * @param {object} address - Address details
 *
 *   - This function adds owner details to contact (including spouse)
 */
export const handleOwner = (ownerData) => {
  const contactToAdd = {};
  if (ownerData.length > 1) {
    if (ownerData[0]._FirstName && ownerData[0]._LastName) {
      contactToAdd.firstName = ownerData[0]._FirstName.split(" ")[0];
      contactToAdd.lastName = ownerData[0]._LastName;
    }
    if (ownerData[1]._FirstName && ownerData[1]._LastName) {
      contactToAdd.spousefirstName = ownerData[1]._FirstName.split(" ")[0];
      contactToAdd.spouselastName = ownerData[1]._LastName;
      contactToAdd.maritalStatus = "Married";
    }
  } else {
    if (ownerData._FirstName && ownerData._LastName) {
      contactToAdd.firstName = ownerData._FirstName.split(" ")[0];
      contactToAdd.lastName = ownerData._LastName;
    }
  }
  store.dispatch(addContact(contactToAdd));
};

export const clearPropertyOwnerDetails = () => {
  const contact = store.getState().contact;
  const newContact = { ...contact };
  [
    "firstName",
    "lastName",
    "spousefirstName",
    "spouselastName",
    "maritalStatus",
    "dob",
    "gender",
    "spouseDob",
    "suffix",
    "spouseSuffix",
  ].forEach((value) => {
    newContact[value] = undefined;
  });
  store.dispatch(
    addContact({
      ...newContact,
    })
  );
};

export const getDwellingCoverage = async ({ squareFoot, state, zipcode }) => {
  const raterdefaultsData = await getRaterDefaults(state, zipcode);

  const raterdefaults = raterdefaultsData.data;

  let dollarPerSquareFoot = 0;

  const session = store.getState().session;
  if (session.line_of_business.includes("Home")) {
    dollarPerSquareFoot = raterdefaults.dollars_per_square_feet__c;
  } else if (session.line_of_business.includes("Condo")) {
    dollarPerSquareFoot = raterdefaults.condo_sq_ft__c;
  }

  dollarPerSquareFoot = parseFloat(dollarPerSquareFoot);

  const dwellingCoverage = calculateDwellingCoverage(
    squareFoot,
    dollarPerSquareFoot
  );
  return dwellingCoverage;
};

export const mapPropertyDataFromPropertyLinkedToPolicy = (propertyDetails) => {
  return {
    street_number: propertyDetails.Name,
    locality: propertyDetails.City__c,
    administrative_area_level_2: propertyDetails.County__c
      ? `${propertyDetails.County__c} County`
      : "",
    administrative_area_level_1: propertyDetails.State__c,
    country: "US",
    postal_code: propertyDetails.Zip__c,
    foundationType: propertyDetails.Foundation__c,
    hasPool: propertyDetails.Swimming_Pool__c,
    squareFoot: propertyDetails.Square_Footage__c,
    yearBuilt: propertyDetails.Year_Built__c,
    numStories: propertyDetails.Number_of_Stories__c,
    exteriorWalls: propertyDetails.Exterior_Walls__c,
    roofShape: propertyDetails.Roof_Design__c,
    roofMaterial:
      propertyDetails.Roof_Type__c === "Composition"
        ? "Shingles"
        : propertyDetails.Roof_Type__c,
    roofReplaced: propertyDetails.Year_Roof_Replaced__c,
    lastSalesDate: propertyDetails.Purchase_Date__c
      ? moment(propertyDetails.Purchase_Date__c).format("YYYYMMDD")
      : undefined,
  };
};

export const getFullMailingAddr = (addressData = {}) => {
  //_.pickBy iterates over the obj keys and keeps only those that CB _.identity returns truthy for
  const { street_number, locality, administrative_area_level_1, postal_code } =
    addressData;
  const adrsObj = _.pickBy(
    Object.assign(
      {},
      { street_number, locality, administrative_area_level_1, postal_code }
    ),
    _.identity
  );
  return _.isEmpty(adrsObj) ? "" : Object.values(adrsObj).join(", ");
};

export const isPropertyThirtyYearsOld = (yearBuilt) => {
  if (!yearBuilt) return false;
  return moment().year() - yearBuilt > 30;
};

export const mapFoundationFromPolicy = (foundation) => {
  return allowedFoundations.includes(foundation) ? foundation : "";
};
