import { StyledQuoteAlert, StyledQuoteAlertTooltip } from "../styled";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";

export const QuoteAlert = ({
  tooltipText,
  alertIcon,
  alertText,
  $backgroundColor,
  $color,
  $paddingX,
}: {
  tooltipText: string;
  alertIcon: React.JSX.Element;
  alertText?: string;
  $backgroundColor: string;
  $color: string;
  $paddingX?: string;
}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <StyledQuoteAlertTooltip
          className="required-tooltip"
          $backgroundColor={$backgroundColor}
          $color={$color}
        >
          {tooltipText}
        </StyledQuoteAlertTooltip>
      }
    >
      <StyledQuoteAlert
        $backgroundColor={$backgroundColor}
        $paddingX={$paddingX}
      >
        <div className="alert-icon">{alertIcon}</div>
        {alertText && <span className="alert-text">{alertText}</span>}
      </StyledQuoteAlert>
    </OverlayTrigger>
  );
};
