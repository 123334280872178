import { FieldArray, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import {
  deleteDrivers,
  saveDriversInContact,
  saveDriversinPg,
} from "../services/drivers";
import {
  handleUpdateAccount,
  handleUpdateHomeQuotes,
  isDwelling,
  mapDriversToInput,
  setFieldsEdited,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomButton from "../components/styled/buttons/CustomButton";
import Disclaimer from "../components/Disclaimer";
import { DriverInputs } from "../components/drivers-vehicles";
import Heading from "../components/Heading";
import { IconImage } from "../components/styled/images";
import LinkButton from "../components/styled/buttons/LinkButton";
import LoadingDots from "../components/LoadingDots";
import { addContact } from "../redux/actions/contact";
import { addDrivers } from "../redux/actions/driver";
import { capitalize } from "lodash";
import { contactDetailsPageDisclosure } from "../utils/constants";
import { createErrorLogger } from "../utils/errors";
import { driverValidationSchema } from "../utils/validation";
import dwellingIcon from "../assets/images/main/dwelling-icon.svg";
import { getPagenumber } from "../utils/strings";
import { handleBranchQuote } from "../utils/quotes";
import { handleUpdateSession } from "../services/utils";
import homeIcon from "../assets/images/main/home-icon.svg";
import { pageIndices } from "../constants";
import { resetQuotesStatus } from "../utils/resubmit";
import { skipButtonTitle } from "../utils/misc";
import { updateIsFetching } from "../redux/actions/fetching";

const logError = createErrorLogger({ file: "Drivers.js" });

const Drivers = ({ handleNext, setIndices }) => {
  const params = useParams();

  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const isFetching = useSelector((store) => store.isFetching);
  const drivers = useSelector((store) => store.drivers);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const lob = params.line_of_business;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleSkipAuto = async () => {
    try {
      dispatch(
        addContact({ autoQuoteId: undefined, spouseAutoQuoteId: undefined })
      );
      const sessionData = await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: lob.replace("Auto", ""),
        page_stopped: getPagenumber(lob.replace("Auto", ""), "Responses"),
        contact_data: JSON.stringify({
          ...contact,
          autoQuoteId: undefined,
          spouseAutoQuoteId: undefined,
        }),
      });
      await handleUpdateAccount();
      await handleUpdateHomeQuotes();
      await resetQuotesStatus();
      navigate(`/agent/${sessionData.line_of_business}/Responses`);
      setIndices(pageIndices[sessionData.line_of_business]);
    } catch (e) {
      console.log("Something has gone wrong ", e);
    }
  };

  // WARNING: this will run on mount, so it might overwrite the contact in Redux store,
  // causing the homeQuoteId/autoQuoteId to be defined when it shouldn't
  useEffect(() => {
    session.auto_quote_id &&
      dispatch(addContact({ autoQuoteId: session.auto_quote_id }));
    dispatch(updateIsFetching(false));
    //eslint-disable-next-line
  }, [session.auto_quote_id]);

  useEffect(() => {
    session.home_quote_id &&
      dispatch(addContact({ homeQuoteId: session.home_quote_id }));
    dispatch(updateIsFetching(false));
    //eslint-disable-next-line
  }, [session.home_quote_id]);

  const driversToDeleteRef = useRef(new Set());

  const defaultDriver = {
    firstName: "",
    lastName: "",
    suffix: "",
    dob: "",
    gender: "",
    driverLicense: "",
    dLState: contact.administrative_area_level_1,
    education: "",
    occupation: "",
    relationship: "Child",
    maritalStatus: "Single",
    selected: true, // if true, it will be in the columns
    // if false, it will be on the side bar
  };

  const initialDrivers = drivers
    .filter(({ selected, isRemoved }) => selected && !isRemoved)
    .map((driver) => mapDriversToInput(driver));

  const driversFound = drivers
    .filter(({ selected, isRemoved }) => !selected && !isRemoved)
    .map((driver) => mapDriversToInput(driver));

  const removedDrivers = drivers
    .filter(({ selected, isRemoved }) => !selected && isRemoved)
    .map((driver) => mapDriversToInput(driver, ["contactId"]));
  // removed drivers do not have a contactId as they are not in the contact table

  const handleSubmit = async (values) => {
    try {
      setSubmitButtonDisabled(true);
      const deleteDriverPromises = [...driversToDeleteRef.current].map(
        (driverId) => deleteDrivers(driverId)
      );
      await Promise.all(deleteDriverPromises);

      let driversToSubmit = values.items;
      const driversContactIds = (
        await saveDriversInContact({
          accountId: contact.accountId,
          quoteId: contact.autoQuoteId,
          agentSfid: session.agent_sfid,
          propertyStreetNumber: contact.street_number,
          propertyUnitNumber: contact.unitNumber,
          propertyLocality: contact.locality,
          propertyPostalCode: contact.postal_code,
          propertyState: contact.administrative_area_level_1,
          drivers: values.items.map((driver) => {
            return {
              ...driver,
              driverLicense: driver.driverLicense ?? "",
            };
          }),
        })
      ).data;
      driversToSubmit = driversToSubmit.map((driver, index) => ({
        ...driver,
        contactId: driversContactIds[index],
      }));

      const driverContactIds = (
        await saveDriversinPg({
          quoteId: contact.autoQuoteId,
          drivers: driversToSubmit,
        })
      ).data.driversData;
      driversToSubmit = driversToSubmit.map((driver, index) => {
        if (driverContactIds[index].contactId === driver.contactId) {
          return {
            ...driver,
            driverLicense: driver.driverLicense,
            driverId: driverContactIds[index].id,
          };
        } else {
          return {
            ...driver,
            driverLicense: driver.driverLicense,
          };
        }
      });

      const driversSelected = driversToSubmit.map((driver) => ({
        ...driver,
        isRemoved: false,
        selected: true,
      }));
      const driversFound = values.itemsFound.map((driver) => ({
        ...driver,
        isRemoved: false,
        selected: false,
      }));
      const removedDrivers = values.removedItems.map((driver) => ({
        ...driver,
        isRemoved: true,
        selected: false,
        driverId: undefined,
        contactId: undefined,
      }));

      dispatch(
        addDrivers([...driversSelected, ...removedDrivers, ...driversFound])
      );
      handleBranchQuote(true);
      await handleNext();
    } catch (error) {
      logError({
        fn: "handleSubmit",
        message: "Something went wrong",
        error,
      });
    } finally {
      setSubmitButtonDisabled(false);
    }
  };

  return isFetching ? (
    <LoadingDots />
  ) : (
    <>
      <Heading text="Drivers" bold className="mt-5" />
      <Formik
        initialValues={{
          items: initialDrivers,
          itemsFound: driversFound,
          removedItems: removedDrivers,
        }}
        onSubmit={handleSubmit}
        validationSchema={driverValidationSchema}
        validateOnBlur={false}
      >
        {({ values }) => (
          <Form>
            <FieldArray name="items">
              {({ push, remove }) => {
                const removeDriver = (index) => {
                  setFieldsEdited();
                  if (values.items[index].driverId) {
                    driversToDeleteRef.current.add(
                      values.items[index].driverId
                    );
                  }
                  remove(index);
                };
                const addNewDriver = (driver) => {
                  driversToDeleteRef.current.delete(driver?.driverId);
                  push(driver ?? defaultDriver);
                };
                return (
                  <DriverInputs
                    addNewDriver={addNewDriver}
                    removeDriver={removeDriver}
                  />
                );
              }}
            </FieldArray>
            <div className="d-flex flex-column gap-2 align-items-center">
              <CustomButton
                type="submit"
                title="Continue"
                disabled={submitButtonDisabled}
              />
              {lob !== "Auto" && (
                <>
                  <div className="justify-content-center my-3 d-flex">
                    <IconImage
                      src={isDwelling() ? dwellingIcon : homeIcon}
                      className="img-fluid mr-1"
                      alt="home-icon"
                    />
                    <LinkButton
                      type="button"
                      text={`Submit ${
                        isDwelling()
                          ? "Dwelling"
                          : capitalize(skipButtonTitle(lob))
                      } Only`}
                      handleClick={handleSkipAuto}
                    />
                  </div>
                  <Disclaimer
                    text={contactDetailsPageDisclosure}
                    centered={true}
                  />
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Drivers;
