// @ts-check
import { useSelector } from "react-redux";
import flow from "lodash/flow";

// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars

/**
 * @param {{
 *   quoteResponse: Pick<AppTypes.QuoteResponse, "carrier_login__c">;
 * }} input
 */
export const useGetAgents = ({ quoteResponse }) => {
  // @ts-ignore - Property 'contact' does not exist on type 'DefaultRootState'.
  const contact = useSelector((store) => store.contact);
  // @ts-ignore
  const session = useSelector((store) => store.session);

  const getAgents = () => {
    const original = quoteResponse.carrier_login__c;
    const current =
      session.logged_in_user ?? contact.newLoggedInUser ?? contact.agentUserId;

    return {
      original,
      current,
    };
  };

  return getAgents;
};

/** @param {Parameters<typeof useGetAgents>[0]} input */
export const useAgents = ({ quoteResponse }) =>
  useGetAgents({ quoteResponse })();

/** @param {{ original: any; current: any }} agents */
export function getAreAgentsDifferent(agents) {
  if (!agents.original) return false;
  if (agents.current === agents.original) return false;

  return true;
}

/**
 * @type {(
 *   ...args: Parameters<typeof useGetAgents>
 * ) => ReturnType<typeof getAreAgentsDifferent>}
 */
export const useAreAgentsDifferent = flow(useAgents, getAreAgentsDifferent);
