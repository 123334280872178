import { pageIndices, pageNames } from "../constants";

import { capitalize } from "lodash";
import moment from "moment";
import store from "../redux/store";

export const convertToDollars = (
  amount,
  shouldUseQuestionMarkForEmpty = true
) => {
  let result;
  if (amount === "" || amount === null) {
    result = shouldUseQuestionMarkForEmpty ? "?" : "";
  } else if (
    (typeof amount === "string" &&
      (amount.toLowerCase() === "none" || ["0", "0000"].includes(amount))) ||
    (typeof amount === "number" && amount === 0)
  ) {
    result = "None";
  } else {
    result =
      "$" +
      parseInt(amount)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (result === "$NaN") {
    result = shouldUseQuestionMarkForEmpty ? "?" : "";
  }
  return result;
};

export const convertToCurrency = (amount) =>
  parseInt(amount)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const convertCurrencyToNumber = (currency) =>
  currency.replace(/[^0-9.-]+/g, "");

export const includesIntegers = (value) => /\d/.test(value);

export const includesOnlyIntegers = (value) => /^\d+$/.test(value);

export const isValidStreetNumber = (streetNumber) =>
  !!streetNumber && !!streetNumber.split(" ")[0];
export const isValidAddress = (addressData) => {
  return (
    addressData.street_number &&
    isValidStreetNumber(addressData.street_number) &&
    addressData.route &&
    addressData.locality &&
    addressData.administrative_area_level_1 &&
    addressData.postal_code
  );
};

const DO_NOT_CAPITALIZE_WORDS = new Set([
  "the",
  "a",
  "an",
  "to",
  "of",
  "and",
  "or",
  "for",
]);

/**
 * Capitalizes the first character of a string.
 *
 * @param {any} str
 * @returns {string} The string with the first character capitalized.
 */
export const capitalizeFirstChar = (str) => {
  if (typeof str !== "string") return "";
  if (DO_NOT_CAPITALIZE_WORDS.has(str)) return str;

  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

/**
 * DEPRECATED: Capitalizes the first character of each word in a string.
 *
 * @param {any} name
 * @returns {string} The string with the first character of each word
 *   capitalized.
 */
//todo: remove this function
export const capitalizeFirstChars = (name) => {
  if (typeof name !== "string") return "";

  return name
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .split(" ")
    .map((word) => word.split("-").map(capitalizeFirstChar).join("-"))
    .join(" ");
};

export const combinableCarriers = [
  "168",
  "113",
  "542",
  "507",
  "472",
  "102",
  "436",
  "464",
  "501",
  "431",
  "476",
  "160",
  "363",
  "425",
  "442",
];

export const getLineOfBusiness = (lob) => {
  if (lob?.indexOf("Auto") === -1) return "Home";
  else if (lob?.indexOf("Auto") === 0) return "Auto";
  else return "HomeAuto";
};

export const isValidDate = (date, format = "YYYY-MM-DD") => {
  const validDate = moment(date, format, true);
  return validDate.isValid() && validDate.year() > 1900;
};

export const isPastDate = (date, format = "YYYY-MM-DD") => {
  const today = moment(new Date(), format);
  const inputDate = moment(date, format);

  let dDiff = today.diff(inputDate, "days");
  if (dDiff > 0) {
    return true;
  } else {
    return false;
  }
};

export const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

export const validPhoneRegex = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/i;

export const getPageName = (lob, pageNumber) =>
  pageNames[pageIndices[lob]?.indexOf(parseInt(pageNumber))];

export const getPagenumber = (lob, pageName) =>
  pageIndices[lob][pageNames.indexOf(pageName)];

export const getResponsesPage = () => pageNames[pageNames.length - 1];

export const getExpectedResponseCountByLob = (lob, hasSpouse) => {
  const expectedResponses = {
    HomeAuto: 8,
    RenterAuto: 8,
    CondoAuto: 8,
    Home: 2,
    Renter: 2,
    Auto: 2,
    Condo: 2,
  };
  return hasSpouse ? expectedResponses[lob] * (3 / 2) : expectedResponses[lob];
};

export const getPremiumPerMonth = (quote) => {
  if (quote.premium_total_ppm__c) {
    return quote.premium_total_ppm__c;
  } else if (quote.premium_total__c && quote.term__c) {
    return quote.premium_total__c / parseInt(quote.term__c);
  }
};

export const getUMPDCoverage = (state, BI) => {
  let bodilyInjuryUMPDMap = {
    "50/100": "No Coverage",
    "100/300": "No Coverage",
    "250/500": "No Coverage",
    "100 CSL": "No Coverage",
    "300 CSL": "No Coverage",
    "500 CSL": "No Coverage",
    "1 Mil CSL": "No Coverage",
  };
  if (
    [
      "AR",
      "DC",
      "IN",
      "MD",
      "MS",
      "NC",
      "NH",
      "NJ",
      "NM",
      "OR",
      "RI",
      "SC",
      "TN",
      "VA",
      "WA",
      "WV",
      "TX",
    ].includes(state)
  ) {
    bodilyInjuryUMPDMap = {
      ...bodilyInjuryUMPDMap,
      "50/100": "50000",
      "100/300": "100000",
      "250/500": "100000",
    };
  } else if (state === "ID") {
    bodilyInjuryUMPDMap = {
      ...bodilyInjuryUMPDMap,
      "50/100": "50000",
      "100/300": "50000",
      "250/500": "50000",
    };
  }
  return bodilyInjuryUMPDMap[BI] || "No Coverage";
};

export const getLobName = (lob) => {
  if (lob.startsWith("life")) return "Life";
  return capitalize(lob);
};

export const getPropertyAddress = () => {
  const { property } = store.getState();

  if (!property?.street_number) {
    return null;
  }

  const unitText = property.unitNumber ? `Unit ${property.unitNumber}, ` : "";
  return `${property.street_number}, ${unitText}${property.locality} ${property.administrative_area_level_1} ${property.postal_code}`;
};

export const getCurrentAddress = () => {
  const { contact } = store.getState();
  const unitText = contact.unitNumber ? `Unit ${contact.unitNumber}, ` : "";
  return `${contact.street_number}, ${unitText}${contact.locality} ${contact.administrative_area_level_1} ${contact.postal_code}`;
};

export const removeCountyText = (county) => {
  return county?.replace(" County", "") ?? "";
};
