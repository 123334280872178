const getPrimaryAndSpouseLifeQuotes = (quotes) => {
  if (quotes?.life === null) {
    return {
      lifeQuotesPrimary: null,
      lifeQuotesSpouse: null,
    };
  }

  const { life: lifeQuotes } = quotes;

  const lifePrimary = lifeQuotes.filter(({ quote_response_external_id__c }) =>
    quote_response_external_id__c.includes("Insured")
  );
  const lifeSpouse = lifeQuotes.filter(({ quote_response_external_id__c }) =>
    quote_response_external_id__c.includes("Spouse")
  );

  return {
    lifePrimary,
    lifeSpouse,
  };
};

export default getPrimaryAndSpouseLifeQuotes;
