import ReactSelect, { components } from "react-select";
import styled, { css } from "styled-components";

import searchIcon from "../../../assets/images/components/searchIcon.svg";

const SelectWrapper = styled.div`
  margin: ${(props) => (props.isCentered ? "auto" : undefined)};
  position: relative;
  ::after {
    content: "▼";
    color: #6d6e71;
    height: 10px;
    width: 15px;
    font-size: larger;
    position: absolute;
    right: 15px;
    top: 52px;
    transform: translateY(-70%);
    pointer-events: none;
  }
  @media (min-width: 992px) {
    ${(props) =>
      props.seterrorheight &&
      props.error &&
      css`
        top: ${(props) => props.error && "-3px"};
      `}
    ::after {
      ${(props) =>
        props.seterrorheight &&
        css`
          top: 38%;
        `}
    }

    & > small {
      ${(props) =>
        props.seterrorheight &&
        css`
          display: inline-block;
          height: 50px;
        `}
    }
  }
`;

const Select = styled.select`
  background: white;
  height: 50px;
  color: ${(props) =>
    props.value ? props.theme.primary : props.theme.light_gray_alt};
  font-size: rem(15px);
  padding: 12px 10px;
  font-weight: 400;
  border-radius: 25px;
  border: ${(props) =>
    `1px solid ${props.bordercolor ? props.bordercolor : "#707070"}`};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  ::-ms-expand {
    display: none;
  }
  ::placeholder {
    font-weight: 200;
    opacity: 70%;
  }
`;

const Label = styled.label`
  font-size: 15px;
  margin-left: 3px;
  margin-bottom: 10px;
  color: ${(props) =>
    props.hideLabel
      ? "transparent"
      : props.dark
      ? props.theme.white
      : props.theme.primary};
`;

const CustomSelect = (props) => {
  const {
    handleChange,
    dark,
    wrapperClassName,
    isCentered = true,
    ...rest
  } = props;
  return (
    <SelectWrapper
      className={wrapperClassName}
      isCentered={isCentered}
      error={props.error}
      seterrorheight={props.seterrorheight}
    >
      {props.label && (
        <Label dark={dark} hideLabel={props.hideLabel}>
          {props.label}
        </Label>
      )}
      <Select {...rest} onChange={props.handleChange}>
        {props.children}
      </Select>
      <small className="fw-light font-italic text-danger">{props.error}</small>
    </SelectWrapper>
  );
};

export default CustomSelect;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img src={searchIcon} alt="search" />
  </components.DropdownIndicator>
);

export const TypeAheadSelect = (props) => {
  return (
    <ReactSelect
      classNamePrefix="type-ahead-select"
      components={{
        DropdownIndicator,
      }}
      menuPortalTarget={document.body}
      placeholder={null}
      searchable
      clearable
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          textAlign: "left",
          fontSize: "1rem",
          borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
          boxShadow: state.isFocused
            ? "0 0 0 0.25rem rgba(13,110,253,.25)"
            : "none",
          borderRadius: "999px",
          "&:hover": {
            borderColor: undefined,
          },
        }),
      }}
      {...props}
    />
  );
};
