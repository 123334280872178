import { Col } from "react-bootstrap";
import styled from "styled-components";
import { getLobIcon } from "../utils";
import CustomButton from "./styled/buttons/CustomButton";
import { IconImg, StyledRateRow } from "./styled/QuoteResponse";

export const CarrierName = styled.span`
  font-weight: 500;
  color: #358640;
  font-size: 19px;
  font-family: ${(props) => props.theme["font_secondary"]};
  @media (max-width: 539px) {
    font-size: 0.8rem;
  }
`;

const ManualQuote = ({ quote, lob }) => {
  const lobIcon = getLobIcon(lob);

  const handleBridge = () => {
    let redirectURL = quote.website || "";
    if (
      redirectURL &&
      !redirectURL.startsWith("http://") &&
      !redirectURL.startsWith("https://")
    ) {
      redirectURL = `https://${redirectURL}`;
    }
    const bridgeWindow = window.open(redirectURL);
    bridgeWindow.focus();
  };

  return (
    <StyledRateRow>
      <Col xs={6}>
        <div className="row no-gutters">
          <Col xs={2} className="d-flex flex-column justify-content-center">
            <IconImg src={lobIcon} alt="" className="img-fluid" />
          </Col>
          <Col xs={10} className={`ps-3 pt-3`}>
            <CarrierName> {quote.carrier}</CarrierName>
          </Col>
        </div>
      </Col>
      <Col className="d-flex justify-content-end" xs={6}>
        <CustomButton
          handleClick={handleBridge}
          whiteIcon
          title="Quote"
          color={quote.carrier__c === "Branch" ? "#A7C8E6" : "#358640"}
          small
        />
      </Col>
    </StyledRateRow>
  );
};

export default ManualQuote;
