import { useDispatch, useSelector } from "react-redux";

import CustomButton from "./styled/buttons/CustomButton";
import { QuotePlaceHolderText } from "./styled/QuoteResponse";
import QuoteWrapper from "./QuoteWrapper";
import { addContact } from "../redux/actions/contact";
import { capitalize } from "lodash";
import { getPagenumber } from "../utils/strings";
import { handleUpdateSession } from "../services/utils";
import { useNavigate } from "react-router-dom";

const QuotePlaceHolder = ({ lob }) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const isFetchingQuotes = useSelector((store) => store.isFetchingQuotes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <QuoteWrapper lob={lob}>
      <QuotePlaceHolderText>
        Add {capitalize(lob)} Insurance
      </QuotePlaceHolderText>
      <div className="d-flex flex-grow-1">
        <CustomButton
          className="ms-auto"
          disabled={isFetchingQuotes}
          handleClick={async () => {
            if (lob === "auto") {
              dispatch(
                addContact({
                  ...contact,
                  autoQuoteId: session.auto_quote_id,
                  addSkippedAutoQuote: true,
                })
              );
              await handleUpdateSession({
                uuid: session.uuid,
                line_of_business: `${session.line_of_business}Auto`,
                page_stopped: getPagenumber(
                  `${session.line_of_business}Auto`,
                  "AboutYou"
                ),
                contact_data: JSON.stringify({
                  ...contact,
                  autoQuoteId: session.auto_quote_id,
                  addSkippedAutoQuote: true,
                }),
              });
              navigate(`/agent/${session.line_of_business}Auto/AboutYou`);
            } else {
              dispatch(
                addContact({
                  ...contact,
                  addSkippedHomeQuote: true,
                })
              );
              await handleUpdateSession({
                uuid: session.uuid,
                line_of_business: "HomeAuto",
                page_stopped: getPagenumber("HomeAuto", "PolicyType"),
                contact_data: JSON.stringify({
                  ...contact,
                  addSkippedHomeQuote: true,
                }),
              });
              navigate("/agent/HomeAuto/PolicyType");
            }
          }}
          title={`Add ${capitalize(lob)}`}
          medium
        />
      </div>
    </QuoteWrapper>
  );
};

export default QuotePlaceHolder;
