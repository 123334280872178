// @ts-check
import * as quoteService from "../../services/quote";
import { isAxiosError } from "../../utils/http";
import * as httpStatus from "../../utils/http.status";
import { TimeoutError, pollPromise } from "../../utils/poll";
// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars
import * as ENV from "./env";

export const POLL_STATUS = /** @type {const} */ ({
  initialized: "initialized",
  submitted: "submitted",
  completed: "completed",
  notFound: "not_found",
});

/**
 * @param {{
 *   currentAgentId: string;
 *   quoteResponse: Pick<
 *     AppTypes.QuoteResponse,
 *     "quote_request_heroku_id" | "company_client_id__c" | "package_rate__c"
 *   >;
 *   options?: { interval?: number; numRetries?: number; maxTimeout?: number };
 * }} input
 * @returns {Promise<AppTypes.QuoteResponse>}
 */
export async function pollQuoteResponse({
  quoteResponse,
  currentAgentId,
  options,
}) {
  return /** @type {Promise<AppTypes.QuoteResponse>} */ (
    pollPromise(
      async () => {
        const response = await quoteService
          .checkResubmitQuoteResponseStatus({
            quoteRequestId: quoteResponse.quote_request_heroku_id,
            carrierId: String(quoteResponse.company_client_id__c),
            isPackageable: quoteResponse.package_rate__c,
            sfAgentId: currentAgentId,
          })
          .catch((error) => {
            if (
              isAxiosError(error) &&
              httpStatus.isClientError(error?.response?.status)
            ) {
              throw new TimeoutError(
                "Failed to get new quote response, due to client error"
              );
            }

            throw error;
          });

        if (
          isBadResponse(response) ||
          ![POLL_STATUS.completed].includes(response.data?._meta?.status)
        ) {
          throw new Error("Failed to get new quote response");
        }

        return response.data?.data;
      },
      {
        interval: getInterval(options),
        timeout: {
          milliseconds:
            options?.maxTimeout ?? ENV.RESUBMIT_RESPONSE_POLL_TIMEOUT,
        },
        before: false,
      }
    )
  );
}

/** @param {{ interval?: number; numRetries?: number; maxTimeout?: number }} input */
function getInterval({ interval, numRetries, maxTimeout } = {}) {
  if (interval) {
    return interval;
  }

  if (numRetries && maxTimeout) {
    return maxTimeout / numRetries;
  }

  return ENV.RESUBMIT_RESPONSE_POLL_INTERVAL;
}

/** @param {import("axios").AxiosResponse<any, any>} x */
export function isBadResponse(x) {
  return !x || isAxiosError(x) || !httpStatus.isSuccess(x.status);
}
