import * as salesForce from "../../utils/sales-force";

import {
  ButtonContainer,
  ButtonWrapper,
  CarrierIconColumn,
  CarrierLogoImg,
  ErrorMessage,
  IconImg,
  LobIcon,
  Name,
  RateBox,
  StyledRateRow,
} from "../styled/QuoteResponse";
import { ERROR_MESSAGE_COLOR_MAP, security } from "../../constants";
import {
  ERROR_TYPES_WITHOUT_SWITCH_PRIMARY,
  REFETCH_DELAY_MS,
  REFETCH_TIMES,
  addSwitchPrimaryDoneCarrierIdInRedux,
  handleSwitchPrimaryForHomeAndAuto,
  isCarrierAvailableForSwitchPrimary,
} from "../../utils/switchPrimary";
import { convertToCurrency, getPremiumPerMonth } from "../../utils/strings";
import formatContactName, { getIsSpouse } from "../../utils/formatContactName";
import { getLobIcon, isDwelling, sleep } from "../../utils";
import { useDispatch, useSelector } from "react-redux";

import { ActionButton } from "./ActionButton";
import { ButtonSeparator } from "../styled/seperator";
import { CustomText } from "../styled/typography";
import CustomTooltip from "../CustomTooltip";
import { Fragment } from "react";
import WHIndicator from "../../assets/images/components/exclamationMark.SVG";
import { getFloodZoneIcon } from "../../utils/misc";
import logos from "../../utils/carrierLogos";
import sixMonthIcon from "../../assets/images/components/sixMonthIcon.svg";
import { updateIsFetchingQuotes } from "../../redux/actions/fetchingQuotes";
import { useQuoteResponsesContext } from "../../contexts/QuoteResponsesContext";

const Quote = ({
  quote,
  lob,
  featuredQuote,
  handleRemoveQuote,
  coverages,
  ckb,
  quoteToBind,
  shouldDisplayFloodZone = false,
  setShowSmartHomePackageSelectionModal,
}) => {
  const session = useSelector((store) => store.session);
  const property = useSelector((store) => store.property);
  const contact = useSelector((store) => store.contact);

  const dispatch = useDispatch();

  const { spousefirstName } = contact;

  const switchPrimaryDoneCarrierIds = contact.switchPrimaryDoneCarrierIds ?? [];

  const isValidErrorTypeToSwitchPrimary =
    !ERROR_TYPES_WITHOUT_SWITCH_PRIMARY.includes(quote.error_type__c);

  const isSwitchPrimaryNotDone =
    isCarrierAvailableForSwitchPrimary(quote) &&
    !switchPrimaryDoneCarrierIds.includes(quote.company_client_id__c);

  const hasSwitchContactButton =
    isSwitchPrimaryNotDone && isValidErrorTypeToSwitchPrimary;

  const { spouseHomeQuoteId, spouseAutoQuoteId } = contact;

  const { fetchAndUpdateQuotes } = useQuoteResponsesContext();

  const handleSwitchContactButton = async () => {
    try {
      dispatch(updateIsFetchingQuotes(true));
      addSwitchPrimaryDoneCarrierIdInRedux(quote.company_client_id__c);
      await handleSwitchPrimaryForHomeAndAuto(quote);
      for (let i = 0; i < REFETCH_TIMES; i++) {
        await sleep(REFETCH_DELAY_MS);
        await fetchAndUpdateQuotes(
          spouseHomeQuoteId,
          spouseAutoQuoteId,
          "spouse"
        );
      }
    } catch (error) {
      console.log("Error switching primary", error);
    } finally {
      dispatch(updateIsFetchingQuotes(false));
    }
  };

  const lobIcon = getLobIcon(lob);

  const newQuote = quote || {};
  const isSpouse =
    quote.isSpouseQuote !== undefined
      ? quote.isSpouseQuote
      : getIsSpouse(quote);

  const isDp3 =
    session.line_of_business.replace("Auto", "") === "Home" && isDwelling();

  const filteredCoverages = coverages?.filter(
    (coverage) => !(isDp3 && coverage.label === "Personal Property")
  );
  const floodZone = property?.floodZone;

  const isProspectCreated = !!contact.prospectId && lob === security;

  const handleLinkToCKB = (clientId) => {
    const ckbURL = salesForce.url + ckb[clientId].sfid;
    const ckbWindow = window.open(ckbURL);
    ckbWindow.focus();
  };

  const shouldShowSixMonthIcon =
    parseInt(quote?.term__c) === 6 && quote?.premium_total__c !== null;
  const shouldShowWHIndicator =
    (quote?.w_h_deductible__c === "Excl" ||
      quote?.w_h_deductible__c === null) &&
    quote?.line_of_business__c?.toLowerCase() === "home" &&
    quote?.premium_total__c !== null &&
    false; // W/H indicator should not be shown until the W/H mapping is complete
  // see: https://goosehead.atlassian.net/browse/HQ-334

  const carrierLogoUri = [
    "flood",
    "life",
    "lifePrimary",
    "lifeSpouse",
  ].includes(lob)
    ? logos[newQuote.carrier__c]
    : logos[newQuote.company_client_id__c];

  const hasPremium = !!(
    newQuote.premium_total__c || newQuote.premium_total_ppm__c
  );
  const errorMessage = hasPremium
    ? ""
    : newQuote.error_messages__c ||
      newQuote.remarks_truncated__c ||
      newQuote.remarks__c ||
      "Unknown Error -  Please report this using the Feedback button.";

  const isDisabled = quote.markedForDeletion;

  return (
    <StyledRateRow className={`${isDisabled ? "disabled opacity-50" : ""}`}>
      <div className="quote-row d-flex no-gutters gap-4 align-items-center flex-grow-1">
        <LobIcon src={lobIcon} alt={lob} />
        <div className="d-flex flex-column">
          <div className="d-flex gap-2 align-items-centers">
            <CarrierLogoImg
              key={newQuote && newQuote.quote_response_external_id__c}
              src={carrierLogoUri}
              alt={newQuote.carrier__c}
            />
            <CarrierIconColumn>
              {shouldShowSixMonthIcon && (
                <IconImg
                  src={sixMonthIcon}
                  alt="six month icon"
                  className="img-fluid"
                  width="25px"
                />
              )}
              {shouldShowWHIndicator && (
                <IconImg
                  src={WHIndicator}
                  alt="exclamatory icon"
                  className="img-fluid"
                />
              )}
              {shouldDisplayFloodZone && !!floodZone && (
                <img
                  src={getFloodZoneIcon(floodZone)}
                  alt={floodZone}
                  title={`Flood zone ${floodZone}`}
                />
              )}
            </CarrierIconColumn>
          </div>
          {ckb && ckb[newQuote.company_client_id__c] != null && (
            <div>
              <CustomText
                decoration="underline"
                fontFamily="font_secondary"
                fontSize="xsmall"
                align="center"
                tabIndex={0}
                handleClick={() =>
                  handleLinkToCKB(newQuote.company_client_id__c)
                }
              >
                {"CKB >"}
              </CustomText>
            </div>
          )}
        </div>
        {!!contact.spouseId && (
          <>
            <Name>{formatContactName(contact, isSpouse)}</Name>
            {hasSwitchContactButton && (
              <button
                className="btn switch-primary"
                onClick={handleSwitchContactButton}
              >
                {`Quote ${spousefirstName}`}
              </button>
            )}
          </>
        )}
      </div>

      <div className="d-flex justify-content-end align-items-center gap-5">
        <div className="d-flex flex-column align-items-end">
          {newQuote.premium_total__c || newQuote.premium_total_ppm__c ? (
            <RateBox>
              <sup>$</sup>
              <span className="amount">
                {["home", "flood"].includes(lob)
                  ? convertToCurrency(
                      newQuote.premium_total_pif__c || newQuote.premium_total__c
                    )
                  : convertToCurrency(
                      newQuote.premium_total_ppm__c
                        ? getPremiumPerMonth(newQuote)
                        : newQuote.premium_total_pif__c / 12
                    )}
              </span>
              {(newQuote.premium_total__c || newQuote.premium_total_ppm__c) &&
                (["home", "flood"].includes(lob) ? "/yr" : "/mo")}
              {lob === security && <span className="amount">*</span>}
            </RateBox>
          ) : (
            <ErrorMessage
              color={
                ERROR_MESSAGE_COLOR_MAP[newQuote.error_type__c] ??
                ERROR_MESSAGE_COLOR_MAP.default
              }
            >
              {newQuote.error_type__c
                ? newQuote.error_type__c
                : `Unknown Error`}
            </ErrorMessage>
          )}
          {isProspectCreated && (
            <div>
              <CustomText
                fontFamily="font_secondary"
                fontSize="xsmall"
                tabIndex={0}
              >
                Referral Created
              </CustomText>
            </div>
          )}
          {newQuote.error_type__c !== "Login Error" && !isProspectCreated && (
            <div className="d-flex align-items-end">
              <div className="align-self-end">
                <CustomTooltip
                  detailsText
                  lob={lob}
                  content={errorMessage}
                  // if no errorMessage is available, quote details will be displayed in `CustomTooltip`
                  coverages={filteredCoverages}
                  companyQuoteNumber={newQuote.company_quote_number__c}
                  termLength={newQuote.term__c}
                />
              </div>

              {featuredQuote && (
                <Fragment>
                  <ButtonSeparator className="mx-2 mb-1" />
                  <div>
                    <CustomText
                      decoration={"underline"}
                      fontFamily="font_secondary"
                      fontSize="xsmall"
                      tabIndex={0}
                      handleClick={() => handleRemoveQuote(lob)}
                    >
                      remove
                    </CustomText>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
        <ButtonWrapper quoteToBind={quoteToBind} lob={lob}>
          <ButtonContainer>
            <ActionButton
              {...{
                isDisabled,
                lob,
                quoteResponse: quote,
                quoteToBind,
                setShowSmartHomePackageSelectionModal,
                qti_quote_id: quote.qti_quote_id,
              }}
            />
          </ButtonContainer>
        </ButtonWrapper>
      </div>
    </StyledRateRow>
  );
};

export default Quote;
