import * as salesForce from "../utils/sales-force";

import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { capitalizeFirstChars, getPropertyAddress } from "../utils/strings";
import { useDispatch, useSelector } from "react-redux";

import { CustomText } from "./styled/typography";
import { addContact } from "../redux/actions/contact";
import { getAccountDetails } from "../services/account";
import pRetry from "p-retry";
import { theme } from "../assets/theme";

const ContactNameAddress = () => {
  const property = useSelector((store) => store.property);
  const contact = useSelector((store) => store.contact);
  const dispatch = useDispatch();
  const [sfAccountId, setSFAccountID] = useState("");
  const [showPlainName, setShowPlainName] = useState(false);

  const getSFAccountId = async (accountId) => {
    const sfAccountDetails = await getAccountDetails(accountId).catch(
      (error) => {
        error.message = `Failed to getAccountDetails (accountId: ${accountId}): error.message`;
        throw new Error(error);
      }
    );

    if (!sfAccountDetails?.data?.[0]?.id) {
      throw new Error("sfAccountId not found");
    }

    return sfAccountDetails.data[0].id;
  };

  useEffect(() => {
    async function _updateSFAccountId() {
      if (String(sfAccountId).length > 0) {
        return;
      }

      if (contact.sfAccountId) {
        return setSFAccountID(contact.sfAccountId);
      }

      if (contact.accountId) {
        const sfAccountId = await pRetry(
          () => getSFAccountId(contact.accountId),
          { retries: 5, minTimeout: 2000 }
        ).catch((error) => {
          console.error({
            message: "could not get SF AccountId",
            error,
          });

          return setShowPlainName(true);
        });
        setSFAccountID(sfAccountId);
        dispatch(
          addContact({
            sfAccountId,
          })
        );
      }
    }
    _updateSFAccountId().catch((error) => {
      console.error({
        message: "Failed to set sfAccountId",
        error,
      });
    });

    //eslint-disable-next-line
  }, [contact?.sfAccountId, contact?.accountId]);

  const handleViewAccount = () => {
    if (sfAccountId) {
      const account = window.open(`${salesForce.url}${sfAccountId}`, "_blank");
      account.focus();
    }
  };

  const canLinkName = !showPlainName && sfAccountId;

  return (
    <Row>
      <Col xs={12} className="text-center">
        <CustomText
          fontSize="xlarge"
          fontWeight={500}
          decoration={canLinkName ? "underline" : undefined}
          handleClick={handleViewAccount}
          color={!canLinkName ? theme.disabled : undefined}
        >
          {`${contact.firstName} ${contact.lastName}`}
        </CustomText>
      </Col>
      <Col xs={12} className="text-center">
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${property.street_number}, ${property.locality}, ${property.administrative_area_level_1} ${property.postal_code}`}
          rel="noreferrer"
        >
          <CustomText fontSize="medium" fontWeight={550} decoration="underline">
            {getPropertyAddress()}
          </CustomText>
        </a>
      </Col>
    </Row>
  );
};

export default ContactNameAddress;
