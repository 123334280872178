export const contactDetailsPageDisclosure =
  "By clicking submit, you are acknowledging that you have informed your client that our carrier partners use information from the client, gathered by you, as well as reports such as driving, claims and credit histories to calculate an accurate premium and that the client provided consent to proceed. Additionally, you confirm that the client is aware that new or updated information will be used to calculate renewal premiums.  You can refer your client to Goosehead’s and/or the insurance carrier’s privacy policy should they have further questions.";
export const contactDetailsPageText =
  'Almost done! Click "Submit" below to run quotes and view your client\'s rates.';
export const addressInfoPageDisclosure =
  "By clicking let's do this, you are acknowledging that the client has authorized you to obtain a home and/or auto quote and to collect and use certain personally identifiable information for that purpose.";
export const aboutYouDisclosure =
  "By clicking continue you verify that the client has authorized you to use their personally identifiable information and that you have advised the client that we may match information that they provide with data contained in a third-party database to allow us to identify other members of their household or drivers.";

export const reshopModalText = "You must select at least one policy to Reshop";
export const excludedBranchZipCodes = [
  "75035",
  "75013",
  "75025",
  "75002",
  "75024",
  "75098",
  "75071",
  "75072",
  "75094",
  "75093",
  "75078",
  "75074",
  "75034",
  "75023",
  "75033",
  "75022",
  "75075",
  "75009",
  "75252",
  "75287",
  "75069",
  "75454",
  "75407",
  "75409",
  "75166",
  "75442",
  "75173",
  "75424",
  "75164",
  "75097",
  "75086",
  "75070",
  "75044",
  "75043",
  "75040",
  "75089",
  "75088",
  "75080",
  "75042",
  "75041",
  "75056",
  "75036",
  "75028",
  "75010",
  "75007",
  "75068",
  "76262",
  "76226",
  "76210",
  "75077",
  "75067",
  "76227",
  "76247",
  "76208",
  "75065",
  "76207",
  "76266",
  "76209",
  "76249",
  "76201",
  "76205",
  "76259",
  "76258",
  "75057",
  "76206",
  "75087",
  "75032",
  "75189",
  "75132",
  "76244",
  "76248",
  "76052",
  "76177",
];

export const excludedBranchCounties = [
  "bosque",
  "collin",
  "cooke",
  "dallas",
  "delta",
  "denton",
  "ellis",
  "erath",
  "fannin",
  "grayson",
  "henderson",
  "hill",
  "hopkins",
  "hood",
  "hunt",
  "jack",
  "johnson",
  "kaufman",
  "lamar",
  "montague",
  "navarro",
  "palo pinto",
  "parker",
  "rains",
  "rockwall",
  "sumerveil",
  "tarrant",
  "van zandt",
  "wise",
];

export const FLOOD_ZONE_CARRIER_ORDER = [
  "Wright Flood NFIP Insurance",
  "Wright Flood",
  "Neptune",
  "AON",
];
