// @ts-check
import { inRange } from "lodash";

/** Ref: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status */
const STATUS_CODE_CLASS = /** @type {const} */ ({
  INFORMATIONAL: "1xx",
  SUCCESSFUL: "2xx",
  REDIRECTION: "3xx",
  CLIENT_ERROR: "4xx",
  SERVER_ERROR: "5xx",
});

/** @param {number} [statusCode] */
export const getStatusCodeClass = (statusCode) => {
  if (!statusCode) return undefined;

  if (inRange(statusCode, 100, 200)) {
    return STATUS_CODE_CLASS.INFORMATIONAL;
  }
  if (inRange(statusCode, 200, 300)) {
    return STATUS_CODE_CLASS.SUCCESSFUL;
  }
  if (inRange(statusCode, 300, 400)) {
    return STATUS_CODE_CLASS.REDIRECTION;
  }
  if (inRange(statusCode, 400, 500)) {
    return STATUS_CODE_CLASS.CLIENT_ERROR;
  }
  if (inRange(statusCode, 500, 600)) {
    return STATUS_CODE_CLASS.SERVER_ERROR;
  }
};

/** @param {number} [statusCode] */
export const isSuccess = (statusCode) =>
  getStatusCodeClass(statusCode) === STATUS_CODE_CLASS.SUCCESSFUL;

/** @param {number} [statusCode] */
export const isClientError = (statusCode) =>
  getStatusCodeClass(statusCode) === STATUS_CODE_CLASS.CLIENT_ERROR;

/** @param {number} [statusCode] */
export const isServerError = (statusCode) =>
  getStatusCodeClass(statusCode) === STATUS_CODE_CLASS.SERVER_ERROR;
