/* eslint-disable react-hooks/exhaustive-deps */

import "swiper/css";
import "swiper/css/pagination";

import {
  EDUCATION_LEVELS,
  MAX_DRIVERS_ALLOWED,
  MAX_VEHICLES_ALLOWED,
  OCCUPATIONS,
  USAStates,
  VALID_VIN_LENGTH,
  collisionAndComprehensiveDeductibles,
  DRIVER,
  VEHICLE,
} from "../constants";
import {
  ErrorMessage,
  Field,
  setNestedObjectValues,
  useFormikContext,
} from "formik";
import { Children, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { capitalize, isEmpty, range } from "lodash";
import {
  getDriverErrorMessagesWithIndices,
  getDriverName,
  getDummyVin,
  getGenderOptions,
  getIndicesWithCondition,
  getVehicleMakes,
  getVehicleModels,
  getVehicleName,
  isVehicleEmpty,
  setFieldsEdited,
} from "../utils";

import CustomButton from "./styled/buttons/CustomButton";
import { ReactComponent as DoneIcon } from "../assets/images/components/greenCheck.svg";
import LinkButton from "./styled/buttons/LinkButton";
import { Navigation } from "swiper/modules";
import { TypeAheadSelect } from "./styled/inputs/CustomSelect";
import deleteIcon from "../assets/images/main/delete-icon.svg";
import { getVehicleByVin } from "../services/vehicles";
import leftArrow from "../assets/images/main/green-arrow-left.svg";
import rightArrow from "../assets/images/main/green-arrow-right.svg";
import styled from "styled-components";

const MAX_VISIBLE_SLIDES = 3;

const StyledInput = styled.input`
  border-radius: 999px;
`;

export const Input = (props) => (
  <StyledInput className="form-control" {...props} />
);
const StyledSearchInput = styled.div`
  position: relative;
  & > input {
    border-radius: 999px;
    height: 100%;
  }
  & > svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    height: 25px;
    z-index: 10;
  }
`;

export const SearchInput = ({ Icon, ...props }) => {
  return (
    <StyledSearchInput>
      <input className="form-control" {...props} />
      {Icon && <Icon />}
    </StyledSearchInput>
  );
};

const SelectWrapper = styled.div`
  position: relative;
  ::after {
    content: "▼";
    color: ${({ theme }) => theme.light_gray_alt};
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  & > select {
    height: 100%;
    border-radius: 999px;
    appearance: none;
    padding-right: 2.5em;
    text-overflow: ellipsis;
  }
`;

const Select = (props) => (
  <SelectWrapper>
    <select className="form-control" {...props} />
  </SelectWrapper>
);

const DriverVehicleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const DriverErrorMessage = styled.div`
  color: red;
  font-size: 0.9rem;
  text-align: center;
`;

const DriverVehicleFormContent = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem auto 0 auto;
  justify-content: center;
  & > * {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & .spacer {
      min-height: ${({ shouldDisplayYearMakeModel }) =>
        shouldDisplayYearMakeModel ? "180px" : "60px"};
    }
  }
  & > .left-column,
  & > .right-column {
    min-width: 15rem;
  }
  & > .left-column {
    align-items: end;
    & .labels {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 2rem 0.7rem;
      background-color: ${({ theme }) => theme.form_background};
      border-radius: 1rem;
      font-size: 0.9rem;
      font-weight: bold;
      width: fit-content;
      & > * {
        height: 60px;
        transform: translateY(13px);
        & > p {
          margin: 0;
          &.comment {
            font-weight: normal;
            color: ${({ theme }) => theme.gray};
          }
        }
      }
      /* to align with .delete-button-container */
      & .space-holder {
        height: 30px;
      }
    }
  }
  & > .right-column {
    display: flex;
    flex-direction: column;
    gap: 37.75px;
    width: 230px;
    & .add-error-message {
      color: red;
      font-size: 0.9rem;
      text-align: left;
      padding-top: 0.5rem;
    }
    & .item-list {
      border-radius: 1rem;
      padding: 0.5rem;
      background-color: ${({ theme }) => theme.form_background};
      & .item-list-header {
        font-size: 0.9rem;
        font-weight: bold;
        padding-left: 1.6rem;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid ${({ theme }) => theme.green};
      }
    }
    & .item-list-body {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
      gap: 0.5rem;
      & .item-list-item {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        & > .button-container {
          width: 1.2rem;
          height: 1.2rem;
          flex-shrink: 0;
          & > button {
            background-color: ${({ theme }) => theme.lime};
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            color: ${({ theme }) => theme.green};
            border: none;
          }
        }
        & > span {
          font-size: 0.9rem;
        }
      }
    }
  }
  & > .items {
    flex-direction: row;
    justify-content: center;
    max-width: 930px;
    & .item-column {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 280px;
      &:last-child {
        /* overide Swiper CSS */
        margin-right: 0 !important;
      }
      & .item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 0.9rem;
        font-weight: bold;
        & .item-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        & .item-counter {
          width: fit-content;
          flex-shrink: 0;
          color: ${({ theme }) => theme.green};
        }
      }
      & .item-inputs-column {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 2rem 0.7rem;
        background-color: ${({ theme }) => theme.form_background};
        border-radius: 1rem;
        width: 100%;
        & .last-name-suffix {
          display: flex;
          gap: 0.5rem;
          & > *:nth-child(2) {
            width: 4em;
          }
        }
        & .divider {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          font-size: 13px;
          font-weight: bold;
          color: ${({ theme }) => theme.dark_green};
          &::before,
          &::after {
            content: "";
            display: inline-block;
            width: 2rem;
            height: 1px;
            background-color: ${({ theme }) => theme.dark_green};
          }
        }
        & > * {
          height: 60px;
        }
        & .input-error {
          display: flex;
          flex-direction: column;
          > *:first-child {
            height: 2.8rem;
          }
        }
        & .error-message {
          color: red;
          font-size: 0.9rem;
          padding-left: 0.9em;
          text-align: left;
        }
        & .delete-button-container {
          display: flex;
          justify-content: center;
          align-items: end;
          height: 30px;
          & > button {
            max-height: 25px;
            max-width: 25px;
            color: #6cb545;
            background-color: transparent;
            border: none;
            & :hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  & .left-button-container,
  & .right-button-container {
    display: flex;
    align-items: end;
    height: 22.5px;
  }
  & .left-button-container {
    justify-content: end;
  }
  & .right-button-container {
    justify-content: start;
  }
  & .left-button-container > button,
  & .right-button-container > button {
    background-color: transparent;
    border: none;
    border: 2px solid ${({ theme }) => theme.green};
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    & > img {
      height: 100%;
      width: 100%;
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  margin: 0 !important;
  & .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const DriverVehicleSwiper = ({ className, setSwiper, children }) => {
  const numChildren = Children.count(children);
  const slidesPerView = Math.min(numChildren, MAX_VISIBLE_SLIDES);
  const numGaps = slidesPerView - 1;
  const GAP_WIDTH = 15;
  const spaceBetween = numGaps > 0 ? GAP_WIDTH : 0;
  return (
    <StyledSwiper
      slidesPerView={slidesPerView}
      className={className}
      spaceBetween={spaceBetween}
      modules={[Navigation]}
      onSwiper={setSwiper}
      allowTouchMove={false}
    >
      {Children.map(children, (child) => (
        <SwiperSlide className="item-column">{child}</SwiperSlide>
      ))}
    </StyledSwiper>
  );
};

const useSwiper = (values) => {
  const [swiper, setSwiper] = useState();

  const totalSlides = values.length;
  // swiper?.activeIndex doesn't update automatically on slide change
  const [activeIndex, setActiveIndex] = useState(swiper?.activeIndex ?? 0);
  swiper?.on("slideChange", () => {
    setActiveIndex(swiper?.activeIndex);
  });

  const isBeginning = activeIndex === 0;
  const isEnd = activeIndex >= totalSlides - MAX_VISIBLE_SLIDES;

  useEffect(() => {
    swiper?.slideTo(values.length - 1);
  }, [values.length]);

  return {
    swiper,
    setSwiper,
    isBeginning,
    isEnd,
  };
};

const DriverVehicleInput = ({ type, add, remove, Labels, Column }) => {
  const {
    values: { items, itemsFound, removedItems },
    errors,
    submitCount,
    setFieldValue,
    setTouched,
    validateForm,
  } = useFormikContext();
  const { swiper, setSwiper, isBeginning, isEnd } = useSwiper(items);

  const erroredItemIndices = getIndicesWithCondition(
    errors.items || [],
    (item) => !isEmpty(item)
  );

  const [addItemError, setAddItemError] = useState("");
  const shouldDisplayYearMakeModel = items.some(
    ({ displayYearMakeModelEntry }) => displayYearMakeModelEntry
  );

  // needs to be checked as additional driver
  // can be added as a spouse in the previous page

  const totalItems = items.length;
  const hasMaxedItemLimit =
    (type === DRIVER && totalItems >= MAX_DRIVERS_ALLOWED) ||
    (type === VEHICLE && totalItems >= MAX_VEHICLES_ALLOWED);
  useEffect(() => {
    if (hasMaxedItemLimit) {
      setAddItemError(`You have reached the limit of ${totalItems} ${type}s`);
    } else {
      setAddItemError("");
    }
  }, [items.length]);

  const addRemovedItem = (item) => {
    setFieldValue("removedItems", [...removedItems, item]);
  };
  const deleteItemsFound = (index) => {
    setFieldValue(
      "itemsFound",
      itemsFound.filter((_, i) => i !== index)
    );
  };
  const deleteRemovedItems = (index) => {
    setFieldValue(
      "removedItems",
      removedItems.filter((_, i) => i !== index)
    );
  };

  const shouldReplaceLastItem = () => {
    if (type === DRIVER) {
      return false;
    }
    // type === "vehicle"
    const lastVehicle = items[items.length - 1];
    return isVehicleEmpty(lastVehicle);
  };

  const canAddItem = async ({
    shouldValidateForm = true,
    shouldReplaceEmpty = false,
  } = {}) => {
    if (hasMaxedItemLimit) return false;
    const lastItem = items[items.length - 1];
    if (type === VEHICLE && shouldReplaceEmpty && isVehicleEmpty(lastItem)) {
      setAddItemError("");
      return true;
    }
    if (shouldValidateForm) {
      const errors = await validateForm();
      if (!isEmpty(errors)) {
        // touch all the errored fields to display errors
        setTouched(setNestedObjectValues(errors, true), true);
        setAddItemError(`Please complete updating current ${type}`);
        return false;
      }
    }
    // this is checked before the add driver is called
    // yup won't catch this error as it's not added yet
    if (hasMaxedItemLimit) {
      setAddItemError(`You have reached the limit of ${totalItems} ${type}s`);
      return false;
    }

    setAddItemError("");
    return true;
  };

  const addNewAndDisplayError = async () => {
    // Do not check validity on drivers on adding
    const isValid = await canAddItem({
      shouldReplaceEmpty: true,
      shouldValidateForm: type === VEHICLE,
    });
    if (!isValid) return;
    add();
  };

  const addValueFromValuesFound = async (index, type) => {
    const isValid = await canAddItem({
      shouldValidateForm: type === VEHICLE,
    });
    if (!isValid) return;
    if (shouldReplaceLastItem()) {
      const lastIndex = items.length - 1;
      remove(lastIndex);
    }
    // when adding to the inputs, set selected to true
    add(
      itemsFound.map((item) => ({
        ...item,
        selected: true,
      }))[index]
    );
    deleteItemsFound(index);
  };

  const addValueFromRemovedValues = async (index) => {
    // Do not check validity on drivers on adding
    const isValid = await canAddItem({
      shouldReplaceEmpty: true,
      shouldValidateForm: type === VEHICLE,
    });
    if (!isValid) return;
    if (shouldReplaceLastItem()) {
      const lastIndex = items.length - 1;
      remove(lastIndex);
    }
    add(removedItems[index]);

    deleteRemovedItems(index);
  };

  const removeValueAndAddToSideBar = (index) => {
    const valueToRemove = items[index];
    if (type === DRIVER) {
      // clear error message about number of driver limit
      setAddItemError("");
      // don't add to sidebar if item doesn't have enough info or has already been removed
      if (valueToRemove.firstName || valueToRemove.lastName) {
        addRemovedItem(items[index]);
      }
    } else {
      // don't add to sidebar if item doesn't have enough info or has already been removed
      if (!isVehicleEmpty(valueToRemove)) {
        addRemovedItem(items[index]);
      }
    }
    if (type === VEHICLE && index === 0 && items.length === 1) {
      // if deleted vehicle is the only one, add an empty vehicle
      add();
    }
    remove(index);
  };

  return (
    <DriverVehicleContainer>
      <DriverVehicleFormContent
        shouldDisplayYearMakeModel={shouldDisplayYearMakeModel}
      >
        <div className="left-column">
          <div className="left-button-container">
            {!isBeginning && (
              <button
                type="button"
                aria-label="previous"
                onClick={() => swiper.slidePrev()}
              >
                <img src={leftArrow} alt="left arrow" />
              </button>
            )}
          </div>
          <div className="labels">
            <Labels />
          </div>
        </div>
        <DriverVehicleSwiper className="items" setSwiper={setSwiper}>
          {items.map((_, index) => (
            <Column
              key={index}
              index={index}
              remove={() => removeValueAndAddToSideBar(index)}
            />
          ))}
        </DriverVehicleSwiper>
        <div className="right-column">
          <div className="right-button-container">
            {!isEnd && (
              <button
                type="button"
                aria-label="next"
                onClick={() => swiper.slideNext()}
              >
                <img src={rightArrow} alt="left arrow" />
              </button>
            )}
          </div>
          <div>
            <CustomButton
              width="100%"
              title={`Add ${capitalize(type)}`}
              light
              plus
              type="button"
              aria-label={`add ${type}`}
              handleClick={addNewAndDisplayError}
            />
            <div className="add-error-message">{addItemError}</div>
          </div>
          <SideBarList
            type={type}
            header={`${capitalize(type)}s Found`}
            values={itemsFound}
            handleItemClick={addValueFromValuesFound}
          />
          <SideBarList
            type={type}
            header={`Removed ${capitalize(type)}s`}
            values={removedItems}
            handleItemClick={addValueFromRemovedValues}
          />
        </div>
      </DriverVehicleFormContent>
      {/* display the error only if it has been submitted before */}
      {type === DRIVER && !!submitCount && !!erroredItemIndices.length && (
        <DriverErrorMessage>
          {getDriverErrorMessagesWithIndices(erroredItemIndices)}
        </DriverErrorMessage>
      )}
    </DriverVehicleContainer>
  );
};

const DriverLabels = () => (
  <>
    <div>First Name</div>
    <div>Last Name / Suffix</div>
    <div>Date of Birth</div>
    <div>Gender</div>
    <div>
      <p>License Number</p>
      <p className="comment">(Optional)</p>
    </div>
    <div>License State</div>
    <div>Education</div>
    <div>Occupation - Industry</div>
  </>
);

export const DriverInputColumn = ({ index, remove }) => {
  const {
    values: { items },
    setFieldValue,
    handleChange,
  } = useFormikContext();

  const numDrivers = items.length;
  const isFromContactPage = items[index].isFromContactPage;
  const name = getDriverName(items[index]) || "New Driver";

  const occupation = !items[index].occupation
    ? null // must be null to display placeholder
    : {
        value: items[index].occupation,
        label: items[index].occupation,
      };

  const handleFieldChange = (e) => {
    setFieldsEdited();
    handleChange(e);
    setFieldValue(e.target.name, e.target.value);
  };

  return (
    <>
      <div className="item-header">
        <div className="item-name">{name}</div>
        <div className="item-counter">
          {index + 1} of {numDrivers}
        </div>
      </div>
      {/* <pre>{JSON.stringify(items[index], null, 2)}</pre> */}
      <div className="item-inputs-column">
        <div className="input-error">
          <Field
            as={Input}
            name={`items.${index}.firstName`}
            type="text"
            placeholder="First Name"
            disabled={isFromContactPage}
            onChange={handleFieldChange}
          />
          <ErrorMessage
            name={`items.${index}.firstName`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <div className="last-name-suffix">
            <Field
              as={Input}
              name={`items.${index}.lastName`}
              type="text"
              placeholder="Last Name"
              disabled={isFromContactPage}
              onChange={handleFieldChange}
            />
            <Field
              as={Input}
              name={`items.${index}.suffix`}
              type="text"
              placeholder="Jr."
              disabled={isFromContactPage}
              onChange={handleFieldChange}
            />
          </div>
          <ErrorMessage
            name={`items.${index}.lastName`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <Field
            as={Input}
            name={`items.${index}.dob`}
            type="date"
            max="9999-12-31"
            className="form-control"
            disabled={isFromContactPage}
            onChange={handleFieldChange}
          />
          <ErrorMessage
            name={`items.${index}.dob`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <Field
            as={Select}
            name={`items.${index}.gender`}
            disabled={isFromContactPage}
            onChange={handleFieldChange}
          >
            {getGenderOptions().map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name={`items.${index}.gender`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <Field
            as={Input}
            name={`items.${index}.driverLicense`}
            type="text"
            placeholder="License Number"
            data-private
            onChange={handleFieldChange}
          />
          <ErrorMessage
            name={`items.${index}.driverLicense`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <Field
            as={Select}
            name={`items.${index}.dLState`}
            onChange={handleFieldChange}
          >
            {USAStates.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name={`items.${index}.dLState`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <Field
            as={Select}
            name={`items.${index}.education`}
            onChange={handleFieldChange}
          >
            <option value="">- Select -</option>
            {EDUCATION_LEVELS.map((level) => (
              <option key={level} value={level}>
                {level}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name={`items.${index}.education`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="input-error">
          <TypeAheadSelect
            name={`items.${index}.occupation`}
            placeholder="Occupation"
            options={OCCUPATIONS.map((occupation) => ({
              value: occupation,
              label: occupation,
            }))}
            value={occupation}
            onChange={({ value }, { name }) => {
              setFieldsEdited();
              setFieldValue(name, value);
            }}
          />
          <ErrorMessage
            name={`items.${index}.occupation`}
            component="div"
            className="error-message"
          />
        </div>
        <div className="delete-button-container">
          {index !== 0 && (
            <button
              id={`items.${index}.delete`}
              type="button"
              aria-label="delete driver"
              onClick={remove}
            >
              <img src={deleteIcon} alt="delete" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export const DriverInputs = ({ addNewDriver, removeDriver }) => {
  return (
    <DriverVehicleInput
      type={DRIVER}
      add={addNewDriver}
      remove={removeDriver}
      Labels={DriverLabels}
      Column={DriverInputColumn}
    />
  );
};

const VehicleLabels = () => {
  const {
    values: { items },
  } = useFormikContext();
  const shouldDisplayYearMakeModel = items.some(
    ({ displayYearMakeModelEntry }) => displayYearMakeModelEntry
  );
  return (
    <>
      <div>VIN</div>
      {shouldDisplayYearMakeModel ? (
        <>
          <div>Year</div>
          <div>Make</div>
          <div>Model</div>
        </>
      ) : (
        <div className="spacer" />
      )}
      <div>Comprehensive Deductible</div>
      <div>Collision Deductible</div>
      <div className="space-holder" />
    </>
  );
};

const VehicleInputColumn = ({ index, remove }) => {
  const {
    values: { items },
    // errors,
    // touched,
    setFieldValue,
    handleChange,
  } = useFormikContext();

  const numVehicles = items.length;
  const vehicle = items[index];
  const { displayYearMakeModelEntry, isVinFound, isDummyVin } = vehicle;
  const name = getVehicleName(vehicle) || "New Vehicle";

  const isVinLengthValid = vehicle.Vin.length === VALID_VIN_LENGTH;
  const isDone = isVinLengthValid && (isVinFound || isDummyVin);
  const Icon = isDone ? DoneIcon : undefined;

  const shouldDisplayDeleteButton = !(index === 0 && isVehicleEmpty(vehicle));

  const YearMakeModelInput = isVinFound ? Input : Select;

  // This is the behavior for Legacy Rater too
  const handleComprehensiveChange = (e) => {
    setFieldsEdited();
    handleChange(e);
    if (e.target.value === "No Coverage") {
      setFieldValue(`items.${index}.towing`, false);
      setFieldValue(`items.${index}.rental`, false);
      setFieldValue(`items.${index}.collision`, "No Coverage");
    }
  };

  const handleCollisionChange = (e) => {
    setFieldsEdited();
    handleChange(e);
    if (e.target.value === "No Coverage") {
      setFieldValue(`items.${index}.towing`, false);
      setFieldValue(`items.${index}.rental`, false);
    }
  };

  const displayYearMakeModel = () => {
    setFieldValue(`items.${index}.displayYearMakeModelEntry`, true);
  };

  const searchVin = async (Vin) => {
    try {
      const { Year, Make, Model } = (await getVehicleByVin(Vin)).data
        .vehicleData;
      // set isVinFound to true first
      // so that Year Make Model inputs changes don't clear VIN
      // in the useEffect below
      setFieldValue(`items.${index}.isVinFound`, true);
      setFieldValue(`items.${index}.Year`, Year);
      setFieldValue(`items.${index}.Make`, Make);
      setFieldValue(`items.${index}.Model`, Model);
    } catch (error) {
      console.log(error);
      setFieldValue(`items.${index}.Year`, "");
      setFieldValue(`items.${index}.Make`, "");
      setFieldValue(`items.${index}.Model`, "");
      // do not set isVinFound to false first
      // for similar reasons
      setFieldValue(`items.${index}.isVinFound`, false);
    } finally {
      displayYearMakeModel();
    }
  };

  const vehicleYears = range(new Date().getFullYear() + 1, 1980, -1);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);

  useEffect(() => {
    if (isVinFound || !displayYearMakeModelEntry || !vehicle.Year) return;
    setVehicleModels([]);
    getVehicleMakes(vehicle.Year).then((makes) => {
      setVehicleMakes(Array.isArray(makes) ? makes : [makes]);
      if (!makes.includes(vehicle.Make)) {
        setFieldValue(`items.${index}.Make`, "");
      }
    });
  }, [vehicle.Year]);

  useEffect(() => {
    if (
      isVinFound ||
      !displayYearMakeModelEntry ||
      !vehicle.Year ||
      !vehicle.Make
    )
      return;
    getVehicleModels(vehicle.Year, vehicle.Make).then((models) => {
      setVehicleModels(models);
      if (!models.includes(vehicle.Model)) {
        setFieldValue(`items.${index}.Model`, "");
      }
    });
  }, [vehicle.Year, vehicle.Make]);

  const existingVins = items
    .filter((_, i) => i !== index)
    .map(({ Vin }) => Vin);

  useEffect(() => {
    if (
      isVinFound ||
      !displayYearMakeModelEntry ||
      !vehicle.Year ||
      !vehicle.Make ||
      !vehicle.Model
    )
      return;
    getDummyVin(vehicle.Year, vehicle.Make, vehicle.Model, existingVins).then(
      (Vin) => {
        setFieldValue(`items.${index}.Vin`, Vin);
        setFieldValue(`items.${index}.isDummyVin`, true);
      }
    );
  }, [
    vehicle.Year,
    vehicle.Make,
    vehicle.Model,
    isVinFound,
    displayYearMakeModelEntry,
  ]);

  const handleVinChange = (e) => {
    // remove all spaces in the VIN
    const vinRaw = e.target.value;
    const vin = vinRaw.replaceAll(" ", "");
    e.target.value = vin;
    setFieldsEdited();
    handleChange(e);
    setFieldValue(`items.${index}.isDummyVin`, false);
    if (vin.length !== VALID_VIN_LENGTH) return;
    searchVin(vin);
  };

  const handleYearMakeModelChange = (e) => {
    setFieldsEdited();
    handleChange(e);
    const { Year, Make, Model } = vehicle;
    if (!isVinFound & Year && Make && Model) {
      // if the VIN is not found
      // user fills out year, make, and model, clear Vin
      setFieldValue(`items.${index}.Vin`, "");
    }
  };

  return (
    <>
      <div className="item-header">
        <div className="item-name">{name}</div>
        <div className="item-counter">
          {index + 1} of {numVehicles}
        </div>
      </div>
      {/* <pre>{JSON.stringify(vehicle, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors.items?.[index], null, 2)}</pre> */}
      <div className="item-inputs-column">
        <div className="input-error">
          <Field
            as={SearchInput}
            name={`items.${index}.Vin`}
            type="text"
            placeholder="VIN"
            Icon={Icon}
            onChange={handleVinChange}
          />
          <ErrorMessage
            name={`items.${index}.Vin`}
            component="div"
            className="error-message"
          />
        </div>
        {displayYearMakeModelEntry ? (
          <>
            <div className="input-error">
              <Field
                as={YearMakeModelInput}
                name={`items.${index}.Year`}
                type="number"
                placeholder="Year"
                disabled={isVinFound}
                onChange={handleYearMakeModelChange}
              >
                {isVinFound ? null : (
                  <>
                    <option value="">- Select -</option>
                    {vehicleYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage
                name={`items.${index}.Year`}
                component="div"
                className="error-message"
              />
            </div>
            <div className="input-error">
              <Field
                as={YearMakeModelInput}
                name={`items.${index}.Make`}
                placeholder="Make"
                disabled={isVinFound || vehicleMakes.length === 0}
                onChange={handleYearMakeModelChange}
              >
                {isVinFound ? null : (
                  <>
                    <option value="">- Select -</option>
                    {vehicleMakes.map((make) => (
                      <option key={make} value={make}>
                        {make}
                      </option>
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage
                name={`items.${index}.Make`}
                component="div"
                className="error-message"
              />
            </div>
            <div className="input-error">
              <Field
                as={YearMakeModelInput}
                name={`items.${index}.Model`}
                placeholder="Model"
                disabled={isVinFound || vehicleModels.length === 0}
                onChange={handleYearMakeModelChange}
              >
                {isVinFound ? null : (
                  <>
                    <option value="">- Select -</option>
                    {vehicleModels.map((model) => (
                      <option key={model} value={model}>
                        {model}
                      </option>
                    ))}
                  </>
                )}
              </Field>
              <ErrorMessage
                name={`items.${index}.Model`}
                component="div"
                className="error-message"
              />
            </div>
          </>
        ) : (
          <>
            <div className="spacer">
              <div className="divider">or</div>
              <LinkButton
                type="button"
                fontWeight="bold"
                onClick={displayYearMakeModel}
              >
                Enter Year, Make, and Model
              </LinkButton>
            </div>
          </>
        )}
        <div className="input-error">
          <Field
            as={Select}
            name={`items.${index}.comprehensive`}
            type="number"
            onChange={handleComprehensiveChange}
          >
            {collisionAndComprehensiveDeductibles.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Field>
        </div>
        <div className="input-error">
          <Field
            as={Select}
            name={`items.${index}.collision`}
            type="number"
            onChange={handleCollisionChange}
          >
            {collisionAndComprehensiveDeductibles.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Field>
        </div>
        <div className="delete-button-container">
          {shouldDisplayDeleteButton && (
            <button
              id={`items.${index}.delete`}
              type="button"
              aria-label="delete vehicle"
              onClick={() => {
                setFieldsEdited();
                remove();
              }}
            >
              <img src={deleteIcon} alt="delete" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export const VehicleInputs = ({ addNewVehicle, removeVehicle }) => {
  return (
    <DriverVehicleInput
      type={VEHICLE}
      add={addNewVehicle}
      remove={removeVehicle}
      Labels={VehicleLabels}
      Column={VehicleInputColumn}
    />
  );
};

export const SideBarList = ({ type, header, values, handleItemClick }) => {
  const getName = type === DRIVER ? getDriverName : getVehicleName;

  if (!values.filter(Boolean).length) return null;
  return (
    <div className="item-list">
      <div className="item-list-header">
        <div>{header}</div>
      </div>
      <div className="item-list-body">
        {values.map((value, index) => {
          if (!value) return null;
          const { isCommercialTruck } = value;
          return (
            <div key={index} className="item-list-item">
              <div className="button-container">
                {!isCommercialTruck && (
                  <button
                    type="button"
                    onClick={() => handleItemClick(index)}
                    aria-label={`add ${getName(value)} to ${type} list`}
                  >
                    +
                  </button>
                )}
              </div>
              <span>{getName(value)}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
