import styled from "styled-components";

export const RecommendedQuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  gap: 35px;
`;

export const QuotesContainer = styled.div`
  border: 4px solid ${(props) => props.theme.tertiary_accent};
  border-radius: 15px;
  padding: 22.5px;
  padding-top: 7.5px;
`;

export const HeadersAndQuotes = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 1rem;
  & > h3 {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${(props) => props.theme.secondary_accent};
    font-size: 1.5rem;
    font-weight: bold;
    > svg {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
`;
