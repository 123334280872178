import pWaitFor from "p-wait-for";
import { TimeoutError } from "p-wait-for";

export { TimeoutError };

export const waitFor = pWaitFor;

/**
 * @template ReturnType
 * @param {() => PromiseLike<ReturnType>} conditionFn
 * @param {Parameters<typeof pWaitFor>[1]} options
 * @returns
 */
export const waitForPromise = (conditionFn, options) =>
  waitFor(() => _waitForPromiseCondition(conditionFn), options);

/**
 * @param {() => Promise} asyncFn
 * @param {{ onError?: (error: Error) => void }} options
 */
async function _waitForPromiseCondition(asyncFn, { onError } = {}) {
  try {
    const result = await asyncFn();
    return pWaitFor.resolveWith(result);
  } catch (error) {
    onError?.(error);

    // allow early exit
    if (error instanceof TimeoutError) {
      throw error;
    }

    return false;
  }
}

export const poll = waitFor;
export const pollPromise = waitForPromise;
