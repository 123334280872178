import {
  DEFAULT_PRIOR_CARRIER,
  NO_PRIOR_INSURANCE,
  PREVIOUS_LIMITS_BY_STATE,
  PRIOR_CAREER_ID,
  PRIOR_CARRIER,
  YEARS_WITH_PRIOR_CARRIER,
  accidentsRecordTypeId,
  bodilyInjuryToPropertyDamageMap,
  compLossesRecordTypeId,
  priorCarrierNames,
  stateSpecificBodilyInjuryOptions,
} from "../constants";
import { Field, Form, Formik } from "formik";
import {
  clearAllEventStreams,
  fetchAutoDetails,
  filterIncident,
  getDefaultIncident,
  getPriorInsuranceInfo,
  handleUpdateAccount,
  handleUpdateQuote,
  mapIncidentFormToPayload,
  mapIncidentResponseToForm,
  setFieldsEdited,
} from "../utils";
import {
  getAccidentsViolations,
  getIncidentsFromQuoteRequestId,
  upsertLosses,
} from "../services/accidents";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { AutoIndicators } from "../components/AutoDetails/Indicators";
import CustomButton from "../components/styled/buttons/CustomButton";
import CustomInput from "../components/styled/inputs/CustomInput";
import CustomSelect from "../components/styled/inputs/CustomSelect";
import DatePicker from "../components/DatePicker";
import Disclaimer from "../components/Disclaimer";
import FormContainer from "../components/styled/forms/FormContainer";
import Heading from "../components/Heading";
import IncidentInputLine from "../components/AutoDetails/IncidentInputLine";
import LoadingDots from "../components/LoadingDots";
import { addCoverages } from "../redux/actions/coverages";
import { autoDetailsValidationSchema } from "../utils/validation";
import { clearSwitchPrimaryDoneCarrierIdsInRedux } from "../utils/switchPrimary";
import { contactDetailsPageDisclosure } from "../utils/constants";
import { createFeatureFlagContext } from "../services/utils";
import { getUMPDCoverage } from "../utils/strings";
import { handleIntInputKeyDown } from "../utils/misc";
import { isEmpty } from "lodash";
import moment from "moment";
import { resetQuotesStatus } from "../utils/resubmit";
import { scrollToTop } from "../utils/layout";
import styled from "styled-components";
import { updateIsFetching } from "../redux/actions/fetching";
import { useFeatureFlag } from "../services/featureFlag";

const PriorPolicyUpdate = styled.div`
  display: flex;
  gap: 1rem;
  padding: 0 5rem;
  justify-content: center;
`;
const NewAutoPolicy = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;
const IncidentButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0 5rem;
  position: relative;
`;

const Incidents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const PreviousPolicyDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 5rem 0 5rem;
  border-top: 1px solid ${({ theme }) => theme.secondary};
  & .summary {
    font-weight: bold;
  }
  & > .values {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
  }
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > button {
      border: 1px green solid;
      background: none;
      padding: 0.25rem 1em;
      border-radius: 999px;
      font-weight: bold;
      color: ${({ theme }) => theme.primary};
      &:hover {
        background-color: ${({ theme }) => theme.green3};
      }
    }
  }
`;

const AccidentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-top: 1px solid ${({ theme }) => theme.secondary};
  padding: 1rem 0;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.25rem;
  & .capsule {
    width: 100%;
    max-width: 200px;
    font-size: 1rem;
  }
`;

const handleUpdateIncidents = async (values) => {
  const incidents = values.incidents
    .filter(Boolean)
    .map(mapIncidentFormToPayload)
    .filter(Boolean);
  if (incidents.length) await upsertLosses(incidents);
};

const AutoDetails = ({ handleNext }) => {
  const contact = useSelector((store) => store.contact);
  const property = useSelector((store) => store.property);
  const coverages = useSelector((store) => store.coverages);
  const drivers = useSelector((store) => store.drivers);
  const featureFlags = useFeatureFlag();

  const state = property.administrative_area_level_1;
  const { quotesSubmitted, fieldsEdited, previousAutoQuoteRequestSfId } =
    contact;

  const isFetchingPriorLimits = useSelector(
    (store) => store.isFetchingPriorLimits
  );

  const dispatch = useDispatch();

  const [shouldDisplayPriorInsurance, setShouldDisplayPriorInsurance] =
    useState(true);

  const checkoutText = shouldDisplayPriorInsurance ? "Hide ⇑" : "Show ⇓";

  const handleSubmit = async (values) => {
    try {
      dispatch(updateIsFetching(true));
      const { quoteEvents } = window;

      clearAllEventStreams(quoteEvents);

      await handleUpdateAccount();
      await handleUpdateQuote(values);
      await handleUpdateIncidents(values);

      const updatedCoverages = {
        bodily_injury: values.bodily_injury,
        uim: values.bodily_injury,
        property_damage: bodilyInjuryToPropertyDamageMap[values.bodily_injury],
        autoEffectiveDate: values.effective_date,
        uim_property_damage: getUMPDCoverage(state, values.bodily_injury),
      };
      dispatch(
        addCoverages({
          ...coverages,
          ...updatedCoverages,
        })
      );

      const featureFlagContext = createFeatureFlagContext();
      if (featureFlagContext.key) {
        await featureFlags.initialize(featureFlagContext);
      }

      await resetQuotesStatus();
      handleNext();
      clearSwitchPrimaryDoneCarrierIdsInRedux();

      scrollToTop();
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const [priorInsuranceInfo, setPriorInsuranceInfo] = useState([
    { name: "Carrier", value: DEFAULT_PRIOR_CARRIER },
    { name: "Expiration Date", value: "" },
    { name: "BI", value: "" },
    { name: "PD", value: "" },
    { name: "UMBI", value: "" },
    { name: "PIP", value: "" },
  ]);

  const isPriorInsuranceFound = priorInsuranceInfo.some(({ name, value }) => {
    if (name === "Carrier") {
      return value !== DEFAULT_PRIOR_CARRIER;
    }
    return Boolean(value);
  });

  const [initialValues, setInitialValues] = useState({
    prior_carrier: "",
    years_with_prior_carrier: "",
    prior_liability_limits: "",
    effective_date: coverages.autoEffectiveDate,
    bodily_injury: coverages.bodily_injury,
    incidents: [],
  });

  // Fetch prior insurance info and incidents
  useEffect(() => {
    (async () => {
      setPriorInsuranceInfo(await getPriorInsuranceInfo());
      const autoDetails = await fetchAutoDetails();
      const incidentsFromCurrentQuote = [
        ...(await getAccidentsViolations(contact.autoQuoteId)).data,
      ]
        .filter(filterIncident)
        .map(mapIncidentResponseToForm)
        .filter(Boolean);
      // Reshop
      // if the user submitted the quotes, do not fetch incidents from previous quote again
      const shouldFetchPastIncidents =
        !!previousAutoQuoteRequestSfId && !quotesSubmitted && !fieldsEdited;
      let incidentsFromPreviousQuote = shouldFetchPastIncidents
        ? (await getIncidentsFromQuoteRequestId(previousAutoQuoteRequestSfId))
            .data
        : [];

      incidentsFromPreviousQuote = incidentsFromPreviousQuote
        .map((incident) => {
          // fill in missing fields with 0 for accidents and comp losses
          // otherwise there could be some weird behavior
          if (incident.recordtypeid === accidentsRecordTypeId) {
            for (const key of [
              "property_damage_amount__c",
              "bodily_injury_amount__c",
              "collision_amount__c",
              "medpay_amount__c",
            ]) {
              incident[key] = Math.floor(incident[key] ?? 0);
            }
          } else if (incident.recordtypeid === compLossesRecordTypeId) {
            incident["amount__c"] = Math.floor(incident["amount__c"] ?? 0);
          }

          // find driver in Redux with the same first name and dob
          const driverId =
            drivers.find(({ firstName, dob }) => {
              return (
                firstName === incident.driver?.firstName &&
                dob === incident.driver?.dob
              );
            })?.driverId ?? null;
          // if there's a driver, but we can't find it in Redux
          // don't include the incident
          if (incident.driver && !driverId) {
            return null;
          }
          incident.driver__c = driverId;

          delete incident.driver;

          return {
            ...incident,
            quote_request__c: contact.autoQuoteId,
          };
        })
        .filter(Boolean);

      setInitialValues((prev) => ({
        ...prev,
        ...autoDetails,
        incidents: [
          ...incidentsFromCurrentQuote,
          ...incidentsFromPreviousQuote,
        ],
      }));
    })();
  }, [
    contact.autoQuoteId,
    drivers,
    fieldsEdited,
    previousAutoQuoteRequestSfId,
    quotesSubmitted,
  ]);

  return isFetchingPriorLimits ? (
    <LoadingDots />
  ) : (
    <div className="justify-content-center align-items-center gap-5 d-flex flex-column my-5">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={autoDetailsValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => {
          const handleFieldChange = (event) => {
            setFieldsEdited();
            handleChange(event);
          };

          const addIncident = (type) => {
            setFieldValue("incidents", [
              ...values.incidents,
              { ...getDefaultIncident(type), type },
            ]);
          };
          const removeIncident = (index) => {
            setFieldValue(
              "incidents",
              values.incidents.map((incident, i) =>
                i === index ? null : incident
              )
            );
          };
          const isInvalidCarrier = values.prior_carrier === NO_PRIOR_INSURANCE;
          return (
            <StyledForm noValidate>
              <div className="d-flex flex-column align-items-center w-100 mb-4">
                <Heading text="Prior Auto Policy" bold />
                <FormContainer className="d-flex gap-4 flex-column">
                  <PriorPolicyUpdate>
                    <CustomSelect
                      className="form-control"
                      wrapperClassName="capsule"
                      isCentered={false}
                      id={PRIOR_CAREER_ID}
                      name={PRIOR_CAREER_ID}
                      label={PRIOR_CARRIER}
                      aria-label={PRIOR_CARRIER}
                      value={values.prior_carrier}
                      error={errors.prior_carrier}
                      handleChange={handleFieldChange}
                    >
                      <option value="">-Please Choose-</option>
                      {priorCarrierNames.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </CustomSelect>
                    <CustomInput
                      className="form-control"
                      disabled={isInvalidCarrier}
                      wrapperClassName="capsule"
                      placeholder="#"
                      isCentered={false}
                      type="number"
                      id="years_with_prior_carrier"
                      name="years_with_prior_carrier"
                      label={YEARS_WITH_PRIOR_CARRIER}
                      aria-label={YEARS_WITH_PRIOR_CARRIER}
                      value={
                        isInvalidCarrier ? 0 : values.years_with_prior_carrier
                      }
                      error={errors.years_with_prior_carrier}
                      onKeyDown={handleIntInputKeyDown}
                      handleChange={handleFieldChange}
                    />
                    <CustomSelect
                      className="form-control"
                      disabled={isInvalidCarrier}
                      wrapperClassName="capsule"
                      isCentered={false}
                      id="prior_liability_limits"
                      name="prior_liability_limits"
                      label="Prior Liability Limits"
                      aria-label="Prior Liability Limits"
                      value={
                        isInvalidCarrier ? "" : values.prior_liability_limits
                      }
                      error={errors.prior_liability_limits}
                      handleChange={handleFieldChange}
                    >
                      <option value="">-Please Choose-</option>
                      {PREVIOUS_LIMITS_BY_STATE[
                        contact.administrative_area_level_1
                      ].map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </CustomSelect>
                  </PriorPolicyUpdate>
                  {isPriorInsuranceFound && (
                    <PreviousPolicyDisplay>
                      <div className="header">
                        <span className="summary">Prior Insurance Found:</span>
                        <button
                          type="button"
                          onClick={() => {
                            setShouldDisplayPriorInsurance((prev) => !prev);
                          }}
                        >
                          {checkoutText}
                        </button>
                      </div>
                      {shouldDisplayPriorInsurance && (
                        <div className="values">
                          {priorInsuranceInfo.map(({ name, value }) => {
                            const valueDisplayed = !value
                              ? "Not Available"
                              : name !== "Expiration Date"
                              ? value
                              : moment(value).format("L");
                            return (
                              <div
                                key={name}
                                className="d-flex justify-content-between"
                              >
                                <span>{name}</span>
                                <span>{valueDisplayed}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </PreviousPolicyDisplay>
                  )}
                </FormContainer>
              </div>
              <div className="d-flex flex-column align-items-center w-100 mb-4">
                <Heading text="New Auto Policy" bold />
                <FormContainer className="d-flex gap-4 flex-column">
                  <NewAutoPolicy>
                    <Field
                      as={DatePicker}
                      label="Effective Date"
                      id="effective_date"
                      name="effective_date"
                      wrapperClassName="capsule justify-right"
                      error={errors.effective_date}
                      onChange={handleFieldChange}
                    />
                    {!isEmpty(
                      stateSpecificBodilyInjuryOptions[
                        contact.administrative_area_level_1
                      ]
                    ) && (
                      <CustomSelect
                        isCentered={false}
                        className="form-control"
                        wrapperClassName="capsule justify-left"
                        id="bodily_injury"
                        name="bodily_injury"
                        label="Bodily Injury"
                        aria-label="Bodily Injury"
                        value={values.bodily_injury}
                        handleChange={handleFieldChange}
                        error={errors.bodily_injury}
                      >
                        <option value="">- Select -</option>
                        {stateSpecificBodilyInjuryOptions[
                          contact.administrative_area_level_1
                        ].map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </CustomSelect>
                    )}
                  </NewAutoPolicy>
                </FormContainer>
              </div>
              <div className="d-flex flex-column align-items-center w-100 mb-4">
                <Heading text="Incidents" bold />
                <FormContainer className="d-flex gap-4 flex-column py-4">
                  <Incidents>
                    <IncidentButtons>
                      <CustomButton
                        className="capsule"
                        title="Add Accident"
                        light
                        plus
                        type="button"
                        handleClick={() => addIncident("Accident")}
                      />
                      <CustomButton
                        className="capsule"
                        title="Add Comp Loss"
                        light
                        plus
                        type="button"
                        handleClick={() => addIncident("Comp Loss")}
                      />
                      <CustomButton
                        className="capsule"
                        title="Add Violation"
                        light
                        plus
                        type="button"
                        handleClick={() => addIncident("Violation")}
                      />
                      <AutoIndicators wrapperClassName="indicators" />
                    </IncidentButtons>
                    {values.incidents.filter(Boolean).length > 0 && (
                      <AccidentWrapper>
                        {values.incidents.map((incident, index) => {
                          if (incident === null) return null;
                          return (
                            <IncidentInputLine
                              key={index}
                              index={index}
                              removeIncident={removeIncident}
                            />
                          );
                        })}
                      </AccidentWrapper>
                    )}
                  </Incidents>
                </FormContainer>
              </div>
              <div className="mt-4 d-flex flex-column gap-4 align-items-center">
                <CustomButton title="Submit" />
                <Disclaimer
                  text={contactDetailsPageDisclosure}
                  centered={true}
                />
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default AutoDetails;
