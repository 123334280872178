import { convertToCurrency } from "../utils/strings";
import styled from "styled-components";

const Currency = styled.sup`
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#358640")};
`;

const Value = styled.span`
  color: ${(props) => (props.color ? props.color : "#358640")};
  font-size: 24px;
  margin-top: 0px;
  font-weight: bold;
`;

const Frequency = styled.span`
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#358640")};
`;

const PriceInfo = styled.span`
  font-size: 13px;
`;

const Price = ({ value, currency, frequency, color, priceInfo }) => {
  const isValueNumeric = !isNaN(value);
  return (
    <span>
      {isValueNumeric && <Currency color={color}>{currency}</Currency>}
      <Value color={color} className="ml-1">
        {!isValueNumeric ? value : convertToCurrency(value)}
      </Value>
      {isValueNumeric && frequency && (
        <Frequency color={color} className="align-self-end my-0">
          {frequency === "monthly" ? "/mo" : "/yr"}
        </Frequency>
      )}
      {priceInfo && (
        <PriceInfo className="align-self-end ml-2">
          <span>{priceInfo}</span>
        </PriceInfo>
      )}
    </span>
  );
};
export default Price;
