import { DUMMY_DATA_FOR_LOGOS, DUMMY_RESPONSES } from "../DUMMY_TO_BE_DELETED";

// TODO: TO BE DELETED AFTER NEW DESIGN GOES LIVE
import { BundleQuoteListContainer } from "../components/new_design/BundleQuoteListContainer";
import { MonolineQuoteListContainer } from "../components/new_design/MonolineQuoteListContainer";
import { CarrierLogoName } from "../components/new_design/Quote";
import { BundleQuote } from "../redux/reducers/quoteReducer";

const homeQuotes = DUMMY_RESPONSES.filter(
  (quote) => quote.line_of_business__c === "Home"
);
const autoQuotes = DUMMY_RESPONSES.filter(
  (quote) => quote.line_of_business__c === "Auto"
);
const floodQuotes = DUMMY_RESPONSES.filter(
  (quote) => quote.line_of_business__c === "Flood"
);
const lifeQuotes = DUMMY_RESPONSES.filter(
  (quote) => quote.line_of_business__c === "Life"
);

const dummyBundles: BundleQuote[] = [
  { homeQuote: homeQuotes[0], autoQuote: autoQuotes[0] },
  { homeQuote: homeQuotes[1], autoQuote: autoQuotes[1] },
  { homeQuote: homeQuotes[2], autoQuote: autoQuotes[2] },
  { homeQuote: homeQuotes[3], autoQuote: autoQuotes[3] },
  { homeQuote: homeQuotes[4], autoQuote: autoQuotes[4] },
  { homeQuote: homeQuotes[5], autoQuote: autoQuotes[5] },
  { homeQuote: homeQuotes[6], autoQuote: autoQuotes[6] },
  { homeQuote: homeQuotes[7], autoQuote: autoQuotes[7] },
  { homeQuote: homeQuotes[8], autoQuote: autoQuotes[8] },
  { homeQuote: homeQuotes[9], autoQuote: autoQuotes[9] },
  { homeQuote: homeQuotes[10], autoQuote: autoQuotes[10] },
  { homeQuote: homeQuotes[11], autoQuote: autoQuotes[11] },
  { homeQuote: homeQuotes[12], autoQuote: autoQuotes[12] },
  { homeQuote: homeQuotes[13], autoQuote: autoQuotes[13] },
  { homeQuote: homeQuotes[14], autoQuote: autoQuotes[14] },
  { homeQuote: homeQuotes[15], autoQuote: autoQuotes[15] },
];

const ResponseDesignExample = () => {
  return (
    <div>
      {/* @ts-ignore this page is going to be removed anyways */}
      <BundleQuoteListContainer quoteBundles={dummyBundles} />
      <MonolineQuoteListContainer
        lob="home"
        quotesByType={{
          "Monoline Eligible": homeQuotes.slice(0, 7),
          "Package Required": homeQuotes.slice(5, 6),
          "Quote Manually": homeQuotes.slice(6, 7),
        }}
      />
      <MonolineQuoteListContainer
        lob="auto"
        quotesByType={{
          "Monoline Eligible": autoQuotes.slice(0, 5),
          "Package Required": autoQuotes.slice(5, 6),
          "Quote Manually": autoQuotes.slice(6, 7),
        }}
      />
      <MonolineQuoteListContainer
        lob="flood"
        quotesByType={{
          "Monoline Eligible": floodQuotes.slice(0, 5),
          "Package Required": floodQuotes.slice(5, 6),
          "Quote Manually": floodQuotes.slice(6, 7),
        }}
      />
      <MonolineQuoteListContainer
        lob="life"
        quotesByType={{
          "Monoline Eligible": lifeQuotes.slice(0, 5),
          "Package Required": lifeQuotes.slice(5, 6),
          "Quote Manually": lifeQuotes.slice(6, 7),
        }}
      />
      {DUMMY_DATA_FOR_LOGOS.map((item) => {
        return (
          <CarrierLogoName
            key={item.company_client_id__c}
            carrierId={item.company_client_id__c}
            carrierName={item.carrier__c}
          />
        );
      })}
    </div>
  );
};

export default ResponseDesignExample;
