// @ts-check
import isNil from "lodash/isNil";
import { useDispatch } from "react-redux";
import { useQuoteResponsesContext } from "../../contexts/QuoteResponsesContext";
import * as store from "../../redux/actions/quote";
import * as quoteService from "../../services/quote";
import { tryCatch } from "../../utils/errors";
import { createLogger } from "../../utils/logger";
import * as Flags from "./env";
import { pollQuoteResponse } from "./pollQuoteResponse";
import { useGetAgents } from "./useGetAgents";
import { toast } from "../../toast";

// types
// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars

/**
 * @param {{
 *   closeModal: () => void;
 *   lob: string;
 *   quoteResponse: Pick<
 *     AppTypes.QuoteResponse,
 *     | "carrier__c"
 *     | "carrier_login__c"
 *     | "company_client_id__c"
 *     | "heroku_id"
 *     | "line_of_business__c"
 *     | "package_rate__c"
 *     | "quote_request_heroku_id"
 *   >;
 *   skipDelete?: boolean;
 * }} input
 */
export const useResubmitQuoteResponse = ({
  closeModal,
  lob,
  quoteResponse,
  skipDelete = Flags.SKIP_DELETE,
}) => {
  const log = createLogger("useResubmitQuoteResponse");

  const dispatch = useDispatch();
  const getAgents = useGetAgents({ quoteResponse });

  const {
    insertNewQuoteResponses,
    removeQuoteResponse: _removeQuoteResponse,
    refreshQuoteResponses,
  } = useQuoteResponsesContext() ?? {};
  if (
    !insertNewQuoteResponses ||
    !_removeQuoteResponse ||
    !refreshQuoteResponses
  ) {
    log.warn({
      msg: `Not Ready: useResubmitQuoteResponse.${
        useQuoteResponsesContext.name
      }: ${{
        insertNewQuoteResponses,
        removeQuoteResponse: _removeQuoteResponse,
        refreshQuoteResponses,
      }}`,
    });
    return () => Promise.resolve(undefined);
  }

  /**
   * @param {Parameters<typeof _removeQuoteResponse>[0]} input
   * @returns {ReturnType<typeof _removeQuoteResponse>}
   */
  const removeQuoteResponse = (input) => {
    if (skipDelete) {
      return;
    }

    return _removeQuoteResponse(input);
  };

  const markQuoteForDeletion = (shouldDelete = true) => {
    if (skipDelete) {
      return;
    }

    dispatch(
      store.updateQuoteResponse({
        lob,
        quoteResponseId: quoteResponse.heroku_id,
        updates: {
          markedForDeletion: shouldDelete,
        },
        meta: { fn: "ActionButton.markQuoteForDeletion" },
      })
    );
  };

  const safelyDeleteResponseAPI = async () => {
    if (skipDelete) {
      return Promise.resolve();
    }

    try {
      return await quoteService.deleteQuoteResponse(quoteResponse.heroku_id);
    } catch (error) {
      log.warn({
        fn: `${log.label} - quoteService.deleteQuoteResponse`,
        msg: "Failed to delete old quote",
        error,
      });
    }
  };

  return async () => {
    try {
      markQuoteForDeletion(true);
      closeModal();
      const quoteResponseLabel = `${quoteResponse.carrier__c} - ${
        quoteResponse.line_of_business__c
      }${quoteResponse.package_rate__c ? "(bundled)" : ""}`;
      const resubmitToast = toast.info(
        `Resubmitting ${quoteResponseLabel}...`,
        {
          autoClose: false,
          isLoading: true,
        }
      );
      /** @param {Parameters<typeof toast.update>[1]} options */
      const updateToast = (options) =>
        toast.update(resubmitToast, {
          ...options,
        });
      /** @param {Parameters<typeof updateToast>[0]} options */
      const endToast = (options) =>
        updateToast({
          isLoading: false,
          autoClose: 5000,
          ...options,
        });

      await quoteService
        .resubmitQuoteResponse({
          quoteResponseId: quoteResponse.heroku_id,
          sfAgentId: getAgents().current,
        })
        .catch((error) => {
          log.error({
            fn: `${log.label} - quoteService.resubmitQuoteResponse`,
            msg: "Failed to call resubmit quote API",
            error,
          });

          endToast({
            render: `Failed to resubmit ${quoteResponseLabel}`,
            type: "error",
          });

          throw error;
        });

      updateToast({
        render: `Waiting on new quote for ${quoteResponseLabel}...`,
      });
      const newQuoteResponse = await pollQuoteResponse({
        quoteResponse,
        currentAgentId: getAgents().current,
      }).catch((error) => {
        log.warn({
          msg: "Failed to get new quote response",
          error,
        });
        return undefined;
      });

      if (isNil(newQuoteResponse)) {
        endToast({
          render: `Failed to find new quote for ${quoteResponseLabel}, refreshing list...`,
          type: "warning",
        });
        await safelyDeleteResponseAPI();
        return refreshQuoteResponses();
      } else {
        updateToast({
          render: `Found resubmitted quote for ${quoteResponseLabel}! Updating list...`,
          type: "success",
        });
        // don't need to wait here b/c we're going to manually remove it from memory
        safelyDeleteResponseAPI();
      }

      const successfullyRemoved = tryCatch(
        () => {
          removeQuoteResponse({
            ...quoteResponse,
            quoteResponseId: quoteResponse.heroku_id,
            line_of_business__c: quoteResponse.line_of_business__c || "",
          });
          return true;
        },
        (error) => {
          log.warn({
            msg: "Failed to remove old quote",
            error,
            quoteResponse,
          });

          return false;
        }
      );
      if (!successfullyRemoved) {
        endToast({
          render: `Failed to remove old quote for ${quoteResponseLabel}, refreshing list`,
          type: "warning",
        });
        return refreshQuoteResponses();
      }

      const successfullyInserted = tryCatch(
        () => {
          insertNewQuoteResponses({
            quoteResponses: [newQuoteResponse],
            quoteRequestId: quoteResponse.quote_request_heroku_id,
          });
          return true;
        },
        (error) => {
          log.warn({
            msg: "Failed to insert quote",
            error,
          });
          return false;
        }
      );
      if (!successfullyInserted) {
        endToast({
          render: `Failed to insert new quote for ${quoteResponseLabel}, refreshing list`,
          type: "warning",
        });
        return refreshQuoteResponses();
      }
      endToast({
        render: `Successfully resubmitted quote for ${quoteResponseLabel}!`,
        type: "success",
      });
    } catch (error) {
      // if we can't fully succeed AND we haven't deleted the quote through the API - show the quote again in the UI
      markQuoteForDeletion(false);

      log.warn({
        msg: "General error catcher, failed to resubmit quote",
        error,
      });
    }
  };
};
