import React, { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "../../redux/hooks";
import { useFormik } from "formik";
import styled from "styled-components";
import AviatorModal from "./AviatorModal";
import DatePicker from "../DatePicker";
import { DatePickerTypes } from "../../types";
import { getResubmitValidationSchema } from "../../utils/validation";
import { responsePageResubmit } from "../../utils/resubmit";
import { addCoverages } from "../../redux/actions/coverages";
import { addProperty } from "../../redux/actions/property";
import { getSessionPropertyLob } from "../../utilsMisc";

const ResubmitModalStyled = styled.div`
  & .row-equal-height {
    margin: 0px 22px 0px 22px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex: 1;
    & > [class*="col-"] {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
    }
  }
  & .header-row {
    margin-bottom: 10px;
    width: 262px;
    height: 20px;
    font-size: 12px;
    font-weight: normal;
    color: #2d2d2d;
    padding: 0 23px 0px 34px;
    flex-grow: 0;
    & :nth-child(2) {
      align-items: center;
    }
  }
  & .content-row {
    margin-bottom: 0;
    width: 262px;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding: 0;
    color: #626262;
    font-size: 16px;
    flex-grow: 0;
    align-content: flex-start;
    & :nth-child(2) {
      align-items: flex-end;
    }

    & .error {
      white-space: nowrap;
      margin-top: 5px;
      text-align: right;
      & small {
        font-size: 12px;
        font-weight: normal;
        color: #c30000;
      }
    }

    & .flex-column {
      height: auto;
    }

    & .date-picker {
      vertical-align: bottom;
    }

    & .selection {
      height: 20px;
      display: flex;
      align-items: center;
      gap: 15px;
      & .selectedLabel {
        color: #2d2c2a;
        font-weight: 500;
      }
    }
    & input[type="checkbox"] {
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: solid 1px #d6d6d6;
    }

    & input:checked {
      accent-color: #157f3a;
    }
  }
`;

type ResubmitQuotesModalProps = {
  setShowResubmitModal: (input: boolean) => void;
  showResubmitModal: boolean;
};

type ResubmitQuotesModalState = {
  isSubmitting: boolean;
  isHomePolicySelected: boolean;
  isAutoPolicySelected: boolean;
};

type EffectiveDate = {
  homeEffectiveDate?: string;
  autoEffectiveDate?: string;
};

const DatePickerProps: DatePickerTypes = {
  label: "",
  width: "139px",
  height: "40px",
  borderColor: "#d6d6d6",
  radius: "40px",
  fontFamily: "Rethink Sans Variable",
  fontSize: "14px",
  color: "#626262",
  className: "date-picker",
  wrapperClassName: "date-picker",
  errorFontSize: "12px",
  hideError: true,
  errorBorder: "#c30000",
};

const ResubmitQuotesModal = ({
  setShowResubmitModal,
  showResubmitModal,
}: ResubmitQuotesModalProps) => {
  const dispatch = useDispatch();
  const { homeQuoteId, autoQuoteId, newLoggedInUser, user } =
    useSelector((store) => store?.contact) ?? {};
  const property = useSelector((store) => store?.property) ?? {};
  const coverages = useSelector((store) => store?.coverages) ?? {};
  const initialState = {
    isSubmitting: false,
    isHomePolicySelected: !autoQuoteId, // If autoQuoteId is not present, then home policy is selected by default
    isAutoPolicySelected: !homeQuoteId,
  };
  const [resubmitQuotesModalState, setResubmitQuotesModalState] =
    useState<ResubmitQuotesModalState>(initialState);

  const homeType = getSessionPropertyLob();
  const validationSchema = useMemo(
    () => getResubmitValidationSchema(resubmitQuotesModalState),
    [resubmitQuotesModalState]
  );

  const initialValues: EffectiveDate = {
    autoEffectiveDate: coverages.autoEffectiveDate ?? "",
    homeEffectiveDate:
      property.effectiveDate ?? coverages.homeEffectiveDate ?? "",
  };
  const handleSubmit = async (resubmitData: EffectiveDate) => {
    setResubmitQuotesModalState({
      ...resubmitQuotesModalState,
      isSubmitting: true,
    });

    const { autoEffectiveDate = "", homeEffectiveDate = "" } = resubmitData;
    dispatch(
      addCoverages({
        ...coverages,
        ...(isAutoPolicySelected && {
          autoEffectiveDate,
        }),
        ...(isHomePolicySelected && {
          homeEffectiveDate,
        }),
      })
    );

    isHomePolicySelected &&
      dispatch(addProperty({ ...property, effectiveDate: homeEffectiveDate }));

    const resubmitOptions = {
      autoEffectiveDate: autoEffectiveDate,
      homeEffectiveDate: homeEffectiveDate,
      isHomePolicySelected,
      isAutoPolicySelected,
    };
    try {
      responsePageResubmit(newLoggedInUser ?? user, resubmitOptions);
      setShowResubmitModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
  };

  const {
    values: resubmitData,
    handleChange,
    errors,
    ...formik
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  });

  const { isSubmitting, isHomePolicySelected, isAutoPolicySelected } =
    resubmitQuotesModalState;

  const closeModal = () => {
    formik.resetForm(); //effective dates go back to previous quoted values
    setResubmitQuotesModalState(initialState);
    setShowResubmitModal(false);
  };

  return (
    <AviatorModal
      closeModal={closeModal}
      heading="Resubmit"
      show={showResubmitModal}
      hasSubmit={true}
      buttonText="Resubmit"
      dialogClassName="resubmit-modal"
      isDisabled={
        isSubmitting || (!isHomePolicySelected && !isAutoPolicySelected)
      }
      onClick={() => formik.handleSubmit()}
    >
      <ResubmitModalStyled>
        <div className="row row-equal-height header-row">
          <div className="col-md-5">Policy</div>
          <div className="col-md-7">Effective Date</div>
        </div>
        <div
          className="policyRowWrapper"
          style={{
            display: "inline-flex",
            rowGap: "31px",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {homeQuoteId && (
            <div className="row row-equal-height content-row">
              <div className="col-md-5 d-flex flex-column">
                <div className="selection">
                  <input
                    id="homeCheckBox"
                    type="checkbox"
                    checked={isHomePolicySelected}
                    onChange={() =>
                      setResubmitQuotesModalState({
                        ...resubmitQuotesModalState,
                        isHomePolicySelected: !isHomePolicySelected,
                      })
                    }
                  />
                  <label
                    className={isHomePolicySelected ? "selectedLabel" : ""}
                  >
                    {homeType}
                  </label>
                </div>
              </div>
              <div className="col-md-7 d-flex flex-column">
                <DatePicker
                  {...DatePickerProps}
                  id="homeEffectiveDate"
                  name="homeEffectiveDate"
                  onChange={handleFieldChange}
                  error={errors.homeEffectiveDate}
                  value={resubmitData.homeEffectiveDate}
                  data-private
                  disabled={!isHomePolicySelected}
                />
              </div>
              {errors.homeEffectiveDate && (
                <div className="col-md-12 d-flex flex-column error">
                  <small>{errors.homeEffectiveDate}</small>
                </div>
              )}
            </div>
          )}
          {autoQuoteId && (
            <div className="row row-equal-height content-row">
              <div className="col-md-5 d-flex flex-column">
                <div className="selection">
                  <input
                    id="autoCheckBox"
                    type="checkbox"
                    checked={isAutoPolicySelected}
                    onChange={() =>
                      setResubmitQuotesModalState({
                        ...resubmitQuotesModalState,
                        isAutoPolicySelected: !isAutoPolicySelected,
                      })
                    }
                  />
                  <label
                    className={isAutoPolicySelected ? "selectedLabel" : ""}
                  >
                    Auto
                  </label>
                </div>
              </div>
              <div className="col-md-7 d-flex flex-column">
                <DatePicker
                  {...DatePickerProps}
                  id="autoEffectiveDate"
                  name="autoEffectiveDate"
                  onChange={handleFieldChange}
                  error={errors.autoEffectiveDate}
                  value={resubmitData.autoEffectiveDate}
                  data-private
                  disabled={!isAutoPolicySelected}
                />
              </div>
              {errors.autoEffectiveDate && (
                <div className="col-md-12 d-flex flex-column error">
                  <small>{errors.autoEffectiveDate}</small>
                </div>
              )}
            </div>
          )}
        </div>
      </ResubmitModalStyled>
    </AviatorModal>
  );
};

export default ResubmitQuotesModal;
