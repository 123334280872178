import {
  Owner,
  Tenant,
  policyFormTypes,
  purchasingConstants,
} from "../constants";
import { useEffect, useState } from "react";
import { addPackage, addRent } from "../redux/actions/quote";
import { getPageName, getPagenumber } from "../utils/strings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomButton from "../components/styled/buttons/CustomButton";
import Heading from "../components/Heading";
import { IconImage } from "../components/styled/images";
import LinkButton from "../components/styled/buttons/LinkButton";
import LoadingDots from "../components/LoadingDots";
import { addContact } from "../redux/actions/contact";
import { addProperty } from "../redux/actions/property";
import autoIcon from "../assets/images/main/auto-icon.svg";
import { clearPropertyOwnerDetails } from "../utils/propertyProcess";
import condoPolicyActiveIcon from "../assets/images/main/condo_policy_active.svg";
import condoPolicyDefaultIcon from "../assets/images/main/condo_policy_default.svg";
import dwellingPolicyActiveIcon from "../assets/images/main/dwelling_policy_active.svg";
import dwellingPolicyDefaultIcon from "../assets/images/main/dwelling_policy_default.svg";
import { getIsReshop } from "../utils/reshop";
import { handleUpdateSession } from "../services/utils";
import homePolicyActiveIcon from "../assets/images/main/home_policy_active.svg";
import homePolicyDefaultIcon from "../assets/images/main/home_policy_default.svg";
import rentersPolicyActiveIcon from "../assets/images/main/renters_policy_active.svg";
import rentersPolicyDefaultIcon from "../assets/images/main/renters_policy_default.svg";
import { setFieldsEdited } from "../utils";
import { updateIsFetching } from "../redux/actions/fetching";
import { updateProperty } from "../services/property";
import { updateQuote } from "../services/quote";

const { Home, Auto, Condo, Dwelling } = purchasingConstants;

const PolicyType = () => {
  const params = useParams();

  const session = useSelector((store) => store.session);

  const contact = useSelector((store) => store.contact);

  const isFetching = useSelector((store) => store.isFetching);
  const property = useSelector((store) => store.property);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [selectedPolicy, setSelectedPolicy] = useState(
    contact.selectedPolicy || "Home"
  );

  const updateQuoteandPropertyDetails = async (
    homeQuoteDetails,
    autoQuoteDetails,
    homePropertyDetails
  ) => {
    if (Object.keys(homeQuoteDetails).length) {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        ...homeQuoteDetails,
      });
    }

    if (Object.keys(autoQuoteDetails).length) {
      await updateQuote({
        quoteId: contact.autoQuoteId,
        ...autoQuoteDetails,
      });
    }

    if (Object.keys(homePropertyDetails).length) {
      await updateProperty({
        heroku_id: contact.propertyId,
        ...homePropertyDetails,
      });
    }
  };

  const handleSelectPolicy = (lob) => {
    setFieldsEdited();
    setSelectedPolicy(lob);
  };

  const updateSessionAndStore = async (lob) => {
    try {
      const sessionData = await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: lob,
        page_stopped: getPagenumber(lob, params.page) + 1,
      });

      dispatch(
        addContact({
          selectedPolicy: selectedPolicy,
        })
      );

      navigate(
        `/agent/${lob}/${getPageName(
          sessionData.line_of_business,
          sessionData.page_stopped
        )}`
      );
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const handleNext = async () => {
    try {
      dispatch(updateIsFetching(true));
      let updatedHomeQuoteDetails = {};
      let updatedHomePropertyDetails = {};
      let updatedAutoQuoteDetails = {};

      const updateHomePropertyAndAutoDetails = (
        lineOfBusiness,
        residenceType,
        policyForm
      ) => {
        if (contact.homeQuoteId) {
          updatedHomeQuoteDetails = {
            line_of_business__c: lineOfBusiness,
            residence_type__c: residenceType,
            policy_form__c: policyForm,
          };
          updatedHomePropertyDetails = {
            policy_form__c: policyForm,
          };
        }
        if (contact.autoQuoteId) {
          updatedAutoQuoteDetails = {
            residence_type__c: residenceType,
          };
        }
      };

      dispatch(addRent(false));
      if (selectedPolicy === Home || selectedPolicy === Dwelling) {
        dispatch(
          addProperty({ occupancy: selectedPolicy === Home ? Owner : Tenant })
        );
        if (!session.line_of_business.includes("Home")) {
          updateHomePropertyAndAutoDetails(
            purchasingConstants.Home,
            purchasingConstants.Own,
            policyFormTypes.HO3
          );
        }
      } else if (selectedPolicy === Condo) {
        dispatch(addProperty({ occupancy: Owner }));
        if (!session.line_of_business.includes("Condo")) {
          updateHomePropertyAndAutoDetails(
            purchasingConstants.Condo,
            purchasingConstants.Own,
            policyFormTypes.HO6
          );
        }
      } else if (selectedPolicy === "Renter") {
        dispatch(addRent(true));
        dispatch(addProperty({ occupancy: null }));
        if (!contact.accountId && !contact.sfAccountId) {
          dispatch(
            addProperty({
              yearBuilt: 2018,
              numStories: "1",
              lastSalesDate: "2018-01-01",
            })
          );
          clearPropertyOwnerDetails();
        }
        if (!session.line_of_business.includes("Rent")) {
          updateHomePropertyAndAutoDetails(
            "Renter's",
            purchasingConstants.Rent,
            policyFormTypes.HO4
          );
        }
      }
      await updateQuoteandPropertyDetails(
        updatedHomeQuoteDetails,
        updatedAutoQuoteDetails,
        updatedHomePropertyDetails
      );

      await updateSessionAndStore(
        `${selectedPolicy === Dwelling ? "Home" : selectedPolicy}Auto`
      );

      dispatch(updateIsFetching(false));
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const handleAutoClick = async () => {
    try {
      if (!contact.accountId && !contact.sfAccountId) {
        clearPropertyOwnerDetails();
      }

      dispatch(
        addContact({
          homeQuoteId: undefined,
          spouseHomeQuoteId: undefined,
        })
      );
      if (!getIsReshop()) {
        dispatch(addRent(true));
      }
      const sessionData = await handleUpdateSession({
        uuid: session.uuid,
        line_of_business: Auto,
        page_stopped: 2,
        contact_data: JSON.stringify({
          ...contact,
          homeQuoteId: undefined,
          spouseHomeQuoteId: undefined,
        }),
      });

      dispatch(addPackage(false));
      navigate(
        `/agent/${Auto}/${getPageName(
          sessionData.line_of_business,
          sessionData.page_stopped
        )}`
      );
    } catch (e) {
      console.log("Something has gone wrong ", e);
    }
  };

  const [canContinue, setCanContinue] = useState(false);
  const propertyStreetNumber = property?.street_number;
  useEffect(() => {
    setCanContinue(Boolean(propertyStreetNumber));
  }, [propertyStreetNumber]);

  return isFetching ? (
    <LoadingDots />
  ) : (
    <>
      <div className="mt-2">
        <Heading text="Choose your Policy" bold />
      </div>
      <div className="d-flex justify-content-center my-5">
        <IconImage
          src={
            selectedPolicy === "Home"
              ? homePolicyActiveIcon
              : homePolicyDefaultIcon
          }
          size="35px"
          className="mx-4"
          onClick={() => handleSelectPolicy("Home")}
        />
        <IconImage
          src={
            selectedPolicy === "Renter"
              ? rentersPolicyActiveIcon
              : rentersPolicyDefaultIcon
          }
          size="35px"
          className="mx-4"
          onClick={() => handleSelectPolicy("Renter")}
        />

        <IconImage
          src={
            selectedPolicy === "Condo"
              ? condoPolicyActiveIcon
              : condoPolicyDefaultIcon
          }
          size="35px"
          className="mx-4"
          onClick={() => handleSelectPolicy("Condo")}
        />

        <IconImage
          src={
            selectedPolicy === "Dwelling"
              ? dwellingPolicyActiveIcon
              : dwellingPolicyDefaultIcon
          }
          size="35px"
          className="mx-4"
          onClick={() => handleSelectPolicy("Dwelling")}
        />
      </div>

      <div className="mt-4 mb-3 justify-content-center d-flex">
        <CustomButton
          title="Continue"
          disabled={!canContinue}
          handleClick={handleNext}
        />
      </div>
      <div className="text-center">
        <IconImage src={autoIcon} className="img-fluid me-1" alt="auto-icon" />
        <LinkButton text="Only Quote Auto" handleClick={handleAutoClick} />
      </div>
    </>
  );
};

export default PolicyType;
