import "@fontsource-variable/rethink-sans";

import Paths from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Paths />
    </Router>
  );
}

export default App;
