import Auto from "./assets/images/progressBar/Auto.svg";
import AutoCurrent from "./assets/images/progressBar/AutoCurrent.svg";
import AutoDisabled from "./assets/images/progressBar/AutoDisabled.svg";
import Client from "./assets/images/progressBar/Client.svg";
import ClientCurrent from "./assets/images/progressBar/ClientCurrent.svg";
import ClientDisabled from "./assets/images/progressBar/ClientDisabled.svg";
import Condo from "./assets/images/progressBar/Condo.svg";
import CondoCurrent from "./assets/images/progressBar/CondoCurrent.svg";
import CondoDisabled from "./assets/images/progressBar/CondoDisabled.svg";
import Dwelling from "./assets/images/progressBar/Dwelling.svg";
import DwellingCurrent from "./assets/images/progressBar/DwellingCurrent.svg";
import DwellingDisabled from "./assets/images/progressBar/DwellingDisabled.svg";
import Home from "./assets/images/progressBar/Home.svg";
import HomeCurrent from "./assets/images/progressBar/HomeCurrent.svg";
import HomeDisabled from "./assets/images/progressBar/HomeDisabled.svg";
import Policy from "./assets/images/progressBar/Policy.svg";
import PolicyCurrent from "./assets/images/progressBar/PolicyCurrent.svg";
import PolicyDisabled from "./assets/images/progressBar/PolicyDisabled.svg";
import Renters from "./assets/images/progressBar/Renters.svg";
import RentersCurrent from "./assets/images/progressBar/RentersCurrent.svg";
import RentersDisabled from "./assets/images/progressBar/RentersDisabled.svg";
import Results from "./assets/images/progressBar/Results.svg";
import ResultsCurrent from "./assets/images/progressBar/ResultsCurrent.svg";
import ResultsDisabled from "./assets/images/progressBar/ResultsDisabled.svg";
import autoIcon from "./assets/images/main/auto-icon.svg";
import condoIcon from "./assets/images/main/condo-icon.svg";
import dwellingIcon from "./assets/images/main/dwelling-icon.svg";
import floodIcon from "./assets/images/main/flood-icon.svg";
import homeIcon from "./assets/images/main/home-icon.svg";
import jewelryIcon from "./assets/images/main/jewelry-icon.svg";
import lifeIcon from "./assets/images/main/life-icon.svg";
import rentersIcon from "./assets/images/main/renters-icon.svg";
import smartHomeIcon from "./assets/images/main/smart-home-icon.svg";
import stateConfigsJson from "./constants.state-configs.json";

export const isProduction = process.env.REACT_APP_ENV === "production";

export const baseURLS = {
  development: "http://localhost:5000",
  test: "https://test-agent-rater-api.herokuapp.com",
  production: "https://agent-api.goosehead.com",
  "dev-test": "https://dev-agent-rater-api.herokuapp.com",
  feature: "https://feature-goosehead-rater-api.herokuapp.com",
  "core-platform": "https://dev-agent-rater-api-2.herokuapp.com",
  uat: "https://uat.agent-quotes-api.goosehead.com",
  review: process.env.REACT_APP_AGENT_API,
};

export const bindURLS = {
  development: "http://localhost:3001",
  test: "https://quotes-test.goosehead.com",
  production: "https://quotes.goosehead.com",
  "dev-test": "https://quotes-dev.goosehead.com",
  feature: "https://dev-client-rater-react-2.herokuapp.com",
  "core-platform": "https://dev-client-rater-react-2.herokuapp.com",
  uat: "https://uat.quotes.goosehead.com",
  review: process.env.REACT_APP_CLIENT_URL,
};

export const CRAWLSPACE = "Crawlspace";

export const foundationMap = {
  slab: "Slab",
  piers: "Pier & Beam",
  pier: "Pier & Beam",
  beam: "Pier & Beam",
  basement: "Basement",
  bsmt: "Basement",
  bmt: "Basement",
  crawl: CRAWLSPACE,
  crawlspace: CRAWLSPACE,
};

export const allowedFoundations = [
  "Slab",
  "Pier & Beam",
  "Basement",
  "Crawlspace",
];

export const BASEMENT = "Basement";

export const wallMap = {
  ADOBE: "Brick Veneer",
  "ALUM/BRICK": "Brick Veneer",
  ALUMINUM: "Frame",
  "ALUMINUM/VINYL": "Frame",
  ASBESTOS: "Frame",
  BLOCK: "Brick Veneer",
  "BLOCK/MASONRY": "Brick Veneer",
  "BLOCK/STUCCO": "Stucco",
  BOARD: "Frame",
  BRICK: "Brick Veneer",
  "BRICK/CONCRETE": "Brick Veneer",
  "BRICK/MASONRY": "Brick Veneer",
  "BRICK/METAL": "Brick Veneer",
  "BRICK/SIDING": "Brick Veneer",
  "BRICK/STONE": "Brick Veneer",
  "BRICK/VINYL": "Brick Veneer",
  COMPOSITION: "Brick Veneer",
  CONCRETE: "Hardiboard",
  "CONCRETE/DRYVIT": "Hardiboard",
  FIBERGLASS: "Frame",
  FRAME: "Frame",
  "FRAME/MASONRY": "Frame",
  "FRAME/METAL": "Frame",
  "FRAME/PLYWOOD": "Frame",
  "FRAME/STUCCO": "Frame",
  "FRAME/VINYL": "Frame",
  HARDBOARD: "Hardiboard",
  LOG: "Frame",
  MASONITE: "Brick Veneer",
  "MASONITE/SHEATING": "Brick Veneer",
  MASONRY: "Brick Veneer",
  METAL: "Frame",
  PAINT: "Frame",
  PLASTER: "Frame",
  PLYWOOD: "Frame",
  PREFAB: "Frame",
  "ROCK/STONE": "Brick Veneer",
  SHINGLE: "Frame",
  SIDING: "Frame",
  SINGLE: "Frame",
  STEEL: "Brick Veneer",
  STONE: "Brick Veneer",
  "STONE/SIDING": "Brick Veneer",
  "STONE/STUCCO": "Brick Veneer",
  "STONE/VENEER": "Brick Veneer",
  STUCCO: "Stucco",
  "STUCCO/MASONRY": "Stucco",
  "STUCCO/METAL": "Stucco",
  "STUCCO/TILE/WOOD": "Stucco",
  "STUCCO/WOOD": "Stucco",
  VINYL: "Frame",
  "VINYL/WOOD": "Frame",
  WEATHERBOARD: "Hardiboard",
  WOOD: "Frame",
  "WOOD/ALUM": "Frame",
  "WOOD/BRICK": "Brick Veneer",
  "WOOD/STONE": "Brick Veneer",
};

export const All = "All";
export const home = "home";
export const auto = "auto";
export const flood = "flood";
export const life = "life";
export const umbrella = "umbrella";
export const UMBRELLA_LINE_OF_BUSINESS = "Umbrella";
export const jewelry = "jewelry";
export const security = "security";
export const bundle = "bundle";
export const lifePrimary = "lifePrimary";
export const lifeSpouse = "lifeSpouse";
export const PRIOR_CARRIER = "Prior Carrier";
export const PRIOR_CAREER_ID = "prior_carrier";
export const NO_PRIOR_INSURANCE = "No Prior Insurance";
export const PRIOR_LIABILITY_LIMITS = "Prior Liability Limits";
export const YEARS_WITH_PRIOR_CARRIER = "Years with Prior Carrier";
export const MONTHS_WITH_PRIOR_CARRIER = "Months with Prior Carrier";
export const BODILY_INJURY = "Bodily Injury";
export const COMPREHENSIVE_DEDUCTIBLE = "Comprehensive Deductible";
export const COLLISION_DEDUCTIBLE = "Collision Deductible";
export const ACCIDENTS = "Accidents";
export const VIOLATIONS = "Violations";
export const COMP_LOSSES = "Comp Losses";
export const ADD_VIOLATION = "Add Violation";
export const ADD_ACCIDENT = "Add Accident";
export const ADD_COMP_LOSS = "Add Comp Loss";
export const DATE = "Date";
export const DRIVER = "Driver";
export const VEHICLE = "Vehicle";
export const AMOUNT = "Amount";
export const INDIVIDUAL_CAR_COVERAGES = "Individual Car Coverages";
export const DESCRIPTION = "Description";
export const PD_AMOUNT = "PD Amount";
export const BI_AMOUNT = "BI Amount";
export const BI = "BI";
export const PD = "PD";
export const UMBI = "UMBI";
export const UMPD = "UMPD";
export const PIP = "PIP";
export const MEDPAY = "Medpay";
export const RENTAL = "Rental";
export const ROADSIDE = "Roadside";
export const COLLISION = "Collision";
export const COMPREHENSIVE = "Comprehensive";
export const COLL_AMOUNT = "Coll Amount";
export const MP_AMOUNT = "MP Amount";
export const SAVE_SUBMIT = "Save & Submit";
export const PRIOR_LIMITS_REPORT = "Prior Limits Report";
export const UPDATE_PRIOR_AUTO_LIMITS = "Update Prior Auto Limits";
export const CARRIER = "Carrier";
export const EXPIRATION_DATE = "Expiration Date";
export const CANCEL = "Cancel";
export const CLOSE = "Close";
export const CANCEL_CONFIRMATION_TEXT =
  "Changes made won't be saved if you close. Are you sure you want to close?";
export const SUMMARY = "summary";
export const HISTORY = "history";

export const ReferralPartner = "Referral Partner";
export const ClientReferral = "Client Referral";

export const purchasingConstants = {
  Yes: "Yes",
  No: "No",
  houseOrCondo: "houseOrCondo",
  ownOrRent: "ownOrRent",
  purchasingText: "New Home Purchase?",
  ownHouseCondoText: "What is the policy type?",
  newHouseCondoText: "What is the policy type?",
  House: "House",
  Condo: "Condo",
  ownOrRentText: "Does your client own or rent?",
  Own: "Own",
  Rent: "Rent",
  Auto: "Auto",
  Home: "Home",
  Dwelling: "Dwelling",
  HomeAuto: "HomeAuto",
  Renter: "Renter",
  RenterAuto: "RenterAuto",
  CondoAuto: "CondoAuto",
};

// -1: skipped
// numbers: the order of the pages found in pageNames
export const pageIndices = {
  HomeAuto: [1, 2, 3, 4, 5, 6, 7],
  RenterAuto: [1, 2, 3, 4, 5, 6, 7],
  CondoAuto: [1, 2, 3, 4, 5, 6, 7],
  Home: [1, 2, 3, -1, -1, -1, 4],
  Renter: [1, 2, 3, -1, -1, -1, 4],
  Auto: [1, -1, 2, 3, 4, 5, 6],
  Condo: [1, 2, 3, -1, -1, -1, 4],
};

export const pageNames = [
  "PolicyType",
  "PropertyInfo",
  "AboutYou",
  "Drivers",
  "Vehicles",
  "AutoDetails",
  "Responses",
];

export const violationsDescriptionOptions = [
  { value: "Careless Driving", display: "Careless Driving" },
  { value: "Cell Phone", display: "Cell Phone" },
  { value: "Child Safety Restraint", display: " Child Safety Restraint" },
  { value: "Defective Equipment", display: "Defective Equipment" },
  { value: "Divided Highways", display: "Divided Highways" },
  { value: "Double Lines", display: "Double Lines" },
  { value: "Driving Left of Center", display: " Driving Left of Center" },
  { value: "Driving on Sus. License", display: "  Driving on Sus. License" },
  { value: "Driving too slow", display: "Driving too slow" },
  { value: "Driving without lights", display: "Driving without lights" },
  { value: "DUI", display: "DUI" },
  { value: "Eluding Police", display: "Eluding Police" },
  { value: "Failure to Obey Signal", display: " Failure to Obey Signal" },
  { value: "Failure to Stop", display: "Failure to Stop" },
  { value: "Failure to Yield", display: "Failure to Yield" },
  {
    value: "Failure To Observe A Safety Zone",
    display: "  Failure to Observe a Safety Zone",
  },
  {
    value: "Failure to show documents",
    display: "  Failure to show documents",
  },
  { value: "False Reporting", display: "False Reporting" },
  { value: "Felony", display: "Felony" },
  { value: "Following too Closely", display: "Following too Closely" },
  { value: "Homicide", display: "Homicide" },
  { value: "Illegal Turn", display: "Illegal Turn" },
  { value: "Improper Parking", display: "Improper Parking" },
  { value: "Improper Passing", display: "Improper Passing" },
  { value: "Improper Loads", display: "Improper Loads" },
  {
    value: "Leaving scene of an Accident/Hit and Run",
    display: "Leaving scene of an Accident/Hit and Run",
  },
  { value: "Motorcycle Violation", display: "  Motorcycle Violation" },
  { value: "Other Major", display: "Other Major" },
  { value: "Other Minor", display: "Other Minor" },
  { value: "Open Container", display: "Open Container" },
  {
    value: "Operating Vehicle without Permission",
    display: "Operating Vehicle without Permission",
  },
  { value: "Out of State", display: "Out of State" },
  { value: "Passing School Bus", display: "Passing School Bus" },
  { value: "Racing/Drag Racing", display: "Racing/Drag Racing" },
  { value: "Recreational Vehicle", display: " Recreational Vehicle" },
  {
    value: "Refusal to submit to chemical test",
    display: " Refusal to submit to chemical test",
  },
  { value: "Speeding 1-5", display: "Speeding 1-5" },
  { value: "Speeding 6-10", display: "Speeding 6-10" },
  { value: "Speeding 11-15", display: "Speeding 11-15" },
  { value: "Speeding 16-20", display: "Speeding 16-20" },
  { value: "Speeding 21+", display: "Speeding 21+" },
  { value: "Speed over 100mph", display: "Speed over 100mph" },
  { value: "Seat Belt", display: "Seat Belt" },
  { value: "Suspension", display: "Suspension" },
  {
    value: "Ticket Violation Not Listed",
    display: "Ticket Violation Not Listed",
  },
  { value: "Towing", display: "Towing" },
  {
    value: "Transportation of Hazardous Materials",
    display: "  Transportation of Hazardous Materials",
  },
  {
    value: "Unsafe Operation of a Motor Vehicle",
    display: " Unsafe Operation of a Motor Vehicle",
  },
  { value: "Vehicle Theft", display: "Vehicle Theft" },
  { value: "Wrong Way/Wrong Lane", display: " Wrong Way/Wrong Lane" },
];

export const compLossesDescriptionOptions = [
  { value: "Towing", display: "Towing" },
  { value: "Fire", display: "Fire" },
  { value: "Theft", display: "Theft" },
  { value: "Vandalism", display: "Vandalism" },
  { value: "Glass", display: "Glass" },
  { value: "Tornado/Hurricane", display: "Tornado/Hurricane" },
  { value: "Flood", display: "Flood" },
  { value: "Wind/Hail", display: "Wind/Hail" },
  { value: "All Other", display: "All Other" },
];

export const accidentDescriptionOptions = [
  { value: "At Fault Accident", display: "At Fault Accident" },
  { value: "Not at Fault Accident", display: "Not at Fault Accident" },
  { value: "At Fault With No Injury", display: "At Fault With No Injury" },
];

export const INCIDENT_TYPE_OPTIONS_MAP = {
  Accident: accidentDescriptionOptions,
  "Comp Loss": compLossesDescriptionOptions,
  Violation: violationsDescriptionOptions,
};

export const accidentsRecordTypeId = "012C0000000Qf0MIAS";
export const violationsRecordTypeId = "012C0000000Qf0OIAS";
export const compLossesRecordTypeId = "012C0000000Qf0NIAS";

export const INCIDENT_TYPE_RECORD_ID_MAP = {
  Accident: accidentsRecordTypeId,
  "Comp Loss": compLossesRecordTypeId,
  Violation: violationsRecordTypeId,
};

export const PRIMARY = "primary";
export const SPOUSE = "spouse";
export const BEST = "best";

export const ssnPattern =
  /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;

export const bodilyInjuryToPropertyDamageMap = {
  "10/20": "10000",
  "15/30": "25000",
  "25/50": "25000",
  "50/100": "50000",
  "100/300": "100000",
  "250/500": "100000",
  "100 CSL": "No Coverage",
  "300 CSL": "No Coverage",
  "500 CSL": "No Coverage",
  "1 Mil CSL": "No Coverage",
};

export const bodilyInjuryOptions = [
  "15/30",
  "20/40",
  "25/50",
  "25/65",
  "30/60",
  "50/100",
  "100/300",
  "250/500",
  "100 CSL",
  "300 CSL",
  "500 CSL",
  "1 Mil CSL",
];

export const DEFAULT_BODILY_INJURY = "250/500";

export const DEFAULT_UMBRELLA_COVERAGE = 1000000;

export const STATE_COVERAGES = {
  home: [
    {
      key: "dwelling_limit__c",
      name: "Dwelling",
      isNotForRenter: true,
      isDollar: true,
      states: ["ALL"],
    },
    {
      key: "other_structures__c",
      name: "Other Structures",
      isNotForRenter: true,
      isDollar: true,
      states: ["ALL"],
    },
    {
      key: "personal_property__c",
      name: "Personal Property",
      isDollar: true,
      states: ["ALL"],
    },
    {
      key: "loss_of_use__c",
      name: "Loss of Use",
      isDollar: true,
      states: ["ALL"],
    },
    { key: "liability__c", name: "Liability", isDollar: true, states: ["ALL"] },
    {
      key: "medical_payments__c",
      name: "Medical Payment",
      isDollar: true,
      states: ["ALL"],
    },
    {
      key: "aop_deductible__c",
      name: "All Other Deductible",
      shouldFormatDeductible: true,
      states: ["ALL"],
    },
    {
      key: "w_h_deductible__c",
      name: "Wind / Hail Deductible",
      isNotForRenter: true,
      shouldFormatDeductible: true,
      states: [
        "AL",
        "AK",
        "AZ",
        "CO",
        "CT",
        "DC",
        "DE",
        "GA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MO",
        "MN",
        "NV",
        "NJ",
        "NM",
        "NY",
        "NC",
        "OH",
        "OK",
        "OR",
        "PA",
        "SC",
        "TN",
        "TX",
        "UC",
        "VA",
        "WA",
        "WI",
        "WY",
      ],
    },
  ],
  auto: [
    { key: "liability", name: "Liability", states: ["ALL"] },
    {
      key: "umuimumpd",
      name: "UM/UIM/UMPD",
      states: [
        "AR",
        "CA",
        "DC",
        "FL",
        "GA",
        "ID",
        "IN",
        "KY",
        "LA",
        "MD",
        "MS",
        "MT",
        "NJ",
        "NM",
        "NC",
        "OH",
        "OR",
        "RI",
        "SC",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
      ],
    },
    {
      key: "umuim",
      name: "UM/UIM",
      states: [
        "AL",
        "AK",
        "AZ",
        "CT",
        "CO",
        "FL",
        "DE",
        "HI",
        "IL",
        "IA",
        "KS",
        "ME",
        "MI",
        "MN",
        "MO",
        "NE",
        "NV",
        "NH",
        "NY",
        "ND",
        "OK",
        "PA",
        "SD",
        "WI",
        "WY",
      ],
    },
    {
      key: "pip",
      name: "PIP",
      isDollar: true,
      states: [
        "AR",
        "DE",
        "KY",
        "MD",
        "MN",
        "NJ",
        "NY",
        "NC",
        "ND",
        "OR",
        "TX",
        "UT",
        "WA",
      ],
    },
    {
      key: "medPay",
      name: "Medpay",
      isDollar: true,
      states: [
        "AK",
        "AL",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DC",
        "FL",
        "GA",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "LA",
        "MA",
        "ME",
        "MN",
        "MO",
        "MS",
        "MT",
        "ND",
        "NE",
        "NH",
        "NM",
        "NV",
        "OH",
        "OK",
        "RI",
        "SC",
        "SD",
        "TN",
        "UT",
        "VA",
        "VT",
        "WI",
        "WV",
        "WY",
      ],
    },
    { key: "rental", name: "Rental Reimbursement", states: ["ALL"] },
    { key: "towing", name: "Roadside Assistance", states: ["ALL"] },
  ],
};

export const stateSpecificBodilyInjuryOptions = {
  AK: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  AL: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  AR: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  AZ: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  CA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  CO: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  CT: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  DC: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  DE: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  FL: [
    "10/20",
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  GA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  HI: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  IA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  ID: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  IL: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  IN: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  KS: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  KY: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  LA: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MA: [],
  MD: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  ME: [
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MI: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  MN: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  MO: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  MS: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  MT: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NC: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  ND: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NE: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NH: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NJ: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NM: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NV: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  NY: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  OH: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  OK: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  OR: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  PA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  RI: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  SC: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  SD: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  TN: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  TX: [
    "50/100",
    "100/300",
    "250/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
    "1 Mil CSL",
  ],
  UT: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  VA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  VT: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  WA: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  WI: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  WV: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
  WY: ["50/100", "100/300", "250/500", "100 CSL", "300 CSL", "500 CSL"],
};

export const PREVIOUS_LIMITS_BY_STATE = {
  AK: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  AL: [
    "15/30",
    "20/40",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  AR: [
    "10/20",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  AZ: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  CA: [
    "15/30",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  CO: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  CT: [
    "20/40",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  DC: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  DE: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  FL: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  GA: [
    "10/20",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  HI: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  IA: [
    "20/40",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  ID: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  IL: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  IN: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  KS: [
    "15/30",
    "20/40",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  KY: [
    "10/20",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  LA: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MA: [
    "15/30",
    "20/40",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MD: [
    "10/20",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  ME: [
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MI: [
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MN: [
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MO: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MS: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  MT: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NC: [
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  ND: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NE: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NH: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NJ: [
    "15/30",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NM: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NV: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  NY: [
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
    "1Mil CSL",
  ],
  OH: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  OK: [
    "15/30",
    "20/40",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  OR: [
    "10/20",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  PA: [
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  RI: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  SC: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  SD: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  TN: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  TX: [
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
    "1Mil CSL",
  ],
  UT: [
    "25/65",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  VA: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  VT: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  WA: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  WI: [
    "15/30",
    "20/40",
    "25/50",
    "30/60",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  WV: [
    "10/20",
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
  WY: [
    "25/50",
    "50/100",
    "100/300",
    "250/500",
    "300/500",
    "500/500",
    "100 CSL",
    "300 CSL",
    "500 CSL",
  ],
};

export const optionalBodilyInjuryOptionsMassachusetts = [
  "No Coverage",
  "50/100",
  "100/300",
  "250/500",
];

export const DEFAULT_BODILY_INJURY_MASSACHUSETTS = "100/300";

export const languagesList = ["English", "Spanish"];

export const companyIds = {
  Branch: "994",
};

export const statesWithoutCoAndPdTogether = ["CO", "MS", "OH", "SC", "TX"];

export const collisionAndComprehensiveDeductibles = [
  { label: "$500", value: "500" },
  { label: "$1,000", value: "1000" },
  { label: "No Coverage", value: "No Coverage" },
];

export const priorCarrierNames = [
  "Allstate",
  "American Family",
  "Farmers",
  "Geico",
  "Liberty Mutual",
  "Nationwide",
  "Progressive",
  "State Farm",
  "Travelers",
  "USAA",
  "Other - Standard",
  "No Prior Insurance",
];

export const mapOccupancyToPolicyForm = {
  owner: "HO3",
  tenant: "DP3",
  vacant: "DP3",
};

export const policyFormTypes = {
  HO3: "HO3",
  HO4: "HO4",
  HO6: "HO6",
  DP3: "DP3",
};

export const stateSpecificWindHailOptions = {
  AL: [
    {
      label: "$1,000",
      value: "1000",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  AR: [],
  AZ: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  CA: [],
  CO: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  CT: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  DC: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  DE: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  FL: [],
  GA: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  HI: [],
  IA: [],
  ID: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$7,500", value: "7500" },
  ],
  IL: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  IN: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  KS: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  KY: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  LA: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
    {
      label: "3%",
      value: "3%",
    },
    {
      label: "EXCL",
      value: "EXCL",
    },
  ],
  MA: [],
  MD: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  MI: [],
  MN: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
  ],
  MO: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  MS: [],
  MT: [],
  NC: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  ND: [],
  NE: [],
  NH: [],
  NJ: [
    { label: "$1,000", value: "1000" },
    {
      label: "$2,000",
      value: "2000",
    },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  NM: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  NV: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  NY: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    {
      label: "2%",
      value: "2%",
    },
    {
      label: "5%",
      value: "5%",
    },
  ],
  OH: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  OK: [
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  OR: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  PA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  RI: [],
  SC: [
    { label: "$1,000", value: "1000" },

    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  SD: [],
  TN: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  TX: [
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
    {
      label: "3%",
      value: "3%",
    },
    {
      label: "5%",
      value: "5%",
    },
    {
      label: "EXCL",
      value: "EXCL",
    },
  ],
  UT: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  VA: [
    { label: "$1,000", value: "1000" },

    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  VT: [],
  WA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  WI: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  WV: [],
  WY: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
};

export const stateSpecificAopOptions = {
  AL: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  AR: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  AZ: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  CA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  CO: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  CT: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  DC: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
  ],
  DE: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  FL: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  GA: [
    { label: "$1,000", value: "1000" },
    { label: "$2,000", value: "2000" },

    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  HI: [],
  IA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  ID: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  IL: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  IN: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  KS: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  KY: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  LA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  MA: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  MD: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  ME: [
    { label: "$1,000", value: "1000" },
    {
      label: "$2,000",
      value: "2000",
    },
    { label: "$2,500", value: "2500" },
  ],
  MI: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  MN: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  MO: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  MS: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  MT: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  NC: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "5%",
      value: "5%",
    },
  ],
  ND: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
  ],
  NE: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
  ],
  NH: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  NJ: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  NM: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  NV: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  NY: [
    { label: "$1,000", value: "1000" },

    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  OH: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  OK: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
  ],
  OR: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  PA: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  RI: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    {
      label: "2%",
      value: "2%",
    },
  ],
  SC: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  SD: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  TN: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  TX: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
    {
      label: "2%",
      value: "2%",
    },
    {
      label: "5%",
      value: "5%",
    },
  ],
  UT: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
  ],
  VA: [
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
    {
      label: "1%",
      value: "1%",
    },
  ],
  VT: [{ label: "$1,000", value: "1000" }],
  WA: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  WI: [
    {
      label: "$500",
      value: "500",
    },
    { label: "$1,000", value: "1000" },
    {
      label: "$1,500",
      value: "1500",
    },
    { label: "$2,000", value: "2000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
  WV: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
  ],
  WY: [
    { label: "$1,000", value: "1000" },
    { label: "$2,500", value: "2500" },
    { label: "$5,000", value: "5000" },
  ],
};

export const Owner = "owner";

export const Tenant = "tenant";

export const Vacant = "vacant";

export const roofDeckAttachmentOptions = [
  "Unknown or Undetermined",
  "Level A (6d @ 6/12)",
  "Level B (8d @ 6/12)",
  "Level C (8d @ 6/6)",
  "Level D: 8 D @ 6/6 Dimensional Lumber Deck",
  "No Attic Access",
  "Reinforced Concrete Roof Deck",
  "Dimensional Lumber Deck or Other",
  "Metal Deck on 5+",
  "Unit Type II/III Buildings",
  "Wood Deck or Other on 5+ Unit Type II/III Buildings",
];

export const roofWallConnectionOptions = [
  "Not Applicable",
  "Toe Nails",
  "Clips",
  "Single Wraps",
  "Double Wraps",
  "No Attic Access",
  "Structural Concrete",
  "Reinforced Concrete",
  "Other",
  "Unknown",
];

export const windSpeedOptions = [
  "Not Applicable",
  "100",
  "110",
  "120 or Greater",
];

export const openingProtectionOptions = ["Basic", "Hurricane", "None"];

export const secondaryWaterResistanceOptions = ["Yes", "No"];

// determines how to sort the error quotes in the responses page
export const ERROR_TYPES_SORTED_IN_RESPONSES = [
  "Quote Directly in Carrier",
  "Approval Needed",
  "Login Error",
  "Invalid Input",
  "Did Not Quote/See Details",
  "Carrier Error",
  "Coverage Ineligible",
  "Driver Ineligible",
  "Location Ineligible",
  "Property Ineligible",
  "Vehicle Ineligible",
  "Ineligible",
];

// determines which quote is "Best" between spouses
export const ERROR_TYPES_SORTED_BETWEEN_SPOUSES = [
  "Approval Needed",
  "Login Error",
  "Ineligible",
  "Coverage Ineligible",
  "Driver Ineligible",
  "Location Ineligible",
  "Property Ineligible",
  "Vehicle Ineligible",
  "Carrier Error",
  "Invalid Input",
  "Quote Directly in Carrier",
  "Did Not Quote/See Details",
];

export const profileIdsToRerunQuotes = {
  Agent: "00e80000001BvLQAA0",
  "Sales Manager": "00e800000018kO8AAI",
  "Franchise User": "00eC00000018ujxIAA",
  Developer: "00e3c000001H2Gd",
  "Systems Administrator": "00e80000001BZUd",
  "Systems Analyst": "00e3c000001H2GY",
  "Franchise Support": "00eC0000001GJVB",
};

export const CLIENT_ADDRESS_TYPE = {
  MAILING: "MAILING",
  CURRENT: "CURRENT",
};

export const DEFAULT_FLORIDA_PROPERTY_PROPS = {
  roof_deck_attachment__c: "Unknown or Undetermined",
  roof_wall_connection__c: "Not Applicable",
  wind_speed__c: "Not Applicable",
  opening_protection__c: "Basic",
  secondary_water_resistance__c: "Yes",
};

export const MAX_JEWLERY_VALUE = 999999;

export const RATE_LOB_ORDER = [bundle, home, auto, jewelry, flood, life];

export const LOB_ICON_MAP = {
  home: homeIcon,
  auto: autoIcon,
  flood: floodIcon,
  life: lifeIcon,
  lifePrimary: lifeIcon,
  lifeSpouse: lifeIcon,
  jewelry: jewelryIcon,
  dwelling: dwellingIcon,
  renters: rentersIcon,
  condo: condoIcon,
  security: smartHomeIcon,
};

export const PAGE_TAB_MAP = new Map([
  ["PolicyType", "Policy"],
  ["PropertyInfo", "Home Details"],
  ["AboutYou", "Info"],
  ["Drivers", "Drivers"],
  ["Vehicles", "Vehicles"],
  ["AutoDetails", "Details"],
  ["Responses", "Results"],
]);

export const TAB_ICON_MAP = {
  normal: {
    Policy: Policy,
    "Home Details": {
      Home: Home,
      Renters: Renters,
      Dwelling: Dwelling,
      Condo: Condo,
    },
    Info: Client,
    Drivers: Auto,
    Vehicles: Auto,
    Details: Auto,
    Results: Results,
  },
  current: {
    Policy: PolicyCurrent,
    "Home Details": {
      Home: HomeCurrent,
      Renters: RentersCurrent,
      Dwelling: DwellingCurrent,
      Condo: CondoCurrent,
    },
    Info: ClientCurrent,
    Drivers: AutoCurrent,
    Vehicles: AutoCurrent,
    Details: AutoCurrent,
    Results: ResultsCurrent,
  },
  disabled: {
    Policy: PolicyDisabled,
    "Home Details": {
      Home: HomeDisabled,
      Renters: RentersDisabled,
      Dwelling: DwellingDisabled,
      Condo: CondoDisabled,
    },
    Info: ClientDisabled,
    Drivers: AutoDisabled,
    Vehicles: AutoDisabled,
    Details: AutoDisabled,
    Results: ResultsDisabled,
  },
};

export const TABS = [...Object.keys(TAB_ICON_MAP.normal)];

export const DWELLING_TABS = ["Policy", "Home Details", "Info", "Results"];

export const HOME_LOB_NAMES = ["home", "renter", "condo"];

export const TABS_LOB = {
  home: ["Policy", "Home Details"],
  person: ["Info"],
  auto: ["Drivers", "Vehicles", "Details"],
  final: ["Results"],
};

export const USAStates = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

export const propertyDefaults = {
  hasPool: false,
  squareFoot: "",
  yearBuilt: "",
  numStories: 1,
  exteriorWalls: "Brick Veneer",
  roofShape: "",
  roofReplaced: "",
  roofMaterial: "Shingles",
  raterDefaults: {},
};

export const EDUCATION_LEVELS = [
  "Some High School",
  "High School/GED",
  "Some College",
  "Technical or Vocational School",
  "Certification",
  "Military Service",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Advanced or Doctorate Degree",
];

export const OCCUPATIONS = [
  "Account Executive - Business/Sales/Offi",
  "Accountant/Auditor - Banking/Finance/RE",
  "Accountant/Auditor - Government/Military",
  "Accountant/Auditor - Insurance",
  "Actor - Art/Design/Media",
  "Actuarial Clerk - Insurance",
  "Actuary - Engr/Archt/Sci/Math",
  "Admin Assist - Banking/Finance/RE",
  "Admin Assist - Business/Sales/Offi",
  "Admin Assist - Education/Library",
  "Admin Assist - Government/Military",
  "Admin Assist - Med/Soc Svcs/Relig",
  "Admin Assist - Mfg/Production",
  "Agent/Broker - Insurance",
  "Agr Inspect/Grader - Agriclt/Forestry/Fish",
  "Air Traffic Control - Trvl/Trnsprt/Warehs",
  "Airport Ops Crew - Trvl/Trnsprt/Warehs",
  "Analyst - Engr/Archt/Sci/Math",
  "Analyst - Government/Military",
  "Analyst - Info Tech",
  "Analyst - Insurance",
  "Analyst/Broker - Banking/Finance/RE",
  "Announcer/Broadcstr - Art/Design/Media",
  "Arborist - Agriclt/Forestry/Fish",
  "Architect - Engr/Archt/Sci/Math",
  "Artist/Animator - Art/Design/Media",
  "Assist-Med/Dent/Vet - Med/Soc Svcs/Relig",
  "Athlete - Sports/Recreation",
  "Attorney - Lgl/Law Enfcmt/Sec",
  "Bartender - Rest/Hotel Services",
  "Bldg Maint Engineer - Maint/Rpr/Hsekeep",
  "Boiler Oper/Maker - Construct/EnrgyTrds",
  "Bookkeeper - Banking/Finance/RE",
  "Branch Manager - Banking/Finance/RE",
  "Bricklayer/Mason - Construct/EnrgyTrds",
  "Buyer - Business/Sales/Offi",
  "CSR - Business/Sales/Offi",
  "CSR - Insurance",
  "CSR/Teller - Banking/Finance/RE",
  "Caregiver - Person.Care/Service",
  "Carpenter - Construct/EnrgyTrds",
  "Chef - Rest/Hotel Services",
  "Child/DayCare Wrker - Education/Library",
  "Choreography/Dancer - Art/Design/Media",
  "Claims Adjuster - Insurance",
  "Clergy - Med/Soc Svcs/Relig",
  "Clerk - Banking/Finance/RE",
  "Clerk - Education/Library",
  "Clerk - Government/Military",
  "Clerk - Info Tech",
  "Clerk - Med/Soc Svcs/Relig",
  "Clerk - Mfg/Production",
  "Clerk - Trvl/Trnsprt/Warehs",
  "Clerk-Office - Business/Sales/Offi",
  "Client Care Worker - Med/Soc Svcs/Relig",
  "Clinical Data Coord. - Engr/Archt/Sci/Math",
  "Coach - Sports/Recreation",
  "Composer/Director - Art/Design/Media",
  "Concierge - Rest/Hotel Services",
  "Concrete Worker - Construct/EnrgyTrds",
  "Constrct Proj Mgr - Construct/EnrgyTrds",
  "Consultant - Banking/Finance/RE",
  "Consultant - Business/Sales/Offi",
  "Contractor - Construct/EnrgyTrds",
  "Controller - Banking/Finance/RE",
  "Cook-Rest/Cafeteria - Rest/Hotel Services",
  "Cook/Wrkr-Fast Food - Rest/Hotel Services",
  "Corrections Officer - Lgl/Law Enfcmt/Sec",
  "Council member - Government/Military",
  "Counselor - Education/Library",
  "Court Clrk/Reporter - Lgl/Law Enfcmt/Sec",
  "Crane Loader/Oper - Trvl/Trnsprt/Warehs",
  "Crane Operator - Construct/EnrgyTrds",
  "Custodian/Janitor - Maint/Rpr/Hsekeep",
  "Dental Hygenist - Med/Soc Svcs/Relig",
  "Dentist - Med/Soc Svcs/Relig",
  "Deputy Sheriff - Lgl/Law Enfcmt/Sec",
  "Designer - Art/Design/Media",
  "Desk Clerk - Rest/Hotel Services",
  "Director, Program - Sports/Recreation",
  "Director/Administr - Banking/Finance/RE",
  "Director/Administr - Business/Sales/Offi",
  "Director/Administr - Government/Military",
  "Director/Administr - Info Tech",
  "Director/Administr - Insurance",
  "Disabled - Disabled",
  "Dishwasher - Rest/Hotel Services",
  "Dispatcher - Lgl/Law Enfcmt/Sec",
  "Dispatcher - Trvl/Trnsprt/Warehs",
  "Doctor - Med/Soc Svcs/Relig",
  "Drafter - Engr/Archt/Sci/Math",
  "Driver-Bus/Strcar - Trvl/Trnsprt/Warehs",
  "Driver-Taxi/Limo - Trvl/Trnsprt/Warehs",
  "Driver-Truck/Delvry - Trvl/Trnsprt/Warehs",
  "Editor - Art/Design/Media",
  "Electrician - Maint/Rpr/Hsekeep",
  "Electrician/Linesmn - Construct/EnrgyTrds",
  "ElevatorTech/Instl - Construct/EnrgyTrds",
  "Engineer - Engr/Archt/Sci/Math",
  "Engineer-Hardware - Info Tech",
  "Engineer-Software - Info Tech",
  "Engineer-Systems - Info Tech",
  "Enlst Mil Prsnl E1-4 - Government/Military",
  "Equip. Operator - Construct/EnrgyTrds",
  "Event Mgr/Promoter - Sports/Recreation",
  "Examiner - Lgl/Law Enfcmt/Sec",
  "Executive - Banking/Finance/RE",
  "Executive - Business/Sales/Offi",
  "Executive - Info Tech",
  "Factory Worker - Mfg/Production",
  "Farm/Ranch Owner - Agriclt/Forestry/Fish",
  "Farm/Ranch Worker - Agriclt/Forestry/Fish",
  "Fed Agt/Marshall - Lgl/Law Enfcmt/Sec",
  "Field Service Tech. - Maint/Rpr/Hsekeep",
  "Financial Advisor - Banking/Finance/RE",
  "Fire Chief - Lgl/Law Enfcmt/Sec",
  "Fire Fighter/Supv. - Lgl/Law Enfcmt/Sec",
  "Flight Attendant - Trvl/Trnsprt/Warehs",
  "FloorLayer/Finisher - Construct/EnrgyTrds",
  "Food Prod/Packing - Rest/Hotel Services",
  "Foreman/Supervisor - Construct/EnrgyTrds",
  "Foreman/Supervisor - Mfg/Production",
  "Forklift Operator - Trvl/Trnsprt/Warehs",
  "Grad. Teaching/Asst - Education/Library",
  "Graduate Student - Student",
  "H.R. Representative - Business/Sales/Offi",
  "Hair Stylist/Barber - Person.Care/Service",
  "Handyman - Construct/EnrgyTrds",
  "Handyman - Maint/Rpr/Hsekeep",
  "Heat/Air Technician - Construct/EnrgyTrds",
  "Heat/AirCond Repair - Maint/Rpr/Hsekeep",
  "High school - Student",
  "Highway Patrol Ofcr - Lgl/Law Enfcmt/Sec",
  "Homemaker/Houseprsn - Homemaker/Houseprsn",
  "Hospice Volunteer - Med/Soc Svcs/Relig",
  "Host/Maitre d - Rest/Hotel Services",
  "Housekeeper - Person.Care/Service",
  "Housekeeper/Maid - Maint/Rpr/Hsekeep",
  "Housekeeper/Maid - Rest/Hotel Services",
  "Inspector - Construct/EnrgyTrds",
  "Inspector - Mfg/Production",
  "Instructor-Vocation - Education/Library",
  "Investment Banker - Banking/Finance/RE",
  "Investor - Banking/Finance/RE",
  "Journalist/Reporter - Art/Design/Media",
  "Judge/Hearing Ofcr - Lgl/Law Enfcmt/Sec",
  "Laborer - Trvl/Trnsprt/Warehs",
  "Laborer/Worker - Agriclt/Forestry/Fish",
  "Laborer/Worker - Construct/EnrgyTrds",
  "Landscaper - Agriclt/Forestry/Fish",
  "Legal Asst./Sec - Lgl/Law Enfcmt/Sec",
  "Librarian/Curator - Education/Library",
  "Lndscpe/Grnds Maint - Maint/Rpr/Hsekeep",
  "Loan/EscrowProcess - Banking/Finance/RE",
  "Logger - Agriclt/Forestry/Fish",
  "Machine Operator - Mfg/Production",
  "Maint Mechanic - Maint/Rpr/Hsekeep",
  "Manager - Rest/Hotel Services",
  "Manager-Project - Engr/Archt/Sci/Math",
  "Manager-R&D - Engr/Archt/Sci/Math",
  "Manager-Systems - Info Tech",
  "Manicurist - Person.Care/Service",
  "Marketing Researchr - Business/Sales/Offi",
  "Masseuse - Person.Care/Service",
  "Mate/Sailor - Trvl/Trnsprt/Warehs",
  "Mayor/City Manager - Government/Military",
  "Mechanic - Maint/Rpr/Hsekeep",
  "Messenger/Courier - Business/Sales/Offi",
  "Metalworker - Construct/EnrgyTrds",
  "Mgr Warehse/Dist - Trvl/Trnsprt/Warehs",
  "Mgr-Credit/Loan - Banking/Finance/RE",
  "Mgr-Dept/Store - Business/Sales/Offi",
  "Mgr-District - Business/Sales/Offi",
  "Mgr-Finance - Business/Sales/Offi",
  "Mgr-General Opers - Business/Sales/Offi",
  "Mgr-H.R./PublicRel - Business/Sales/Offi",
  "Mgr-Mkt/Sales - Business/Sales/Offi",
  "Mgr-Portfolio/Prod. - Banking/Finance/RE",
  "Mgr-Property - Banking/Finance/RE",
  "Mgr/Supervisr-Offic - Business/Sales/Offi",
  "Miner - Construct/EnrgyTrds",
  "Mortician - Med/Soc Svcs/Relig",
  "Musician/Singer - Art/Design/Media",
  "NCO (E5-9) - Government/Military",
  "Nanny - Person.Care/Service",
  "Network Admin - Info Tech",
  "Nurse Practitioner - Med/Soc Svcs/Relig",
  "Nurse-C.N.A. - Med/Soc Svcs/Relig",
  "Nurse-LPN - Med/Soc Svcs/Relig",
  "Nurse-RN - Med/Soc Svcs/Relig",
  "Officer-Commissiond - Government/Military",
  "Officer-Warrant - Government/Military",
  "Oil/GasDril/RigOpr - Construct/EnrgyTrds",
  "Optometrist - Med/Soc Svcs/Relig",
  "Other - Agriclt/Forestry/Fish",
  "Other - Art/Design/Media",
  "Other - Banking/Finance/RE",
  "Other - Business/Sales/Offi",
  "Other - Construct/EnrgyTrds",
  "Other - Education/Library",
  "Other - Engr/Archt/Sci/Math",
  "Other - Government/Military",
  "Other - Info Tech",
  "Other - Insurance",
  "Other - Lgl/Law Enfcmt/Sec",
  "Other - Maint/Rpr/Hsekeep",
  "Other - Med/Soc Svcs/Relig",
  "Other - Mfg/Production",
  "Other - Other",
  "Other - Person.Care/Service",
  "Other - Rest/Hotel Services",
  "Other - Sports/Recreation",
  "Other - Student",
  "Other - Trvl/Trnsprt/Warehs",
  "Packer - Mfg/Production",
  "Painter - Construct/EnrgyTrds",
  "Paralegal/Law Clerk - Lgl/Law Enfcmt/Sec",
  "Paramedic/EM Tech - Med/Soc Svcs/Relig",
  "Park Ranger - Government/Military",
  "Pet Services - Person.Care/Service",
  "Pharmacist - Med/Soc Svcs/Relig",
  "Pilot/Capt/Eng - Trvl/Trnsprt/Warehs",
  "Planner - Government/Military",
  "Plant Manager - Mfg/Production",
  "Plstr/Drywall/Stuc - Construct/EnrgyTrds",
  "Plumber - Construct/EnrgyTrds",
  "Police Chief - Lgl/Law Enfcmt/Sec",
  "Police Det/Investgr - Lgl/Law Enfcmt/Sec",
  "Police Offcr/Supvr - Lgl/Law Enfcmt/Sec",
  "Postmaster - Government/Military",
  "Printer/Bookbinder - Mfg/Production",
  "Producer - Art/Design/Media",
  "Product Manager - Insurance",
  "Production Crew - Art/Design/Media",
  "Professor, College - Education/Library",
  "Programmer - Info Tech",
  "Project Coordinator - Info Tech",
  "Quality Control - Mfg/Production",
  "Railroad Worker - Trvl/Trnsprt/Warehs",
  "Ranger - Agriclt/Forestry/Fish",
  "Realtor - Banking/Finance/RE",
  "Receptionist/Sec - Art/Design/Media",
  "Receptionist/Sec - Business/Sales/Offi",
  "Receptionist/Sec - Education/Library",
  "Receptionist/Sec - Med/Soc Svcs/Relig",
  "Receptionist/Sec - Mfg/Production",
  "Receptionist/Sec - Person.Care/Service",
  "Refining Operator - Mfg/Production",
  "Regulator - Government/Military",
  "Researcher - Engr/Archt/Sci/Math",
  "Retired - Retired",
  "Roofer - Construct/EnrgyTrds",
  "Sales Rep - Insurance",
  "Sales-Counter/Rentl - Business/Sales/Offi",
  "Sales-Home Based - Business/Sales/Offi",
  "Sales-Mfg Rep - Business/Sales/Offi",
  "Sales-Retail/Whlsle - Business/Sales/Offi",
  "Sales-Route/Vendor - Business/Sales/Offi",
  "SalesAgt/Represent. - Banking/Finance/RE",
  "Scientist - Engr/Archt/Sci/Math",
  "Security Guard - Lgl/Law Enfcmt/Sec",
  "Shipping/RecClk - Trvl/Trnsprt/Warehs",
  "Social Worker - Med/Soc Svcs/Relig",
  "Superintendent - Education/Library",
  "Supervisor - Agriclt/Forestry/Fish",
  "Support Services - Med/Soc Svcs/Relig",
  "Support Technician - Info Tech",
  "Surveyor/Mapmaker - Engr/Archt/Sci/Math",
  "Systems Security - Info Tech",
  "Tailor/Custom Sewer - Mfg/Production",
  "Teacher, College - Education/Library",
  "Teacher, K-12 - Education/Library",
  "Teaching Asst/Aide - Education/Library",
  "Technical Writer - Info Tech",
  "Technician - Engr/Archt/Sci/Math",
  "Technician - Med/Soc Svcs/Relig",
  "Textile Worker - Mfg/Production",
  "Therapist - Med/Soc Svcs/Relig",
  "Ticket Agent - Trvl/Trnsprt/Warehs",
  "Trader,Finan Instru - Banking/Finance/RE",
  "Trainer/Instructor - Sports/Recreation",
  "Transportation Spec - Trvl/Trnsprt/Warehs",
  "Tutor - Education/Library",
  "US Postal Worker - Government/Military",
  "Undergraduate - Student",
  "Underwriter - Banking/Finance/RE",
  "Underwriter - Insurance",
  "Unemployed - Unemployed",
  "Veterinarian - Med/Soc Svcs/Relig",
  "Waiter/Waitress - Rest/Hotel Services",
  "Web Developer - Info Tech",
];

export const PACKAGE_DISCOUNT_TYPE = /** @type {const} */ ({
  SAME_CARRIER_ONLY: "SAME_CARRIER_ONLY",
  ANY_GH_CARRIER: "ANY_GH_CARRIER",
  NONE: "NONE",
});

const { __meta, ...stateConfigs } = stateConfigsJson;

/**
 * @typedef {{
 *   isMonolineAcceptable: boolean;
 *   isMonolineIneligibleInCertainCounties: boolean;
 *   monolineIneligibleCounties: string[] | null;
 *   packageDiscountType:
 *     | typeof PACKAGE_DISCOUNT_TYPE[keyof typeof PACKAGE_DISCOUNT_TYPE]
 *     | string;
 *   carrierName: string;
 * }} CarrierConfig
 *
 * @typedef {Partial<Record<number, CarrierConfig>>} CarrierBundlingConfigs
 * @type {{
 *   [state: string]: {
 *     home: CarrierBundlingConfigs;
 *     auto: CarrierBundlingConfigs;
 *   };
 * }}
 */
export const carrierBundlingConfigs = stateConfigs;

export const lineOfBusiness = {
  HOME: "Home",
  AUTO: "Auto",
  HOMEAUTO: "HomeAuto",
};

export const qtiHoaicId = "113";
export const qtiBranchId = "994";
export const qtiNationwideAutoId = "348";
export const qtiNationwideHomeId = "447";
export const qtiTravelersHomeId = "13";
export const qtiTravelersAutoId = "13";
export const qtiSafecoId = "35";
export const qtiProgressiveAutoId = "25";
export const qtiSageSureOccidentalHomeId = "502";
export const qtiSageSureElevateHomeId = "503";
export const qtiSageSureSureHomeId = "504";
export const qtiSageSureSafeportHomeId = "505";
export const qtiSageSureVaveHomeId = "506";
export const qtiSageSureHomeId = "507";
export const qtiSageSureMarketsIndependentMutualHomeId = "508";
export const qtiSageSureHarcoNationalHomeId = "509";
export const qtiSageSureWilshireHomeId = "510";
export const qtiSageSureSafechoiceHomeId = "512";
export const qtiSageSureIATHomeId = "513";
export const qtiNeptuneId = "0";
export const qtiRootId = "992";
export const qtiClearcoverId = "532";

export const PAST_SESSIONS_LIMIT = 5;

export const v1CarrierIds = [qtiBranchId];

export const v3FloodCarrierIds = [qtiNeptuneId];
export const v3AutoCarrierIds = [qtiSafecoId, qtiProgressiveAutoId, qtiRootId];
export const v3HomeCarrierIds = [
  qtiNationwideHomeId,
  qtiTravelersHomeId,
  qtiSageSureElevateHomeId,
  qtiSageSureHomeId,
  qtiSafecoId,
];

export const v3CarrierIds = Array.from(
  new Set([...v3AutoCarrierIds, ...v3HomeCarrierIds, ...v3FloodCarrierIds])
);
export const v2CarrierIds = [
  qtiClearcoverId,
  qtiNationwideAutoId,
  qtiTravelersAutoId,
];

export const qtiCarrierIds = [
  ...v1CarrierIds,
  ...v3CarrierIds,
  "348",
  qtiClearcoverId,
  "13",
  "25",
];

export const qtiAutoCarriers = {
  994: "branchAuto",
  348: "nationwideAuto",
  532: "clearcoverAuto",
  13: "travelersAuto",
  35: "safecoAuto",
  25: "progressiveAuto",
  992: "rootAuto",
};

export const qtiHomeCarriers = {
  994: "branchHome",
  447: "nationwideHome",
  13: "travelersHome",
  502: "sageSureOccidentalHome",
  503: "sageSureElevateHome",
  504: "sageSureSureHome",
  505: "sageSureSafeportHome",
  506: "sageSureVaveHome",
  507: "sageSureHome",
  508: "sageSureMarketsIndependentMutualHome",
  509: "sageSureHarcoNationalHome",
  510: "sageSureWilshireHome",
  512: "sageSureSafechoiceHome",
  513: "sageSureIATHome",
  35: "safecoHome",
};

export const featureFlagsConst = {
  useV31: "use-v-3-1-new",
};

export const qtiFloodCarriers = {
  0: "neptuneFlood",
};

export const STATES_WITH_NON_BINARY_GENDER_OPTIONS = [
  { value: "Non-Binary", states: ["VA", "NY"] },
  { value: "Not-Specified", states: ["OR"] },
];

export const FORM_ASSEMBLY_FEEDBACK = {
  FormBeforeResponses: "FormBeforeResponses",
  FormAfterResponses: "FormAfterResponses",
};

export const SESSION_RESPONSE_URL = {
  development: "http://localhost:3000/address/?rs=",
  "dev-test": "https://dev-agent-rater.herokuapp.com/address/?rs=",
  "core-platform": "https://dev-agent-rater.herokuapp.com/address/?rs=",
  test: "https://test-agent-rater.herokuapp.com/address/?rs=",
  production: "https://agent-quotes.goosehead.com/address/?rs=",
  feature: "https://dev-agent-rater.herokuapp.com/address/?rs=",
  uat: "https://dev-agent-rater.herokuapp.com/address/?rs=",
};

export const MIN_VEHICLE_YEAR = 1981;

export const MAX_DRIVERS_ALLOWED = 6;
export const MAX_VEHICLES_ALLOWED = 6;

export const VALID_VIN_LENGTH = 17;

export const DEFAULT_PRIOR_CARRIER = "Other - Standard";
// the spelling of Policy_Type__c from SF and selectedPolicy in Redux are different
export const POLICY_MAP = {
  Home: "Home",
  Condo: "Condo",
  Dwelling: "Dwelling",
  "Renter's": "Renter",
};

export const ERROR_MESSAGE_COLOR_MAP = {
  "Login Error": "red",
  "Invalid Input": "red",
  "Ineligible/Approval Needed": "dark_navy",
  "Carrier Error": "dark_navy",
  default: "dark_navy",
};

export const VALIDATION_DISPLAY_RULES = {
  994: {
    /** BRANCH * */
    auto: {
      notAvailableInStates: [
        "AK",
        "CA",
        "CT",
        "DE",
        "FL",
        "HI",
        "LA",
        "MA",
        "MI",
        "MN",
        "NC",
        "NJ",
        "NV",
        "NY",
        "RI",
        "SC",
        "WA",
      ],
    },
    home: {
      notAvailableInStates: [
        "AK",
        "CA",
        "CT",
        "DE",
        "FL",
        "HI",
        "LA",
        "MA",
        "MN",
        "MS",
        "NC",
        "NJ",
        "NV",
        "NY",
        "RI",
        "SC",
        "VT",
        "WA",
      ],
    },
  },
};

export const VIVINT_SMART_HOME_QUOTE = {
  premium_total__c: 960,
  premium_total_ppm__c: 80,
  company_client_id__c: 9999,
  carrier__c: "Vivint",
  error_type__c: "Success",
  line_of_business__c: "Security",
};

export const VIVINT_SMART_HOME_DEVICES = [
  "1 Vivint Smart Hub",
  "1 Smart Lock",
  "1 Smart Thermostat",
  "3 Vivint Door/Window Sensors",
  "1 Vivint Outdoor Camera Pro",
  "1 Vivint Water Sensor",
  "1 Vivint Doorbell Camera Pro (Free Item GHSD)",
];

export const partnerCampaign =
  process.env.REACT_APP_PARTNER_CAMPAIGN || "aD2560000000QU4CAM";

export const MAX_CHARS_AGENT_NOTES = 37000;
export const EVENT_STREAM_TIMEOUT =
  Number(process.env.EVENT_STREAM_TIMEOUT) || 90 * 1000;

export const statesWithFoundationTypeOption = [
  "CO",
  "IN",
  "MI",
  "MO",
  "NJ",
  "NY",
  "OH",
  "PA",
  "WA",
];

export const QUOTE_TYPES = {
  homeAuto: "home-auto-package",
  home: "home-only",
  auto: "auto-only",
};

export const PACKAGE_REQUIRED_NOT_AVAILABLE = `No "Package Required" responses returned for this quote.`;
export const PACKAGE_REQUIRED_BUNDLE_PAGE = `View these quote responses on the "Bundles" tab.`;
export const PACKAGE_REQUIRED_BUNDLE_PAGE_NEW = `View these quote responses in "Package View"`;
export const MONOLINE_INELIGIBLE = "Monoline Ineligible";
export const PACKAGE_REQUIRED = "Package Required";
export const PACKAGE_REQUIRED_FOR_DWELLING = "Package Required for Dwelling";

export const MAX_DWELLING_COVERAGE = 3000000;
export const MIN_DWELLING_COVERAGE = 5000;

export const NAME_REGEX =
  /^[a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ ,.'’-]+$/;

export const BRANCH_TURN_OFF_STATES = [
  "AZ",
  "OR",
  "MT",
  "NE",
  "NM",
  "UT",
  "WI",
  "MO",
  "IL",
  "IN",
  "IA",
  "KY",
  "OH",
  "AR",
  "TX",
  "GA",
  "KS",
  "ID",
  "PA",
  "VA",
  "TN",
  "AL",
  "MD",
  "OK",
  "MS",
  "DC",
  "MA",
  "MI",
  "VT",
  "WY",
  "ME",
  "ND",
  "SD",
  "NH",
];
