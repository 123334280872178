import { useSelector } from "react-redux";
import flow from "lodash/flow";

/** @returns {Session} */
export const useSession = () => useSelector((store) => store.session);

/** @param {Session} session */
export function getSessionQuoteSource(session) {
  return session?.quoteSource;
}

export const useGetSessionQuoteSource = withSession(getSessionQuoteSource);

/**
 * @template T
 * @param {(session: Session) => T} fn
 * @returns {() => T}
 */
function withSession(fn) {
  return flow(useSession, fn);
}

/** @typedef {{ quoteSource?: string }} Session */
