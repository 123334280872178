// @ts-check

// types
// @ts-ignore
import * as AppTypes from "../../app.d.ts"; // eslint-disable-line no-unused-vars
import * as ENV from "./env";
import * as quoteService from "../../services/quote";
import * as salesForce from "../../utils/sales-force";

import { getButtonProperties, getIsBind, getIsBranch } from "./utils";
import {
  getPropertyData,
  updateCarrierPropertyData,
} from "../../services/property";
import {
  security,
  v1CarrierIds,
  v3AutoCarrierIds,
  v3FloodCarrierIds,
  v3HomeCarrierIds,
} from "../../constants";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../styled/buttons/CustomButton";
import { ResubmitModal } from "./ResubmitModal";
import { handleUpdateSession } from "../../services/utils";
import { isValidDate } from "../../utils/strings";
import { prependHttp } from "../../utils";
import { updateQuoteResponse } from "../../redux/actions/quote.js";
import { useFeatureFlag } from "../../services/featureFlag.js";
import { useIsCarrierQtiEnabled } from "./useIsCarrierQtiEnabled";
// types
// @ts-ignore
import { useIsV3_1QtiCarrierOverride } from "./useIsV3_1QtiCarrierOverride.js";
import { useResubmitQuoteResponse } from "./useResubmitQuoteResponse";
import { useShouldResubmit } from "./useShouldResubmit";

/**
 * @param {{
 *   isDisabled: boolean;
 *   lob: string;
 *   quoteResponse: AppTypes.QuoteResponse;
 *   quoteToBind: string;
 *   setShowSmartHomePackageSelectionModal: function;
 *   qti_quote_id: string | null;
 * }} props
 */
export const ActionButton = ({
  isDisabled,
  lob,
  quoteResponse,
  quoteToBind,
  setShowSmartHomePackageSelectionModal,
  qti_quote_id,
}) => {
  // @ts-ignore
  const contact = useSelector((store) => store.contact);
  // @ts-ignore
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const [isBridged, setIsBridged] = useState(
    quoteResponse?.bridged__c ?? false
  );
  const [isResubmitModalVisible, setIsResubmitModalVisible] = useState(false);
  const [optimisticUpdate, setOptimisticUpdate] = useState(
    qti_quote_id === "optimisticUpdate"
  );
  const qtiQuoteExists = !!qti_quote_id || optimisticUpdate;
  const closeModal = useCallback(
    () => setIsResubmitModalVisible(false),
    [setIsResubmitModalVisible]
  );

  const featureFlags = useFeatureFlag();

  const resubmitQuoteResponse = useResubmitQuoteResponse({
    closeModal,
    lob,
    quoteResponse,
  });

  const companyClientId = quoteResponse?.company_client_id__c;

  const isCarrierQtiEnabled = useIsCarrierQtiEnabled({
    quoteToBind,
    companyClientId,
    lob,
  });
  const isV3_1QtiCarrierOverride = useIsV3_1QtiCarrierOverride({
    companyClientId,
    lob,
  });

  const isBranch = getIsBranch(quoteResponse);

  const isBind = getIsBind({
    companyClientId,
    qti_id: session.qti_id,
    quoteResponse,
    isCarrierQtiEnabled,
  });
  const isLoginError = quoteResponse?.error_type__c === "Login Error";
  const buttonProperties = getButtonProperties({
    isBind,
    isBridged,
    isLoginError,
    lob,
    prospectId: contact.prospectId,
    qtiQuoteExists,
  });
  const shouldResubmit = useShouldResubmit({ quoteResponse });
  if (shouldResubmit) {
    buttonProperties.title = "Resubmit";
  }

  const isProspectCreated = !!contact.prospectId && lob === security;
  const newQuote = quoteResponse ?? {};

  const handleClick = async () => {
    try {
      if (buttonProperties.title === "Refer") {
        setShowSmartHomePackageSelectionModal(true);
        return;
      }

      if (shouldResubmit) {
        setIsResubmitModalVisible(true);

        // don't continue
        return;
      }

      let redirectURL = "";
      if (isProspectCreated) {
        // Vivint smart home
        redirectURL =
          featureFlags.getFlag("vivint-redirect-url") ||
          `${salesForce.url}${contact.prospectId}`;
      } else if (isLoginError) {
        redirectURL = salesForce.url + (newQuote.carrier_login__c ?? "");
      } else {
        setIsBridged(true);
        quoteService.updateQuoteResponse({
          heroku_id: quoteResponse.heroku_id,
          bridged__c: true,
        });

        if (isBind) {
          if (isBranch && contact.propertyId) {
            const qti_carrier_purchase_date = (
              await getPropertyData(contact.propertyId)
            ).data.ownership?.qti_carrier_purchase_date;

            // Entering QTI flow, we change the purchase date
            // to the date provided by the QTI carrier
            if (
              qti_carrier_purchase_date &&
              isValidDate(qti_carrier_purchase_date)
            ) {
              await updateCarrierPropertyData(contact.propertyId, {
                purchase_date__c: qti_carrier_purchase_date,
              });
            }
          }

          let homeCarrierCheckedOut;
          let autoCarrierCheckedOut;
          let floodCarrierCheckedOut;
          const v3CarrierIds =
            quoteResponse?.line_of_business__c?.toLowerCase() === "home"
              ? v3HomeCarrierIds
              : quoteResponse?.line_of_business__c?.toLowerCase() === "flood"
              ? v3FloodCarrierIds
              : v3AutoCarrierIds;
          // @ts-ignore - didn't like that companyClientId could be undefined
          const isV1Carrier = v1CarrierIds.includes(companyClientId);
          const isV3Carrier =
            // @ts-ignore - didn't like that companyClientId could be undefined
            v3CarrierIds.includes(companyClientId) || isV3_1QtiCarrierOverride;

          const versionString = isV1Carrier
            ? "/client/QTI/V1"
            : isV3Carrier
            ? "/QTI/V3"
            : "/QTI/V2";
          const bundleCheckedOutVersionString = isBranch
            ? `${versionString}/HomeAuto/HomeReview`
            : `${versionString}`;
          const homeCheckedOutVersionString = isBranch
            ? `${versionString}/Home/HomeReview`
            : `${versionString}`;
          const autoCheckedOutBundleString = isBranch
            ? `${versionString}/Auto/AutoReviewDrivers`
            : `${versionString}`;
          const floodCheckedOutBundleString = versionString;

          if (quoteToBind === "bundle") {
            homeCarrierCheckedOut = quoteResponse?.company_client_id__c;
            autoCarrierCheckedOut = quoteResponse?.company_client_id__c;
            floodCarrierCheckedOut = null;
            redirectURL = `${ENV.BIND_URL}${bundleCheckedOutVersionString}?bindingFromAR=true&lobTobind=bundle&rs=${session.uuid}`;
          } else if (lob === "home") {
            homeCarrierCheckedOut = quoteResponse?.company_client_id__c;
            autoCarrierCheckedOut = null;
            floodCarrierCheckedOut = null;
            redirectURL = `${ENV.BIND_URL}${homeCheckedOutVersionString}?bindingFromAR=true&lobTobind=home&rs=${session.uuid}`;
          } else if (lob === "auto") {
            homeCarrierCheckedOut = null;
            autoCarrierCheckedOut = quoteResponse?.company_client_id__c;
            floodCarrierCheckedOut = null;
            redirectURL = `${ENV.BIND_URL}${autoCheckedOutBundleString}?bindingFromAR=true&lobTobind=auto&rs=${session.uuid}`;
          } else if (lob === "flood") {
            homeCarrierCheckedOut = null;
            autoCarrierCheckedOut = null;
            floodCarrierCheckedOut = quoteResponse?.company_client_id__c;
            redirectURL = `${ENV.BIND_URL}${floodCheckedOutBundleString}?bindingFromAR=true&lobTobind=auto&rs=${session.uuid}`;
          }

          //TODO: Will need to add logic for QTI bundling using v2/v3. May need to be in RecommendedQuotes component.
          const final_quotes_data = JSON.stringify([quoteResponse]);
          await handleUpdateSession({
            contact_data: JSON.stringify({
              ...contact,
              homeCarrierCheckedOut,
              autoCarrierCheckedOut,
              floodCarrierCheckedOut,
              carrierCheckedOut:
                homeCarrierCheckedOut ||
                autoCarrierCheckedOut ||
                floodCarrierCheckedOut,
              qti_id_v2: quoteResponse?.heroku_id,
            }),
            final_quotes_data,
          });

          const shouldOptimisticallyUpdateButtonTitle =
            !qtiQuoteExists && !isV1Carrier;
          if (shouldOptimisticallyUpdateButtonTitle) {
            setOptimisticUpdate(true);
            // Update the currentQtiSession state
            // so buttons will re-render w/ "Resume". On reload
            // the button will be driven by database state.
            dispatch(
              updateQuoteResponse({
                lob,
                quoteResponseId: quoteResponse.heroku_id,
                updates: { qti_quote_id: "optimisticUpdate" },
              })
            );
          }
        } else
          redirectURL =
            newQuote.company_url_long__c ??
            newQuote.company_url__c ??
            (newQuote?.carrier__c === "Branch"
              ? `https://www.ourbranch.com/staff/offer/${session?.qti_id}`
              : "");
      }
      redirectURL = prependHttp(redirectURL);
      const bridgeWindow = window.open(redirectURL, "_blank");
      bridgeWindow?.focus();
    } catch (error) {
      console.log("Error handling bridge", error);
    }
  };

  if (qti_quote_id === "optimisticUpdate" && !optimisticUpdate) {
    setOptimisticUpdate(true);
    return null;
  }

  return (
    <>
      {/* @ts-ignore */}
      <CustomButton
        /*for QA automated testing w/ Test Sigma, we need unique identifiers on the 'Next' button for carriers
      in order to help automated the QA testing of different states per carrier
      each carrier can have multiple quotes depending on monoline/packaged, lob, etc
      */
        className={`w-auto btn-${buttonProperties.title}-${newQuote.carrier__c}-${newQuote.line_of_business__c}-${newQuote.package_rate__c}`}
        disabled={isDisabled || isResubmitModalVisible}
        handleClick={handleClick}
        whiteIcon
        title={buttonProperties.title}
        color={buttonProperties.bgColor}
        textColor={buttonProperties.textColor}
        small
      />
      {/* @ts-ignore */}
      <ResubmitModal
        isVisible={isResubmitModalVisible}
        onCancel={closeModal}
        onAccept={resubmitQuoteResponse}
      />
    </>
  );
};
