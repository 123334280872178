import * as TYPES from "./types.d.ts"; // eslint-disable-line no-unused-vars

import {
  BEST,
  BRANCH_TURN_OFF_STATES,
  EVENT_STREAM_TIMEOUT,
  PRIMARY,
  SPOUSE,
  UMBRELLA_LINE_OF_BUSINESS,
  auto,
  baseURLS,
  home,
} from "../../constants";
import { Suspense, useEffect, useRef, useState } from "react";
import {
  _determineType,
  _insertNewQuoteResponses,
  _removeQuoteResponse,
  setStateHelper,
} from "./utils";
import {
  calculateBundlePrice,
  clearAllEventStreams,
  isDwelling,
  sortErrorTypeInQuotes,
} from "../../utils";
import {
  combinableCarriers,
  getExpectedResponseCountByLob,
  getLineOfBusiness,
} from "../../utils/strings";
import {
  getBestBundles,
  getBestQuotes,
  getUnbundledQuotes,
  handleBranchQuote,
} from "../../utils/quotes";
import {
  getDummyQuoteResponses,
  getResponsesByQuoteRequestId,
  sendQuoteIdToMs,
  updateQuoteRequestInSF,
  updateQuoteResponseRecommendation,
} from "../../services/quote";
import {
  hasBundles,
  isEmptyBundle,
  isMultiLine,
  useIsNewResponsePageDesign,
} from "../../utilsMisc";
import {
  syncAllData,
  updateSfContactByAccountId,
} from "../../services/account";
import { useDispatch, useSelector } from "react-redux";

import ContactNameAddress from "../../components/ContactNameAddress";
import { Container } from "react-bootstrap";
import LoadingDots from "../../components/LoadingDots";
import LogRocket from "logrocket";
import { QuoteResponsesContextProvider } from "../../contexts/QuoteResponsesContext";
import Rates from "../../components/Rates";
import SmartHomeConsentModal from "../../components/modals/SmartHomeConsentModal";
import SmartHomePackageSelectionModal from "../../components/modals/SmartHomePackageSelectionModal";
import VivintHomeConsentModal from "../../components/modals/VivintHomeConsentModal";
import VivintHomePackageSelectionModal from "../../components/modals/VivintHomePackageSelectionModal";
import { addContact } from "../../redux/actions/contact";
import { addProperty } from "../../redux/actions/property";
import { addQuotes } from "../../redux/actions/quote";
import env from "../../env";
import { getFloodZone } from "../../utils/misc";
import { getQueryParamString } from "../../utils/queryParams";
import { handleBranchQuoteResponse } from "../../utils/qti";
import { handleUpdateSession } from "../../services/utils";
import store from "../../redux/store";
import styled from "styled-components";
import { updateIsFetching } from "../../redux/actions/fetching";
import { updateIsFetchingQuotes } from "../../redux/actions/fetchingQuotes";
import { useFeatureFlag } from "../../services/featureFlag";
import { validateBundle } from "../../utils/validateBundle";

const QuotesContainer = styled.div`
  line-height: 1.4;
  margin: 0 auto;
  max-width: 1142px;
`;

const Quotes = () => {
  const contact = useSelector((store) => store.contact);
  const property = useSelector((store) => store.property);
  const coverages = useSelector((store) => store.coverages);
  const session = useSelector((store) => store.session);
  const isFetchingInitalQuotes = useSelector((store) => store.isFetching);
  const isFetchingQuotes = useSelector((store) => store.isFetchingQuotes);
  const isNewResponseDesign = useIsNewResponsePageDesign();

  const shouldUseMock =
    process.env.REACT_APP_USE_MOCK_QUOTE_RESPONSES || contact.dummyQuotes;

  // TODO: remove this after MuleSoft stops sending Monoline Ineligible quotes
  const featureFlags = useFeatureFlag();
  const shouldHideMonolineIneligibleCarriers = featureFlags.getFlag(
    "hide-monoline-ineligible-carriers"
  );

  const state = contact.administrative_area_level_1;

  const defaultData = (value) => {
    return {
      primary: {
        home: value,
        auto: value,
      },
      spouse: {
        home: value,
        auto: value,
      },
      best: {
        home: value,
        auto: value,
      },
    };
  };

  const [quotesToDisplay, setQuotesToDisplay] = useState(PRIMARY);
  const [coveragesData, setCoveragesData] = useState({ auto: {}, home: {} });
  const [quoteResponsesData, setQuoteResponsesData] = useState(defaultData([]));
  const quoteResponsesDataRef = useRef(quoteResponsesData);
  quoteResponsesDataRef.current = quoteResponsesData;

  // displayed in the Home(Condo, Rental, Dwelling) & Auto Rates tab
  const [homeAutoQuoteResponses, setHomeAutoQuoteResponses] = useState(
    defaultData([])
  );
  const quotesUpdatedPrimaryRef = useRef(contact.quotesUpdatedPrimary);
  quotesUpdatedPrimaryRef.current = contact.quotesUpdatedPrimary;

  const [bundledQuoteResponses, setBundledQuoteResponses] = useState({
    primary: [],
    spouse: [],
    best: [],
  });
  const [floodQuoteResponses, setFloodQuoteResponses] = useState({
    primary: [],
    spouse: [],
  });
  const [jewelryQuoteResponses, setJewelryQuoteResponses] = useState({
    primary: [],
    spouse: [],
  });
  const [umbrellaQuoteResponses, setUmbrellaQuoteResponses] = useState({
    primary: [],
    spouse: [],
  });
  const [lifeQuoteResponses, setLifeQuoteResponses] = useState({
    primary: [],
    spouse: [],
  });

  const [
    showSmartHomePackageSelectionModal,
    setShowSmartHomePackageSelectionModal,
  ] = useState(false);

  const [showSmartHomeConsentModal, setShowSmartHomeConsentModal] =
    useState(false);

  const [
    showNewSmartHomePackageSelectionModal,
    setShowNewSmartHomePackageSelectionModal,
  ] = useState(false);

  const [showNewSmartHomeConsentModal, setShowNewSmartHomeConsentModal] =
    useState(false);

  const [events, setEvents] = useState([]);

  const homeRegex = /home|condo|renter/i;
  const dispatch = useDispatch();
  const environment = process.env.REACT_APP_ENV
    ? process.env.REACT_APP_ENV
    : env.APP_ENV;

  const eventSourceURL =
    process.env.REACT_APP_SERVER_ADDRESS || baseURLS[environment];
  const sendQuoteId = async (lob, quoteId) => {
    try {
      await sendQuoteIdToMs(lob, quoteId);
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const handleUmbrellaQuoteResponses = (quotes = [], type) => {
    setUmbrellaQuoteResponses((previousState) => {
      return {
        ...previousState,
        [type]: quotes.filter(
          (quote) => quote.line_of_business__c === UMBRELLA_LINE_OF_BUSINESS
        ),
      };
    });
  };

  const handleFloodLifeAndJewelryQuotes = (quotes, type) => {
    const jewelryQuotes = quotes.filter(
      (quote) => quote.line_of_business__c === "Collections / Personal Articles"
    );
    const floodQuotes = quotes.filter(
      (quote) => quote.line_of_business__c === "Flood"
    );

    setJewelryQuoteResponses((previousState) => {
      return {
        ...previousState,
        [type]: jewelryQuotes,
      };
    });
    setFloodQuoteResponses((previousState) => {
      return {
        ...previousState,
        [type]: floodQuotes,
      };
    });
    if (type === PRIMARY) {
      const lifeQuotes = quotes.filter(
        (quote) => quote.line_of_business__c === "Life"
      );
      const primaryLifeQuotes = lifeQuotes.filter((quote) => {
        return quote.quote_response_external_id__c.indexOf("Spouse") === -1;
      });
      const spouseLifeQuotes = lifeQuotes.filter((quote) => {
        return quote.quote_response_external_id__c.indexOf("Spouse") !== -1;
      });

      setLifeQuoteResponses({
        primary: primaryLifeQuotes,
        spouse: spouseLifeQuotes,
      });
    }
  };

  const homeResponsesFilter = (responses) =>
    responses.filter((quote) => {
      if (session.line_of_business.includes("Renter")) {
        return quote.line_of_business__c === "Renter's";
      } else {
        return session.line_of_business.replace("Auto", "") === "Home" &&
          isDwelling()
          ? quote.line_of_business__c === "Dwelling"
          : quote.line_of_business__c ===
              session.line_of_business.replace("Auto", "");
      }
    });

  const handleQuotesEventData = (quoteResponses, isHome, type) => {
    if (quoteResponses.length >= 2) {
      const validQuoteResponses = quoteResponses.filter(
        (quote) => quote.company_client_id__c
      );
      if (isHome) {
        const floodZone = getFloodZone(quoteResponses);
        // do not remove flood zone if the second quote doesn't have it
        if (floodZone) {
          dispatch(addProperty({ floodZone }));
        }
        const homeOnlyResponses = homeResponsesFilter(quoteResponses);
        const homeOnlyQuoteResponses = homeResponsesFilter(validQuoteResponses);
        handleFloodLifeAndJewelryQuotes(validQuoteResponses, type);
        setQuoteResponsesData((previousState) =>
          setStateHelper(type, home, previousState, homeOnlyResponses)
        );
        setHomeAutoQuoteResponses((previousState) =>
          setStateHelper(type, home, previousState, homeOnlyQuoteResponses)
        );
      } else {
        setQuoteResponsesData((previousState) =>
          setStateHelper(type, auto, previousState, quoteResponses)
        );
        setHomeAutoQuoteResponses((previousState) =>
          setStateHelper(type, auto, previousState, validQuoteResponses)
        );
        handleUmbrellaQuoteResponses(validQuoteResponses, type);
      }
    }
  };

  const setBestQuotes = () => {
    const bestHome = getBestQuotes(quoteResponsesData, "home");
    const bestAuto = getBestQuotes(quoteResponsesData, "auto");
    const bestHomeUnbundled = getUnbundledQuotes(bestHome, isMultiLine);
    const bestAutoUnbundled = getUnbundledQuotes(bestAuto, isMultiLine);

    setQuoteResponsesData((previousState) =>
      setStateHelper(BEST, home, previousState, bestHome)
    );
    setHomeAutoQuoteResponses((previousState) =>
      setStateHelper(BEST, home, previousState, bestHomeUnbundled)
    );

    setQuoteResponsesData((previousState) =>
      setStateHelper(BEST, auto, previousState, bestAuto)
    );
    setHomeAutoQuoteResponses((previousState) =>
      setStateHelper(BEST, auto, previousState, bestAutoUnbundled)
    );

    bundleQuoteResponses(bestHome, bestAuto, BEST);
  };

  const fetchAndUpdateQuotes = async (homeQuoteId, autoQuoteId, type) => {
    try {
      let quoteResponsesHome = [];
      let quoteResponsesAuto = [];

      if (homeQuoteId) {
        quoteResponsesHome = shouldUseMock
          ? await getDummyQuoteResponses(state, "Home")
          : await getResponsesByQuoteRequestId(homeQuoteId);

        // TODO: remove this after MuleSoft stops sending Monoline Ineligible quotes
        if (shouldHideMonolineIneligibleCarriers) {
          quoteResponsesHome.data = quoteResponsesHome.data.filter(
            ({ error_type__c }) => error_type__c !== "Monoline Ineligible"
          );
        }
        handleQuotesEventData(quoteResponsesHome.data, true, type);
      }

      if (autoQuoteId) {
        quoteResponsesAuto = shouldUseMock
          ? await getDummyQuoteResponses(state, "Auto")
          : await getResponsesByQuoteRequestId(autoQuoteId);

        // TODO: remove this after MuleSoft stops sending Monoline Ineligible quotes
        if (shouldHideMonolineIneligibleCarriers) {
          quoteResponsesAuto.data = quoteResponsesAuto.data.filter(
            ({ error_type__c }) => error_type__c !== "Monoline Ineligible"
          );
        }
        handleQuotesEventData(quoteResponsesAuto.data, false, type);
      }

      if (homeQuoteId && autoQuoteId) {
        bundleQuoteResponses(
          quoteResponsesHome.data,
          quoteResponsesAuto.data,
          type
        );
      }
      if (type === PRIMARY) {
        dispatch(
          addContact({
            quotesUpdatedPrimary: true,
            quotesUpdatedSpouse: store.getState().contact.quotesUpdatedSpouse,
          })
        );
      } else {
        dispatch(
          addContact({
            quotesUpdatedPrimary: store.getState().contact.quotesUpdatedPrimary,
            quotesUpdatedSpouse: true,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleQuotes = async () => {
    try {
      await fetchAndUpdateQuotes(
        contact.homeQuoteId,
        contact.autoQuoteId,
        PRIMARY
      );
      if (contact.spouseHomeQuoteId || contact.spouseAutoQuoteId)
        await fetchAndUpdateQuotes(
          contact.spouseHomeQuoteId,
          contact.spouseAutoQuoteId,
          SPOUSE
        );
      await markSessionAsComplete();
      dispatch(updateIsFetching(false));
      dispatch(updateIsFetchingQuotes(false));
    } catch (e) {
      console.log(e);
    }
  };

  const getDaAssignmentType = (currentFlow) => {
    if (!currentFlow) {
      return null;
    }
    return currentFlow === "flow1" ? "Flow 1" : "Flow 2";
  };

  const syncAccountToSf = () => {
    try {
      if (!session.synced) {
        let logRocketURL;
        LogRocket.getSessionURL((sessionURL) => {
          logRocketURL = sessionURL;
        });
        let payload = {
          accountId: contact.accountId,
          contactId: contact.contactId,
          lob: getLineOfBusiness(session.line_of_business || ""),
          assignmentType:
            getDaAssignmentType(contact?.flow) ?? session.assignmentType,
          isAgentRaterAccount: true,
          sessionRecordingURL: logRocketURL,
          isDwelling: isDwelling(),
          isPrimaryContact: contact.isPrimaryContact,
        };
        if (contact.spouseId) {
          payload = {
            ...payload,
            spouseId: contact.spouseId,
          };
        }

        syncAllData(payload).catch((error) =>
          console.log({
            fn: "Quotes -> syncAccountToSf",
            message: "Error syncing data to SF",
            error,
          })
        );
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const markSessionAsComplete = async () => {
    if (session && !session.completed) {
      await handleUpdateSession({
        uuid: session.uuid,
        completed: true,
      });
    }
  };

  const closeAllEvents = () => {
    try {
      events.forEach((event) => event.close());
    } catch (e) {
      console.log(e);
    }
  };

  const isMonolineQuote = (lob) => lob.indexOf("Auto") <= 0;

  /**
   * @param {{
   *   quoteResponses: {
   *     company_client_id__c?: string;
   *     line_of_business__c?: string;
   *   }[];
   *   quoteRequestId: string;
   *   shouldAppendValues: boolean;
   * }} input
   */
  const processNewQuoteResponses = ({
    quoteResponses,
    quoteRequestId,
    shouldAppendValues = false,
  }) => {
    let isHome =
      quoteRequestId === contact.homeQuoteId ||
      quoteRequestId === contact.spouseHomeQuoteId;
    let type = PRIMARY;
    if (quoteResponses.length > 2) {
      if (quoteRequestId === contact.homeQuoteId) {
        isHome = true;
        type = PRIMARY;
      }
      if (quoteRequestId === contact.autoQuoteId) {
        isHome = false;
        type = PRIMARY;
      }
      if (quoteRequestId === contact.spouseHomeQuoteId) {
        isHome = true;
        type = SPOUSE;
      }
      if (quoteRequestId === contact.spouseAutoQuoteId) {
        isHome = false;
        type = SPOUSE;
      }
    }

    const validResponses = quoteResponses.filter(
      (quote) => quote.company_client_id__c
    );

    // handle mono-line quotes
    if (isHome) {
      const homeOnlyResponses = homeResponsesFilter(quoteResponses);
      const homeOnlyValidResponses = homeResponsesFilter(validResponses);
      setQuoteResponsesData((previousState) =>
        setStateHelper(
          type,
          home,
          previousState,
          homeOnlyResponses,
          shouldAppendValues
        )
      );
      setHomeAutoQuoteResponses((previousState) =>
        setStateHelper(
          type,
          home,
          previousState,
          homeOnlyValidResponses,
          shouldAppendValues
        )
      );
    } else {
      setQuoteResponsesData((previousState) =>
        setStateHelper(
          type,
          auto,
          previousState,
          quoteResponses,
          shouldAppendValues
        )
      );
      setHomeAutoQuoteResponses((previousState) =>
        setStateHelper(
          type,
          auto,
          previousState,
          validResponses,
          shouldAppendValues
        )
      );
    }

    // handle bundle quotes
    reRunBundle(type);
  };

  /** @param {TYPES.BundleType} type */
  function reRunBundle(type) {
    if (contact.homeQuoteId && contact.autoQuoteId) {
      bundleQuoteResponses(
        quoteResponsesDataRef.current.primary.home,
        quoteResponsesDataRef.current.primary.auto,
        type
      );
    }
    if (contact.spouseHomeQuoteId && contact.spouseAutoQuoteId) {
      bundleQuoteResponses(
        quoteResponsesDataRef.current.spouse.home,
        quoteResponsesDataRef.current.spouse.auto,
        type
      );
    }
  }

  const eventSourceQuoteResponses = (quoteId) => {
    const queryString = getQueryParamString({
      appName: "Aviator",
      sessionId: session.uuid,
    });
    const qrEvent = new EventSource(
      `${eventSourceURL}/api/v2/quoteresponse/${quoteId}?${queryString}`,
      {
        withCredentials: true,
      }
    );

    setEvents((previous) => {
      window.quoteEvents = [...previous, qrEvent];
      return [...previous, qrEvent];
    });

    qrEvent.onerror = (e) => {
      console.log("Error at Quote responses event source connection ", e);
      qrEvent.close();
    };

    setTimeout(() => {
      dispatch(updateIsFetching(false));
      dispatch(updateIsFetchingQuotes(true));
    }, 5000);

    qrEvent.addEventListener("quotesUpdating", (event) => {
      // Will be called when server keeps sending quotes.
      const { data } = event;
      const quoteResponses = JSON.parse(data);
      return processNewQuoteResponses({
        quoteResponses,
        quoteRequestId: quoteId,
      });
    });

    qrEvent.addEventListener("quotesUpdated", async (event) => {
      // Will be called when server stops sending more quotes.
      try {
        const { data } = event;
        const quotesData = JSON.parse(data);
        const responseCount = quotesData.responseCount;
        let getExpectedResponseCount = getExpectedResponseCountByLob(
          session.line_of_business,
          contact.spouseId ? true : false
        );

        if (responseCount === getExpectedResponseCount) {
          setTimeout(
            async () => {
              await handleQuotes();
              if (session && !session.completed) {
                await handleUpdateSession({
                  uuid: session.uuid,
                  completed: true,
                });
              }
            },
            isMonolineQuote(session.line_of_business || "") ? 4000 : 2000
          );
        }
      } catch (error) {
        console.log("Error at Quote responses event source connection ", error);
      }
    });

    qrEvent.addEventListener("quotesError", () => {
      // Will be called when server sends an error during the process.
      console.log("Error while receiving quote responses ");
    });
  };

  const updateRecommendedQuotes = (homeQuoteId, autoQuoteId, type) => {
    const recommendedQuotes = [];
    if (autoQuoteId && homeQuoteId) {
      if (bundledQuoteResponses[type].length) {
        const recommendedHome = {
          heroku_id: bundledQuoteResponses[type][0].homeQuote.heroku_id,
          recommended_package__c: true,
          quote_request_heroku_id: homeQuoteId,
        };
        const recommendedAuto = {
          heroku_id: bundledQuoteResponses[type][0].autoQuote.heroku_id,
          recommended_package__c: true,
          quote_request_heroku_id: autoQuoteId,
        };
        recommendedQuotes.push(recommendedHome);
        recommendedQuotes.push(recommendedAuto);
      }
    } else if (autoQuoteId && homeAutoQuoteResponses[type].auto.length) {
      const recommendedAuto = {
        heroku_id: homeAutoQuoteResponses[type].auto[0].heroku_id,
        recommended_package__c: true,
        quote_request_heroku_id: autoQuoteId,
      };
      recommendedQuotes.push(recommendedAuto);
    } else if (homeQuoteId && homeAutoQuoteResponses[type].home.length) {
      const recommendedHome = {
        heroku_id: homeAutoQuoteResponses[type].home[0].heroku_id,
        recommended_package__c: true,
        quote_request_heroku_id: homeQuoteId,
      };
      recommendedQuotes.push(recommendedHome);
    }
    if (recommendedQuotes.length) {
      updateQuoteResponseRecommendation(recommendedQuotes);
    }
  };

  const handleRecommendedQuotes = async () => {
    await updateRecommendedQuotes(
      contact.homeQuoteId,
      contact.autoQuoteId,
      PRIMARY
    );
    if (contact.spouseId) {
      await updateRecommendedQuotes(
        contact.spouseHomeQuoteId,
        contact.spouseAutoQuoteId,
        SPOUSE
      );
    }
  };

  const handleCoverages = () => {
    try {
      if (Object.keys(session).length && session.line_of_business) {
        let homeCoveragesData = {};
        let autoCoveragesData = {};
        if (
          contact.autoQuoteId &&
          session.line_of_business.indexOf("Auto") !== -1
        ) {
          const finalCoverages = {
            liability:
              coverages.bodily_injury +
              "/" +
              (coverages.property_damage?.length > 3
                ? coverages.property_damage.substring(
                    0,
                    coverages.property_damage.length - 3
                  )
                : coverages.property_damage),
            umuim: coverages.uim,
            umuimumpd:
              coverages.uim +
              "/" +
              (coverages.uim_property_damage.length > 3
                ? coverages.uim_property_damage.substring(
                    0,
                    coverages.uim_property_damage.length - 3
                  )
                : coverages.uim_property_damage),
            ...coverages,
            comprehensive: coverages.comp,
            collision: coverages.collision,
            rental: coverages.rental || "30/900",
            towing: coverages.towing || "Yes",
          };
          autoCoveragesData = { ...finalCoverages };
        }

        if (contact.homeQuoteId && session.line_of_business.match(homeRegex)) {
          const finalCoverages = {
            ...coverages,
            dwelling: Math.floor(coverages.dwelling / 1000) * 1000,
            otherStructures:
              Math.floor((coverages.dwelling * 0.1) / 1000) * 1000,
            contents: session.line_of_business.match(/renter/i)
              ? coverages.personal_property || 50000
              : Math.floor((coverages.dwelling * 0.5) / 1000) * 1000,
            lossOfUse: session.line_of_business.match(/renter/i)
              ? "Included"
              : Math.floor((coverages.dwelling * 0.2) / 1000) * 1000,
            personal_property: Math.floor(
              (coverages.dwelling * coverages.personal_property) / 100
            ),
            liability: coverages.personal_liability,
            wind_deductible: coverages.wind,
            hurricane_deductible: coverages.hurricane,
            base_deductible: coverages.aop,
          };
          if (session.line_of_business.match(/condo/i)) {
            delete finalCoverages.otherStructures;
          }
          if (session.line_of_business.match(/renter/i)) {
            [
              "dwelling",
              "otherStructures",
              "wind_deductible",
              "hurricane_deductible",
              "replacementDwelling",
            ].forEach((k) => {
              delete finalCoverages[k];
            });
          }
          // if (finalCoverages.dwelling > 1500000) {
          //   dispatch(updateIsFetching(false));
          // } else
          if (
            ["MOBILE HOME", "MANUFACTURED HOME"].includes(
              property.landUseDescription
            )
          ) {
            dispatch(updateIsFetching(false));
          } else {
            homeCoveragesData = { ...finalCoverages };
          }
        }
        setCoveragesData({ home: homeCoveragesData, auto: autoCoveragesData });
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };
  //handle quote responses
  const handleQuoteResponses = async (homeQuoteId, autoQuoteId) => {
    try {
      if (autoQuoteId && session.line_of_business.indexOf("Auto") !== -1) {
        if (
          !contact?.responsePageResubmit ||
          (contact?.responsePageResubmit && contact?.resubmitAuto)
        ) {
          await sendQuoteId("auto", autoQuoteId);
          eventSourceQuoteResponses(autoQuoteId);
        }
      }

      if (homeQuoteId && session.line_of_business.match(homeRegex)) {
        if (
          ["MOBILE HOME", "MANUFACTURED HOME"].includes(
            property.landUseDescription
          )
        ) {
          dispatch(updateIsFetching(false));
        } else {
          if (
            !contact?.responsePageResubmit ||
            (contact?.responsePageResubmit && contact?.resubmitHome)
          ) {
            await sendQuoteId("home", homeQuoteId);
            eventSourceQuoteResponses(homeQuoteId);
          }
        }
      }
      store.dispatch(
        addContact({
          responsePageResubmit: false,
          resubmitAuto: false,
          resubmitHome: false,
        })
      );
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const handleRefresh = async () => {
    if (
      session.completed != null &&
      contact.accountId &&
      Object.keys(property).length
    ) {
      if (!session.synced) {
        syncAccountToSf();
      }
      // do not call event streams if quotes are already in the DB
      // or if we are using mock data
      if (session.completed || shouldUseMock) {
        await handleQuotes();
        dispatch(updateIsFetchingQuotes(false));
      } else {
        setTimeout(async () => {
          if (!quotesUpdatedPrimaryRef.current) {
            await handleQuotes();
          }
        }, EVENT_STREAM_TIMEOUT);

        setTimeout(async () => {
          await markSessionAsComplete();
        }, EVENT_STREAM_TIMEOUT + 10 * 1000);

        handleQuoteResponses(contact.homeQuoteId, contact.autoQuoteId);
        if (contact.spouseHomeQuoteId || contact.spouseAutoQuoteId)
          handleQuoteResponses(
            contact.spouseHomeQuoteId,
            contact.spouseAutoQuoteId
          );
      }
      dispatch(
        addContact({
          quotesSubmitted: true,
          fieldsEdited: false,
        })
      );
    }
  };

  const bundleQuoteResponses = (homeQuotes, autoQuotes, type) => {
    const allHome = (homeQuotes || []).filter(
      (quote) =>
        quote.line_of_business__c ===
          (session.line_of_business.includes("Renter")
            ? "Renter's"
            : session.line_of_business.replace("Auto", "")) &&
        quote.company_client_id__c
    );
    const allAuto = (autoQuotes || []).filter(
      (quote) =>
        quote.line_of_business__c === "Auto" && quote.company_client_id__c
    );
    let bundledResponses = [];
    allHome.forEach((home, i) => {
      const homeQuote = {
        ...home,
        index: i,
        combinable: combinableCarriers.indexOf(home.company_client_id__c) > -1,
      };
      allAuto.forEach((auto, j) => {
        const autoQuote = {
          ...auto,
          index: j,
          combinable:
            combinableCarriers.indexOf(auto.company_client_id__c) > -1,
        };

        if (validateBundle(homeQuote, autoQuote)) {
          bundledResponses.push({
            homeQuote: homeQuote,
            autoQuote: autoQuote,
            bundledPrice: calculateBundlePrice(homeQuote, autoQuote),
          });
        }
      });
    });

    setBundledQuoteResponses((previousState) => {
      return { ...previousState, [type]: bundledResponses };
    });
  };

  useEffect(() => {
    const hasQuotesInBundle =
      hasBundles() && !isEmptyBundle(bundledQuoteResponses); // check if there are any quotes in the bundle
    const showPackageTab =
      hasQuotesInBundle && !isFetchingQuotes && !isFetchingInitalQuotes; // show package tab if there are quotes in the bundle and we are not fetching quotes
    const defaultActiveTab =
      (isMultiLine() && isFetchingQuotes) ||
      isFetchingInitalQuotes ||
      showPackageTab
        ? "package"
        : "split"; // show package tab if we are fetching quotes or if we have quotes in the bundle otherwise show split tab

    dispatch(
      addContact({
        ...contact,
        activeTab: defaultActiveTab,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetchingQuotes,
    JSON.stringify(bundledQuoteResponses.primary),
    JSON.stringify(bundledQuoteResponses.spouse),
  ]);

  useEffect(() => {
    const { quoteEvents } = window;

    clearAllEventStreams(quoteEvents);

    dispatch(
      addContact({
        ...contact,
        quotesUpdatedPrimary: false,
        quotesUpdatedSpouse: false,
      })
    );
    return () => {
      dispatch(updateIsFetchingQuotes(false));
    };
    // on page refresh, we are not fetching quotes, so these items are needed in the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //useEffect to handle Branch quotes.
  useEffect(() => {
    if (
      Object.keys(session).length &&
      Object.keys(contact).length &&
      Object.keys(property).length &&
      session.completed === false &&
      !BRANCH_TURN_OFF_STATES.includes(property.administrative_area_level_1)
    ) {
      if (!session.qti_id) {
        handleBranchQuote();
      } else {
        handleBranchQuoteResponse();
      }
    }

    //eslint-disable-next-line
  }, [
    contact.accountId,
    property.administrative_area_level_1,
    session.completed,
  ]);

  useEffect(() => {
    handleCoverages();
    //eslint-disable-next-line
  }, [contact]);

  useEffect(() => {
    if (
      contact.spouseId &&
      !["CA"].includes(
        (property.administrative_area_level_1 || "").toUpperCase()
      )
    )
      setQuotesToDisplay(BEST);
    else setQuotesToDisplay(PRIMARY);
  }, [contact, property.administrative_area_level_1]);

  useEffect(() => {
    handleRefresh();
    if (session.completed) {
      setTimeout(() => {
        dispatch(updateIsFetchingQuotes(false));
        handleRecommendedQuotes();
        closeAllEvents();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [
    contact.accountId,
    session.completed,
    property.administrative_area_level_1,
  ]);

  useEffect(() => {
    if (contact.spouseId) {
      setBestQuotes();
    }
    // eslint-disable-next-line
  }, [
    contact,
    quoteResponsesData.primary.home.length,
    quoteResponsesData.primary.auto.length,
    quoteResponsesData.spouse.home.length,
    quoteResponsesData.spouse.auto.length,
  ]);

  useEffect(() => {
    if (session.synced && contact.autoQuoteId && session.qti_id) {
      updateQuoteRequestInSF({
        quoteId: contact.autoQuoteId,
        fieldsToSync: [
          "data_source_prior_bi__c",
          "data_source_current_auto_carrier__c",
          "data_source_current_auto_end_date__c",
          "data_source_home_claim_count__c",
        ],
      });

      updateSfContactByAccountId({
        accountId: contact.accountId,
        fieldsToSync: [
          "branch_has_udr__c",
          "branch_non_udr_incident_count__c",
          "data_source_claims_count__c",
          "data_source_violation_count__c",
        ],
      });
    }
    // eslint-disable-next-line
  }, [session.synced]);

  const unsortedQuotes = {
    home: quoteResponsesData[quotesToDisplay].home,
    auto: quoteResponsesData[quotesToDisplay].auto,
    flood: floodQuoteResponses.primary,
    lifePrimary: lifeQuoteResponses.primary,
    lifeSpouse: lifeQuoteResponses.spouse,
    bundle: getBestBundles(bundledQuoteResponses[quotesToDisplay]),
    jewelry: jewelryQuoteResponses.primary,
    umbrella: umbrellaQuoteResponses.primary,
    homeCoverages: coveragesData.home,
    autoCoverages: coveragesData.auto,
  };
  const quotes = sortErrorTypeInQuotes(unsortedQuotes);
  dispatch(addQuotes(quotes));

  const insertNewQuoteResponses = _insertNewQuoteResponses({
    processNewQuoteResponses,
    handleRecommendedQuotes,
  });

  const removeQuoteResponse = _removeQuoteResponse({
    determineType: _determineType([
      contact.spouseHomeQuoteId,
      contact.spouseAutoQuoteId,
    ]),
    setQuoteResponsesData,
    setHomeAutoQuoteResponses,
    reRunBundle,
  });

  return isFetchingInitalQuotes ? (
    <Suspense fallback={<LoadingDots />}>
      <LoadingDots />
    </Suspense>
  ) : (
    <>
      {showSmartHomePackageSelectionModal && (
        <SmartHomePackageSelectionModal
          showSmartHomePackageSelectionModal={
            showSmartHomePackageSelectionModal
          }
          setShowSmartHomePackageSelectionModal={
            setShowSmartHomePackageSelectionModal
          }
          setShowSmartHomeConsentModal={setShowSmartHomeConsentModal}
        />
      )}
      {showSmartHomeConsentModal && (
        <SmartHomeConsentModal
          showSmartHomeConsentModal={showSmartHomeConsentModal}
          setShowSmartHomeConsentModal={setShowSmartHomeConsentModal}
        />
      )}
      {showNewSmartHomePackageSelectionModal && (
        <VivintHomePackageSelectionModal
          showNewSmartHomePackageSelectionModal={
            showNewSmartHomePackageSelectionModal
          }
          setShowNewSmartHomePackageSelectionModal={
            setShowNewSmartHomePackageSelectionModal
          }
          setShowNewSmartHomeConsentModal={setShowNewSmartHomeConsentModal}
        />
      )}
      {showNewSmartHomeConsentModal && (
        <VivintHomeConsentModal
          showNewSmartHomeConsentModal={showNewSmartHomeConsentModal}
          setShowNewSmartHomeConsentModal={setShowNewSmartHomeConsentModal}
        />
      )}
      {isNewResponseDesign ? (
        <QuoteResponsesContextProvider
          value={{
            fetchAndUpdateQuotes,
            insertNewQuoteResponses,
            removeQuoteResponse,
            refreshQuoteResponses: handleRefresh,
            setShowNewSmartHomePackageSelectionModal,
          }}
        >
          <Rates
            setShowSmartHomePackageSelectionModal={
              setShowSmartHomePackageSelectionModal
            }
          />
        </QuoteResponsesContextProvider>
      ) : (
        <QuotesContainer>
          <Container fluid>
            <ContactNameAddress />
            <QuoteResponsesContextProvider
              value={{
                fetchAndUpdateQuotes,
                insertNewQuoteResponses,
                removeQuoteResponse,
                refreshQuoteResponses: handleRefresh,
              }}
            >
              <Rates
                setShowSmartHomePackageSelectionModal={
                  setShowSmartHomePackageSelectionModal
                }
              />
            </QuoteResponsesContextProvider>
          </Container>
        </QuotesContainer>
      )}
    </>
  );
};

export default Quotes;
