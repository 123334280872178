// @ts-check
import { HeadersAndQuotes, QuotesContainer } from "./styled/QuotesContainer";
import { ReactComponent as InfoIcon } from "../assets/images/main/Info.svg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getQuoteHeaderTooltip } from "../utils";
import { PACKAGE_REQUIRED_FOR_DWELLING, PACKAGE_REQUIRED } from "../constants";

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {| "Monoline Eligible"
 *   | "Package Required"
 *   | "Quote Manually"
 *   | null} props.header
 * @param {boolean} props.isDwelling
 * @param {string} props.lob
 * @returns {React.ReactNode}
 */
const RateListFrame = ({ children, header, lob, isDwelling = false }) => {
  const key =
    isDwelling && header === PACKAGE_REQUIRED
      ? PACKAGE_REQUIRED_FOR_DWELLING
      : header;
  return (
    <HeadersAndQuotes>
      {!!header && (
        <h3>
          <span>{header}</span>
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip className="tooltip">
                {getQuoteHeaderTooltip(key, lob) || "No tooltip available"}
              </Tooltip>
            }
          >
            <InfoIcon data-testid="rateListFrameToolTip" />
          </OverlayTrigger>
        </h3>
      )}
      <QuotesContainer>{children}</QuotesContainer>
    </HeadersAndQuotes>
  );
};

export default RateListFrame;
