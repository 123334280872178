import "../../assets/css/Selection.css";

import { Button, CloseButton } from "react-bootstrap";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "../../redux/hooks";

import { ReactComponent as CaretRight } from "../../assets/images/components/response-page/caretRight.svg";
import { CustomText } from "../styled/typography";
import Modal from "react-bootstrap/Modal";
import { addContact } from "../../redux/actions/contact";
import { createProspectInSf } from "../../services/account";
import { handleUpdateSession } from "../../services/utils";
import { partnerCampaign } from "../../constants";
import styled from "styled-components";

type VivintHomeConsentModalProps = {
  showNewSmartHomeConsentModal: boolean;
  setShowNewSmartHomeConsentModal: Dispatch<SetStateAction<boolean>>;
};

const ModalContent = styled.div`
  font-family: "Rethink Sans Variable";
  padding: 40px 72px;
  gap: 1rem;
  & .second-title {
    margin: 32px 0 18px 0;
  }
  & .first-title {
    text-align: center;
  }
  & .closeButton {
    float: right;
    position: absolute;
    right: 22px;
    top: 24px;
  }
  & .modal-dialog {
    max-width: 800px;
  }
  & .submitButton {
    width: 232px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    margin: 40px 157px 0;
    padding: 3px 15px;
    border-radius: 39px;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border: solid 1px #157f3a;
    background-color: #157f3a;
    color: #edfdd6;
  }
  & .acknowledge-container {
    display: flex;
    align-items: start;
    gap: 1rem;
    padding: 0;
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 1.5rem;
    color: #74757b;
    & > input[type="checkbox"] {
      zoom: 1.3;
      transform: translateY(0.2rem);
      accent-color: #fff;
      outline: 1px solid #707070;
      outline-offset: -2px;
    }
  }
`;

const VivintHomeConsentModal = ({
  showNewSmartHomeConsentModal,
  setShowNewSmartHomeConsentModal,
}: VivintHomeConsentModalProps) => {
  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const disabled = isSubmitting || !isAcknowledged;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAcknowledged(e.target.checked);
  };

  // TODO: Add types for response
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const prospectResponse = (
        await createProspectInSf({
          accountId: contact.accountId,
          contactId: contact.referredContactId,
          propertyId: contact.propertyId,
          packageSelected: contact.packageSelected,
          partnerCampaign: partnerCampaign,
          ownerId: session.logged_in_user,
        })
      ).data;
      const { isSuccess, prospectId } = prospectResponse;
      if (isSuccess) {
        await handleUpdateSession({
          uuid: session.uuid,
          contact_data: JSON.stringify({
            ...contact,
            prospectId,
          }),
        });
        dispatch(addContact({ ...contact, prospectId }));
        setIsSubmitting(false);
        setShowNewSmartHomeConsentModal(false);
      }
    } catch (error) {
      console.log("Error creating prospect in sf", error);
      setIsSubmitting(false);
    }
  };
  return (
    <Modal
      show={showNewSmartHomeConsentModal}
      centered
      className="vivintSmartSelection"
    >
      <ModalContent>
        <div className="form-container">
          <div className="first-title">
            <CustomText
              color="#2d2c2a"
              fontSize="22px"
              fontWeight="bold"
              fontFamily="Rethink Sans Variable"
              decoration={null}
              tabIndex={null}
              handleClick={null}
            >
              Refer Vivint Smart Home
            </CustomText>
          </div>

          <CloseButton
            onClick={() => setShowNewSmartHomeConsentModal(false)}
            className="closeButton"
          />
          <div>
            <CustomText
              fontSize="20px"
              fontWeight="bold"
              align="start"
              color="#2d2c2a"
              className="second-title"
              fontFamily={null}
              decoration={null}
              tabIndex={null}
              handleClick={null}
            >
              Please read the following to your client:
            </CustomText>
            <CustomText
              fontSize="16px"
              fontWeight={500}
              align="start"
              color="#2d2c2a"
              fontFamily={null}
              decoration={null}
              tabIndex={null}
              handleClick={null}
            >
              {`“With your permission I would love to connect you with our preferred home security partner, Vivint, to discuss their home security offerings. You do not need to consent to a call to make a purchase. Do I have your permission for Vivint to email and/or give you a call?”`}
            </CustomText>
            <div className="acknowledge-container">
              <input
                id="acknowledge"
                type="checkbox"
                checked={isAcknowledged}
                onChange={handleChange}
              />
              <label htmlFor="acknowledge">
                By clicking Submit below, I acknowledge that I have read the
                above to the Client verbatim and the Client has agreed to have
                their personal information sent to Vivint.
              </label>
            </div>
            <div className="row justify-content-center">
              <Button
                onClick={handleSubmit}
                disabled={disabled}
                className="submitButton"
              >
                Submit
                <CaretRight />
              </Button>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
export default VivintHomeConsentModal;
