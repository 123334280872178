import { PAGE_TAB_MAP, TABS, DWELLING_TABS } from "../constants";
import {
  clearAllEventStreams,
  getKeyInMapByValue,
  getPageNameFromUrl,
  getTabIcon,
  getTabStatus,
  setFieldsEdited,
  isDwelling,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";

import { ImgButton } from "./styled/buttons/misc";
import backButtonSvg from "../assets/images/components/backButtonIcon.svg";
import { getPagenumber } from "../utils/strings";
import { handleUpdateSession } from "../services/utils";
import styled from "styled-components";
import { updateIsFetching } from "../redux/actions/fetching";
import { updateIsFetchingQuotes } from "../redux/actions/fetchingQuotes";
import { useNavigate } from "react-router-dom";

const ICON_SIZE = 41;

export const ProgressBarWrapper = styled.div`
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: ${(props) => (props.isDwelling ? "30rem" : "50rem")};
`;

const BackButtonStyled = styled(ImgButton)`
  position: absolute;
  left: -35px;
  bottom: 10px;
  transform: translateX(-100%);
  padding: 0;
  & > img {
    width: 25px;
    height: 25px;
  }
`;

const BackButton = ({ handleClick, pageName }) => {
  const contact = useSelector((store) => store.contact);

  const { isReshop, isHomePolicySelected } = contact;

  const isBackButtonHidden =
    (isReshop && isHomePolicySelected && pageName === "PropertyInfo") ||
    pageName === "PolicyType";

  return isBackButtonHidden ? null : (
    <BackButtonStyled aria-label="Go Back" onClick={handleClick}>
      <img src={backButtonSvg} alt="back button" />
    </BackButtonStyled>
  );
};

const Icon = styled.img`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const IconButton = styled.button`
  all: unset;
  grid-column: 1;
  box-sizing: content-box;

  &:enabled:hover {
    transform: translateY(-0.1rem);
  }
`;

const IconContainer = styled.li`
  display: grid;
  grid-template-columns: ${ICON_SIZE}px 1fr;
  justify-items: center;
  align-items: center;
  font-size: 0.8rem;
  background-color: white;
  z-index: 1;

  &:not(:last-child) {
    flex-grow: 1;
    &::after {
      content: "";
      background: ${(props) => props.theme[props.lineColor]};
      height: 1.8px;
      width: 100%;
    }
  }
`;

const TabName = styled.div`
  grid-column: 1;
  color: ${(props) =>
    props.status === "disabled" ? "gray" : props.theme.green3};
`;

const ProgressBarUl = styled.ul`
  all: unset;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ProgressBarLi = ({ tab, status, nextTabStatus, lob }) => {
  const session = useSelector((store) => store.session);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const icon = getTabIcon(tab, status);
  // tab cannot be Details because Auto Details uses this key
  const tabName = tab === "Home Details" ? "Details" : tab;

  const handleClick = async () => {
    if (tab === "Policy") {
      setFieldsEdited();
    }
    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: lob,
      page_stopped: getPagenumber(lob, getKeyInMapByValue(PAGE_TAB_MAP, tab)),
    });
    const { quoteEvents } = window;
    clearAllEventStreams(quoteEvents);
    if (tab === "Results") {
      dispatch(updateIsFetching(true));
    }
    dispatch(updateIsFetchingQuotes(false));

    navigate(`/agent/${lob}/${getKeyInMapByValue(PAGE_TAB_MAP, tab)}`);
  };

  const isDisabled = status === "disabled";
  const isNextTabDisabled = nextTabStatus === "disabled";
  const lineColor = isDisabled || isNextTabDisabled ? "disabled" : "green3";

  return (
    <IconContainer lineColor={lineColor}>
      <TabName status={status}>{tabName}</TabName>
      <IconButton
        disabled={isDisabled}
        aria-label={`go to tab ${tab}`}
        onClick={handleClick}
      >
        <Icon src={icon} alt={icon} />
      </IconButton>
    </IconContainer>
  );
};

export const ProgressBar = ({ handleBack, lob }) => {
  const pageName = getPageNameFromUrl();
  const currentTab = PAGE_TAB_MAP.get(pageName);
  const tabs = isDwelling() ? DWELLING_TABS : TABS;
  const currentIndex = tabs.findIndex((tab) => tab === currentTab);

  return (
    <ProgressBarWrapper isDwelling={isDwelling()}>
      <ProgressBarUl>
        <BackButton handleClick={handleBack} pageName={pageName} />
        {tabs.map((tab, index) => {
          const status = getTabStatus({ tab, index, currentIndex });
          const nextTab = tabs[index + 1];
          const nextTabStatus = getTabStatus({
            tab: nextTab,
            index: index + 1,
            currentIndex,
          });

          return (
            <ProgressBarLi
              key={tab}
              tab={tab}
              status={status}
              nextTabStatus={nextTabStatus}
              lob={lob}
            />
          );
        })}
      </ProgressBarUl>
    </ProgressBarWrapper>
  );
};
