// @ts-check

import * as ENV from "./env";
import { useAreAgentsDifferent } from "./useGetAgents";
import { getIsBranch } from "./utils";
import { useGetSessionQuoteSource } from "../../utils/session";

/**
 * @param {{
 *   quoteResponse: Parameters<
 *     typeof useAreAgentsDifferent
 *   >[0]["quoteResponse"] &
 *     Parameters<typeof getIsBranch>[0];
 * }} input
 */
export const useShouldResubmit = ({ quoteResponse }) => {
  const areAgentsDifferent = useAreAgentsDifferent({ quoteResponse });
  const quoteSource = useGetSessionQuoteSource();

  if (ENV.RESUBMIT_RESPONSE_FEATURE_FORCE) {
    return true;
  }

  if (!ENV.RESUBMIT_RESPONSE_FEATURE_ENABLED) {
    return false;
  }

  const isBranch = getIsBranch(quoteResponse);
  const isAllowedQuoteSource = ["DA", "DA 2.0", "DA-2.0"].includes(
    String(quoteSource)
  );
  if (isBranch || !isAllowedQuoteSource) {
    return false;
  }

  if (areAgentsDifferent) {
    return true;
  }

  return false;
};
