import CustomReactSelect from "./CustomReactSelect";
import QuoteWrapper from "./QuoteWrapper";
import { flood } from "../constants";
import { getFloodZoneIcon } from "../utils/misc";
import { useSelector } from "react-redux";

// TODO: move these functions out of the props and import them instead
const QuoteSelect = ({
  lob,
  lobDescription,
  getRecommendedCarrierList,
  handleSelectedQuote,
}) => {
  const property = useSelector((store) => store.property);
  const floodZone = property?.floodZone;

  return (
    <QuoteWrapper lob={lob}>
      <div className="d-flex align-items-center justify-content-end ms-5">
        <div className="d-flex align-items-center" style={{ width: 150 }}>
          {lobDescription} Carrier:
          {lob === flood && !!floodZone && (
            <img
              className="floodzone-icon"
              src={getFloodZoneIcon(floodZone)}
              alt={floodZone}
              title={`Flood zone ${floodZone}`}
            />
          )}
        </div>
      </div>
      <CustomReactSelect
        selectOptions={getRecommendedCarrierList(lob)}
        handleSelectedQuote={handleSelectedQuote}
        lob={lob}
      />
    </QuoteWrapper>
  );
};

export default QuoteSelect;
