import { AutoCoverages, HomeCoverages, Quote } from "./types";

export const DUMMY_DATA_FOR_LOGOS = [
  { company_client_id__c: "391", carrier__c: "Allstate" },
  { company_client_id__c: "445", carrier__c: "Edison" },
  { company_client_id__c: "467", carrier__c: "Farmers of Salem" },
  { company_client_id__c: "416", carrier__c: "Florida Family" },
  { company_client_id__c: "418", carrier__c: "Heritage" },
  {
    company_client_id__c: "610",
    carrier__c: "Naitonal General, An Allstate Company",
  },
  { company_client_id__c: "361", carrier__c: "NatGen Premier" },
  { company_client_id__c: "447", carrier__c: "Nationwide" },
  { company_client_id__c: "160", carrier__c: "Pacific Specialty" },
  { company_client_id__c: "624", carrier__c: "Tower Hill Specialty" },
  { company_client_id__c: "102", carrier__c: "Universal North America" },
  { company_client_id__c: "379", carrier__c: "American Integrity" },
  { company_client_id__c: "39", carrier__c: "American Modern" },
  { company_client_id__c: "438", carrier__c: "American Traditions" },
  { company_client_id__c: "501", carrier__c: "Bamboo" },
  { company_client_id__c: "470", carrier__c: "Berkshire Hathaway Guard" },
  { company_client_id__c: "994", carrier__c: "Branch" },
  { company_client_id__c: "52", carrier__c: "Bristol West (Foremost Choice)" },
  { company_client_id__c: "148", carrier__c: "Cincinnati" },
  { company_client_id__c: "532", carrier__c: "Clearcover" },
  { company_client_id__c: "168", carrier__c: "Cypress" },
  { company_client_id__c: "574", carrier__c: "Elephant" },
  { company_client_id__c: "406", carrier__c: "Florida Peninsula" },
  { company_client_id__c: "479", carrier__c: "Foremost Signature - (MetLife)" },
  { company_client_id__c: "994", carrier__c: "Branch" },
  { company_client_id__c: "199", carrier__c: "Hanover" },
  { company_client_id__c: "113", carrier__c: "Homeowners of America" },
  { company_client_id__c: "998", carrier__c: "Lemonade" },
  { company_client_id__c: "581", carrier__c: "Main Street America" },
  { company_client_id__c: "17", carrier__c: "Mercury" },
  { company_client_id__c: "18", carrier__c: "Mercury" },
  { company_client_id__c: "384", carrier__c: "Mission Select" },
  { company_client_id__c: "430", carrier__c: "Monarch National" },
  { company_client_id__c: "343", carrier__c: "Mutual of Enumclaw" },
  { company_client_id__c: "494", carrier__c: "Narragansett Bay" },
  { company_client_id__c: "203", carrier__c: "Olympus" },
  { company_client_id__c: "542", carrier__c: "Openly" },
  { company_client_id__c: "605", carrier__c: "Orion 180" },
  { company_client_id__c: "363", carrier__c: "Philadelphia Contributionship" },
  { company_client_id__c: "131", carrier__c: "Plymouth Rock" },
  { company_client_id__c: "25", carrier__c: "Progressive" },
  { company_client_id__c: "377", carrier__c: "Progressive" },
  { company_client_id__c: "992", carrier__c: "Root" },
  { company_client_id__c: "35", carrier__c: "Safeco" },
  { company_client_id__c: "565", carrier__c: "SafePoint" },
  { company_client_id__c: "489", carrier__c: "Security First" },
  { company_client_id__c: "422", carrier__c: "Southern Oak" },
  { company_client_id__c: "404", carrier__c: "Stillwater" },
  { company_client_id__c: "425", carrier__c: "Universal Property & Casualty" },
  { company_client_id__c: "436", carrier__c: "Wellington" },
  { company_client_id__c: "988", carrier__c: "Wellington Surplus" },
  { company_client_id__c: "373", carrier__c: "Westfield" },
  { company_client_id__c: "657", carrier__c: "Annex Risk" },
  { company_client_id__c: "334", carrier__c: "Dairyland" },
  { company_client_id__c: "990", carrier__c: "Delos" },
  { company_client_id__c: "999", carrier__c: "Foremost" },
  { company_client_id__c: "67", carrier__c: "Grange" },
  { company_client_id__c: "344", carrier__c: "Johnson & Johnson" },
  { company_client_id__c: "986", carrier__c: "NatGen Advantage" },
  { company_client_id__c: "366", carrier__c: "NatGen Value" },
  { company_client_id__c: "201", carrier__c: "National General" },
  { company_client_id__c: "980", carrier__c: "Northern Neck Insurance" },
  { company_client_id__c: "228", carrier__c: "Rockingham" },
  { company_client_id__c: "507", carrier__c: "SageSure" },
  { company_client_id__c: "503", carrier__c: "Sagesure Elevate " },
  { company_client_id__c: "509", carrier__c: "Sagesure Harco National" },
  { company_client_id__c: "513", carrier__c: "Sagesure IAT" },
  { company_client_id__c: "502", carrier__c: "Sagesure Occidental" },
  { company_client_id__c: "512", carrier__c: "Sagesure SafeChoice" },
  { company_client_id__c: "505", carrier__c: "Sagesure Safeport" },
  { company_client_id__c: "504", carrier__c: "Sagesure Sure" },
  { company_client_id__c: "514", carrier__c: "Sagesure Trium" },
  { company_client_id__c: "506", carrier__c: "Sagesure VAVE" },
  { company_client_id__c: "510", carrier__c: "Sagesure Wilshire" },
  { company_client_id__c: "515", carrier__c: "Sagesure Markel" },
  { company_client_id__c: "609", carrier__c: "Wright Flood" },
  { company_client_id__c: "966", carrier__c: "RLI" },
  { company_client_id__c: "421", carrier__c: "Tower Hill" },
  { company_client_id__c: "13", carrier__c: "Travelers" },
  { company_client_id__c: "303", carrier__c: "Trexis" },
  { company_client_id__c: "305", carrier__c: "Utica National" },
];

export const DUMMY_RESPONSES: Quote[] = [
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 26.58,
    premium_total__c: 26.58,
    premium_total_ppm__c: 26.58,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "ETHOS",
    flood_zone__c: null,
    company_client_id__c: "991",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Life",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "SUCCESShttps://app.ethoslife.com?p=20714&utm_content=GHcrm",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "ETHOS_Life_Insured_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-ETHOS-Life",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "SUCCESShttps://app.ethoslife.com?p=20714&utm_content=GHcrm",
    marketing_messages__c: null,
    company_url_long__c: "https://app.ethoslife.com?p=20714&utm_content=GHcrm",
    company_url__c: "https://app.ethoslife.com?p=20714&utm_content=GHcrm",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "3a471495-bc46-4a38-b178-a59dfc8e3264",
    term__c: "10",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: 479520,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TgLIAA0",
    recommended_package__c: false,
    life_coverage__c: 479520,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 26.58,
    premium_total__c: 26.58,
    premium_total_ppm__c: 26.58,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "ETHOS",
    flood_zone__c: null,
    company_client_id__c: "991",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Life",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "SUCCESShttps://app.ethoslife.com?p=20714&utm_content=GHcrm",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "ETHOS_Life_Insured_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-ETHOS-Life",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "SUCCESShttps://app.ethoslife.com?p=20714&utm_content=GHcrm",
    marketing_messages__c: null,
    company_url_long__c: "https://app.ethoslife.com?p=20714&utm_content=GHcrm",
    company_url__c: "https://app.ethoslife.com?p=20714&utm_content=GHcrm",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "037861d6-f368-4693-8b37-d6d54a686a16",
    term__c: "10",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: 479520,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TgLIAA0",
    recommended_package__c: false,
    life_coverage__c: 479520,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 496.13,
    premium_total__c: 496.13,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "AON",
    flood_zone__c: "X",
    company_client_id__c: "993",
    company_quote_number__c: "QZ401541427",
    company_url_text__c: null,
    line_of_business__c: "Flood",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Contents coverage limit set to lender federal requirements. Higher limits available.  Deductible has been changed to the next highest available. ",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "AON_Flood_943d8a41-0e30-4c00-9af9-fcff7e043456",
    carrier_number__c: null,
    name: "Ta-AON-Flood",
    error_messages__c:
      "Contents coverage limit set to lender federal requirements. Higher limits available.  Deductible has been changed to the next highest available. ",
    extended_status__c: null,
    remarks__c:
      "Contents coverage limit set to lender federal requirements. Higher limits available.  Deductible has been changed to the next highest available. ",
    marketing_messages__c: null,
    company_url_long__c:
      "https://uat-edge.aon.net/HOI/Setup/0d7b61b9-53f0-44ae-a51f-8be750d30e8b",
    company_url__c:
      "https://uat-edge.aon.net/HOI/Setup/0d7b61b9-53f0-44ae-a51f-8be750d30e8b",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "6977cb1a-ddb2-400e-9008-4dffa370ad1a",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: 250000,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TgY1AAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 624,
    premium_total__c: 624,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Neptune",
    flood_zone__c: "X",
    company_client_id__c: "0",
    company_quote_number__c: "TX4064AY2OWRXV",
    company_url_text__c: null,
    line_of_business__c: "Flood",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "SUCCESS - TX4064AY2OWRXV",
    rquid__c: null,
    status__c: "APPROVED",
    quote_response_external_id__c:
      "Neptune_Insurance_Flood_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta - Neptune-Flood",
    error_messages__c: "SUCCESS - TX4064AY2OWRXV",
    extended_status__c: null,
    remarks__c: "SUCCESS - TX4064AY2OWRXV",
    marketing_messages__c: null,
    company_url_long__c:
      "https://uat.neptuneflood.com/agent-hub/#/quote/TX4064AY2OWRXV/auth/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJbXSIsInN1YiI6ImJyb2tlcjo1ZGUxZWVmMC04M2I5LTQ1ZGUtYjQ3MC00NzU3Yjk1NDkxMDYiLCJVc2VyVHlwZSI6IjIiLCJCcm9rZXJTZWNyZXQiOiJiNjhkNzQzOC0zMWQ1LTQzMzYtOWRkZi1iOGIwYzU3YzY2ZTkiLCJBZ2VudFNlY3JldCI6IjdBOTQ3OEU4LTc5QjEtNEVGRC04RDdELTBFRDIwM0JCNkMwNSIsIkFnZW50Tm8iOiJGTDAwMjQiLCJBZ2VudElkIjoiMTIwMTciLCJFeHBpcmF0aW9uIjoiMy8xOC8yMDI0IDg6MzM6NDAgUE0iLCJTdGF0dXMiOiJBIiwibmJmIjoxNzA5NTg0NDIwLCJleHAiOjE3MTA3OTQwMjAsImlhdCI6MTcwOTU4NDQyMH0.g4s70QtzNMkB3GLzLZ2bR1E2L26jo9q7MlR-TnkTLs4",
    company_url__c: "https://neptuneflood.com/agent-hub/#/home",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "24ad81a1-3ce1-4c3f-b1d6-cbdb730ba31d",
    term__c: "12",
    aop_deductible__c: "0",
    w_h_deductible__c: null,
    dwelling_limit__c: 250000,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "126",
    loss_of_use__c: null,
    liability__c: "30",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000000IhQzIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4635,
    premium_total__c: 4635,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-02-28",
    carrier__c: "Sagesure Elevate",
    flood_zone__c: null,
    company_client_id__c: "503",
    company_quote_number__c: "CRU4Q-2144732",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "2144732",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "SageSure_Home_ELEV_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-SageSure-ELEV-Home",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "",
    marketing_messages__c: null,
    company_url_long__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    company_url__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "302cd43b-c8c0-411f-aea6-6ec8ecba0d29",
    term__c: null,
    aop_deductible__c: "1%",
    w_h_deductible__c: "9600",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4635,
    premium_total__c: 4635,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-02-28",
    carrier__c: "Sagesure",
    flood_zone__c: null,
    company_client_id__c: "507",
    company_quote_number__c: "CRU4Q-2144732",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "2144732",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "SageSure_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-SageSure-Home",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "",
    marketing_messages__c: null,
    company_url_long__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    company_url__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "607ce839-8abf-4ea9-a728-5a71f86dc3f3",
    term__c: null,
    aop_deductible__c: "1%",
    w_h_deductible__c: "9600",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4710,
    premium_total__c: 4710,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-02-28",
    carrier__c: "Sagesure Elevate",
    flood_zone__c: null,
    company_client_id__c: "503",
    company_quote_number__c: "CRU4Q-2144732",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "2144732",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "SageSure_Home_ELEV_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-SageSure-ELEV-Home",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "",
    marketing_messages__c: null,
    company_url_long__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    company_url__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "a5ddd010-de48-4b3b-884c-6588900bee90",
    term__c: null,
    aop_deductible__c: "1%",
    w_h_deductible__c: "9600",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4710,
    premium_total__c: 4710,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-02-28",
    carrier__c: "Sagesure",
    flood_zone__c: null,
    company_client_id__c: "507",
    company_quote_number__c: "CRU4Q-2144732",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "2144732",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "SageSure_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-SageSure-Home",
    error_messages__c: "SUCCESS",
    extended_status__c: null,
    remarks__c: "",
    marketing_messages__c: null,
    company_url_long__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    company_url__c: "https://agents.uat.sagesure.com/#quote/CRU4Q-2144732",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "7d80d341-c818-4f5a-9fe1-9f7fef05a8ac",
    term__c: null,
    aop_deductible__c: "1%",
    w_h_deductible__c: "9600",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4887.35,
    premium_total__c: 4887.35,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Nationwide",
    flood_zone__c: null,
    company_client_id__c: "447",
    company_quote_number__c: "361006804-1",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "361006804-1",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Quote Number - 361006804-1",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Nationwide_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Nationwide-Home",
    error_messages__c: "Quote Number - 361006804-1",
    extended_status__c: null,
    remarks__c: "Quote Number - 361006804-1",
    marketing_messages__c: null,
    company_url_long__c:
      "https://personallinespls19.nationwide.com/pc/Job.do?JobNumber=361006804",
    company_url__c:
      "https://personallinespls19.nationwide.com/pc/Job.do?JobNumber=361006804",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "257d7ca6-2132-4cc4-a3b7-0de909851745",
    term__c: "12",
    aop_deductible__c: "$5000",
    w_h_deductible__c: "$5000",
    dwelling_limit__c: 480000,
    personal_property__c: 360000,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "48000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC00000099MW7IAM",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: "40cc4ef5-da37-483e-a0cc-4e10b1e0b7a6",
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 8886.07,
    premium_total__c: 10218.98,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-11",
    request_date__c: "2024-03-04",
    carrier__c: "Branch",
    flood_zone__c: null,
    company_client_id__c: "994",
    company_quote_number__c: "c59e4059-5122-4abc-9922-f32711646e75",
    company_url_text__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    line_of_business__c: "Home",
    quote_response_rquid__c: "75c27b52-14ef-4a8c-8b1e-d00d3aaf8095",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Quote Successful",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Branch_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Branch-Home",
    error_messages__c: "Quote Successful",
    extended_status__c: "SUCCESS",
    remarks__c: "Quote Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    company_url__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "cabe033e-4ed2-42f3-a08c-5706fa8aa265",
    term__c: "12",
    aop_deductible__c: "0.02",
    w_h_deductible__c: "0.02",
    dwelling_limit__c: 731000,
    personal_property__c: 438600,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "73100",
    loss_of_use__c: "146200",
    liability__c: "100000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: "0.02",
    carrier_login__c: "a2g3c000008Hv6hAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: 1483.85,
    discounts__c:
      '{"bundleDiscount":4292,"connectedHome":0,"paperless":377.58000000000175,"discountInventoryScore":0}',
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: "RC1",
    error_responsibility__c: null,
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 12618.93,
    premium_total__c: 14511.77,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-11",
    request_date__c: "2024-03-04",
    carrier__c: "Branch",
    flood_zone__c: null,
    company_client_id__c: "994",
    company_quote_number__c: "c59e4059-5122-4abc-9922-f32711646e75",
    company_url_text__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    line_of_business__c: "Home",
    quote_response_rquid__c: "7c8d7999-799f-4f6d-8c53-38f9400bdda4",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Quote Successful",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Branch_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Branch-Home",
    error_messages__c: "Quote Successful",
    extended_status__c: "SUCCESS",
    remarks__c: "Quote Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    company_url__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "4c2abeab-3981-4a25-962d-090401fea641",
    term__c: "12",
    aop_deductible__c: "0.02",
    w_h_deductible__c: "0.02",
    dwelling_limit__c: 731000,
    personal_property__c: 438600,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "73100",
    loss_of_use__c: "146200",
    liability__c: "100000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: "0.02",
    carrier_login__c: "a2g3c000008Hv6hAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: 2107.19,
    discounts__c:
      '{"connectedHome":0,"paperless":377.58000000000175,"discountInventoryScore":0}',
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: "RC1",
    error_responsibility__c: null,
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "SageSure",
    flood_zone__c: null,
    company_client_id__c: "507",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      '{\n  "timestamp": "2024-03-04T20:33:27.033+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "SageSure_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_New_QAP-No",
    carrier_number__c: null,
    name: "TaSageSure-Home",
    error_messages__c:
      '{\n  "timestamp": "2024-03-04T20:33:27.033+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    extended_status__c: null,
    remarks__c:
      '{\n  "timestamp": "2024-03-04T20:33:27.033+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    marketing_messages__c: null,
    company_url_long__c: "https://agents.sagesure.com/",
    company_url__c: "https://agents.sagesure.com/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "c298ac3e-0135-4cd6-81ba-8cd508fba056",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Carrier Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Mulesoft",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Lemonade",
    flood_zone__c: null,
    company_client_id__c: "998",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "You must input an email for the insured to receive a quote from Lemonade",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Lemonade_HOME_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Lemonade-Home",
    error_messages__c:
      "You must input an email for the insured to receive a quote from Lemonade",
    extended_status__c: null,
    remarks__c:
      "You must input an email for the insured to receive a quote from Lemonade",
    marketing_messages__c: null,
    company_url_long__c: "https://dashboard.lemonade.com",
    company_url__c: "https://dashboard.lemonade.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "f5754741-2aa3-4ae9-9ec0-fd03041e616d",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000004mbDuAAI",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Invalid Input",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Mercury",
    flood_zone__c: null,
    company_client_id__c: "18",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/mercury/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Mercury_Insurance_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Mercury-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/mercury/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://www.mercuryfirst.com",
    company_url__c: "https://www.mercuryfirst.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "628d76ce-a68c-4041-8ed7-3a8b82592704",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5OsIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "SageSure",
    flood_zone__c: null,
    company_client_id__c: "507",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      '{\n  "timestamp": "2024-03-04T20:33:28.896+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "SageSure_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_New_QAP-Yes",
    carrier_number__c: null,
    name: "TaSageSure-Home",
    error_messages__c:
      '{\n  "timestamp": "2024-03-04T20:33:28.896+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    extended_status__c: null,
    remarks__c:
      '{\n  "timestamp": "2024-03-04T20:33:28.896+00:00",\n  "status": 404,\n  "error": "Not Found",\n  "path": "/api/rest/v3/quotes/CRU4Q-2144732/alternative-quotes"\n}',
    marketing_messages__c: null,
    company_url_long__c: "https://agents.sagesure.com/",
    company_url__c: "https://agents.sagesure.com/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "4ef4905f-371f-4c7d-b396-b2994974a711",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TKIuAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Carrier Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Mulesoft",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "National General",
    flood_zone__c: null,
    company_client_id__c: "201",
    company_quote_number__c: null,
    company_url_text__c: "https://www.natgenagency.com/",
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "State not found Please enter this quote on the Carrier website.",
    rquid__c: null,
    status__c: "REJECTED",
    quote_response_external_id__c:
      "National_General_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-National General-Home",
    error_messages__c:
      "National General is experiencing technical difficulties, please try again on their portal.",
    extended_status__c:
      "State not found Please enter this quote on the Carrier website.",
    remarks__c:
      "State not found Please enter this quote on the Carrier website.",
    marketing_messages__c: null,
    company_url_long__c: "https://www.natgenagency.com/",
    company_url__c: "https://www.natgenagency.com/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "3fcd736b-f7b0-402a-956f-078971764728",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H8TUIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Quote Directly in Carrier",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Foremost",
    flood_zone__c: null,
    company_client_id__c: "999",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "This risk was declined due to location.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Foremost_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Foremost-Home",
    error_messages__c: "This risk was declined due to location.",
    extended_status__c: null,
    remarks__c: "This risk was declined due to location.",
    marketing_messages__c: null,
    company_url_long__c: "http://www.foremoststar.com",
    company_url__c: "http://www.foremoststar.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "8bf765e3-35fc-4667-bf38-e856fa047a3c",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000000ZIFpAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Location Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Homeowners of America",
    flood_zone__c: null,
    company_client_id__c: "113",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Please quote on carrier site",
    rquid__c: null,
    status__c: "REJECTED",
    quote_response_external_id__c:
      "HOAIC_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Homeowners of America-Home",
    error_messages__c: "Please quote on carrier site",
    extended_status__c: null,
    remarks__c: "Please quote on carrier site",
    marketing_messages__c: null,
    company_url_long__c: "https://hoaic60.live.ptsapp.com/logIn.cfm",
    company_url__c: "https://hoaic60.live.ptsapp.com/logIn.cfm",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "5b0c6cfe-3cf9-40fd-80f2-e66324c0e79b",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5jrIAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Quote Directly in Carrier",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Cypress",
    flood_zone__c: null,
    company_client_id__c: "168",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/cypress/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Cypress_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Cypress-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/cypress/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "cypress.cogisi.com",
    company_url__c: "cypress.cogisi.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "bd9dcb64-2643-43df-8223-91a6d687388d",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H59PIAS",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Wright Flood",
    flood_zone__c: "",
    company_client_id__c: "629",
    company_quote_number__c: "QT10122503",
    company_url_text__c:
      "https://sqa.wrightflood.net/FloodPortal/flood/goToFlood?token=acc44c2c-05a1-41ee-b9a8-575a08a9222c&quote=42QT10122503",
    line_of_business__c: "Flood",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Amount Entered Does Not Support Estimated Replacement Cost  Requirements.  Please Increase Value to a Minimum of $100 per square  foot.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "WrightFloodPFA_Insurance_Flood_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-WrightFlood-Flood",
    error_messages__c:
      "This quote was not able to be rated by the carrier. Please submit feedback and your Production Support team will be notified",
    extended_status__c: null,
    remarks__c:
      "Amount Entered Does Not Support Estimated Replacement Cost  Requirements.  Please Increase Value to a Minimum of $100 per square  foot.",
    marketing_messages__c: null,
    company_url_long__c:
      "https://sqa.wrightflood.net/FloodPortal/flood/goToFlood?token=acc44c2c-05a1-41ee-b9a8-575a08a9222c&quote=42QT10122503",
    company_url__c: "https://sqa.wrightflood.net/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "8e055eed-315c-40f5-980c-753ac1583476",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: null,
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Invalid Input",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Homeowners of America",
    flood_zone__c: null,
    company_client_id__c: "113",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Please quote on carrier site",
    rquid__c: null,
    status__c: "REJECTED",
    quote_response_external_id__c:
      "HOAIC_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Homeowners of America-Home",
    error_messages__c: "Please quote on carrier site",
    extended_status__c: null,
    remarks__c: "Please quote on carrier site",
    marketing_messages__c: null,
    company_url_long__c: "https://hoaic60.live.ptsapp.com/logIn.cfm",
    company_url__c: "https://hoaic60.live.ptsapp.com/logIn.cfm",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "6257dd2e-580a-4f22-a919-e9336b135b23",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5jrIAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Quote Directly in Carrier",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Wright Flood NFIP Insurance",
    flood_zone__c: null,
    company_client_id__c: "629",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Flood",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "(Internal) Email is required",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "WrightFloodNFIP_Insurance_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-WrightFloodNFIP-Home",
    error_messages__c:
      "This quote was not able to be rated by the carrier. Please submit feedback and your Production Support team will be notified",
    extended_status__c: null,
    remarks__c: "(Internal) Email is required",
    marketing_messages__c: null,
    company_url_long__c: "https://www.wrightflood.net/Portal/",
    company_url__c: "https://www.wrightflood.net/Portal/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "70403681-3add-403c-b19c-11412209a160",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC00000008HLlIAM",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Invalid Input",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "National General",
    flood_zone__c: null,
    company_client_id__c: "201",
    company_quote_number__c: null,
    company_url_text__c: "https://www.natgenagency.com/",
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "State not found Please enter this quote on the Carrier website.",
    rquid__c: null,
    status__c: "REJECTED",
    quote_response_external_id__c:
      "National_General_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-National General-Home",
    error_messages__c:
      "National General is experiencing technical difficulties, please try again on their portal.",
    extended_status__c:
      "State not found Please enter this quote on the Carrier website.",
    remarks__c:
      "State not found Please enter this quote on the Carrier website.",
    marketing_messages__c: null,
    company_url_long__c: "https://www.natgenagency.com/",
    company_url__c: "https://www.natgenagency.com/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "a197c3a9-d1af-4ac1-a519-af5db6fa56fe",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H8TUIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Quote Directly in Carrier",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Foremost Signature-Farmers Infrastructure",
    flood_zone__c: null,
    company_client_id__c: "479",
    company_quote_number__c: "HVQ1000563864",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "cfc2c986-fb7d-4185-b4fc-ae9d27f6032d",
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Guaranteed Rebuild for Buildings\n M-Def",
    rquid__c: "cfc2c986-fb7d-4185-b4fc-ae9d27f6032d",
    status__c: "Error",
    quote_response_external_id__c:
      "Foremost Signature_Home_Farmers_Infrastructure_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Foremost Signature-Home",
    error_messages__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Guaranteed Rebuild for Buildings\n M-Def",
    extended_status__c: null,
    remarks__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Guaranteed Rebuild for Buildings\n M-Defaulted COVB Building Property Loss Settlement to Guaranteed Rebuild for Buildings\n M-Defaulted Prior Carrier to Other\n M-Defaulted Education to College Degree\n M-Defaulted Replacement Cost to 100%\n M-Defaulted Occupation Type to Other\n M-Defaulted Do you occupy the dwelling full time to True\n M-Defaulted Personal Property Loss Settlement to Replacement Cost on Contents\n M-Defaulted Galvanized or PB Plumbing to False\n M-Defaulted Payment Schedule to Pay in Full\n M-Defaulted Oil Tank to False\n M-Defaulted Cov A Coinsurance to 80%\n M-Defaulted Roof Loss Settlement to Replacement Cost\n M-Defaulted Payment Method to Bill Mortgagee\n M-Defaulted SPP Loss Settlement to Agreed Value",
    marketing_messages__c: null,
    company_url_long__c: "www.agentautohome.com",
    company_url__c: "www.agentautohome.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "f2c38eb8-f948-4169-84d0-592cae2e1bfe",
    term__c: "12",
    aop_deductible__c: "1.0",
    w_h_deductible__c: "2.0",
    dwelling_limit__c: 479520,
    personal_property__c: 359640,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "95904",
    loss_of_use__c: "191808",
    liability__c: "100000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5R5IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Invalid Input",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 1000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Cypress",
    flood_zone__c: null,
    company_client_id__c: "168",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/cypress/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Cypress_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Cypress-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/cypress/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "cypress.cogisi.com",
    company_url__c: "cypress.cogisi.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "85144ba0-e8f0-41f2-839f-2b8a6e2e2b04",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H59PIAS",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Safeco",
    flood_zone__c: null,
    company_client_id__c: "35",
    company_quote_number__c: "607ebe23-a521-4beb-836e-66bae681f1b7",
    company_url_text__c: "http://www.safeconow.com",
    line_of_business__c: "Home",
    quote_response_rquid__c: "427da543-380e-43e2-a809-8ec7425884d1",
    quote_summary_url_text__c: "Bridge to Quote",
    remarks_truncated__c:
      "We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)-Rejected-We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic loss",
    rquid__c: "37519e77-2ba7-49aa-9627-e57d644deb93",
    status__c: "Rejected",
    quote_response_external_id__c:
      "Safeco_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Safeco-Home",
    error_messages__c: "This risk was declined by Safeco due to location.",
    extended_status__c: "",
    remarks__c:
      "We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)-Rejected-We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)",
    marketing_messages__c: "\n\n\n\n\n\n\n\n\n\n\n\n\n",
    company_url_long__c: "http://www.safeconow.com",
    company_url__c: "http://www.safeconow.com",
    quote_summary_url__c:
      "https://safesite.qa.safeco.com/tac/Consumer/Home/ReportDisplay.aspx?PolicyGuid=607ebe23-a521-4beb-836e-66bae681f1b7&ReportName=QuoteSummary",
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "a0fb7bed-1e67-4866-935a-df506d5c1709",
    term__c: "12",
    aop_deductible__c: "1%",
    w_h_deductible__c: "$5000",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5m4IAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Location Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Safeco",
    flood_zone__c: null,
    company_client_id__c: "35",
    company_quote_number__c: "607ebe23-a521-4beb-836e-66bae681f1b7",
    company_url_text__c: "http://www.safeconow.com",
    line_of_business__c: "Home",
    quote_response_rquid__c: "d79299cc-1ff6-424a-b7c2-538996c2497f",
    quote_summary_url_text__c: "Bridge to Quote",
    remarks_truncated__c:
      "We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)-Rejected-We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic loss",
    rquid__c: "16d7505d-592a-44d1-bb61-48f9ea2940f5",
    status__c: "Rejected",
    quote_response_external_id__c:
      "Safeco_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Safeco-Home",
    error_messages__c: "This risk was declined by Safeco due to location.",
    extended_status__c: "",
    remarks__c:
      "We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)-Rejected-We are unable to place this policy in a Safeco Market for the following reason:  Exposure to catastrophic losses at the location. (206)",
    marketing_messages__c: "\n\n\n\n\n\n\n\n\n\n\n\n\n",
    company_url_long__c: "http://www.safeconow.com",
    company_url__c: "http://www.safeconow.com",
    quote_summary_url__c:
      "https://safesite.qa.safeco.com/tac/Consumer/Home/ReportDisplay.aspx?PolicyGuid=607ebe23-a521-4beb-836e-66bae681f1b7&ReportName=QuoteSummary",
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "f5380575-18ce-4949-961f-a80308cc399a",
    term__c: "12",
    aop_deductible__c: "1%",
    w_h_deductible__c: "$5000",
    dwelling_limit__c: 480000,
    personal_property__c: 288000,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "48000",
    loss_of_use__c: "96000",
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5m4IAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Location Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 5000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Mercury",
    flood_zone__c: null,
    company_client_id__c: "18",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/mercury/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Mercury_Insurance_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Mercury-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/mercury/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://www.mercuryfirst.com",
    company_url__c: "https://www.mercuryfirst.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "fa48e17d-b586-4bfc-a9a9-2efc6d97af33",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5OsIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Foremost Signature-Farmers Infrastructure",
    flood_zone__c: null,
    company_client_id__c: "479",
    company_quote_number__c: "HVQ1000563865",
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: "fcd2dbea-0afd-4b92-a2db-c59d58ce8831",
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Blanket Property Guaranteed Rebuild\n M-",
    rquid__c: "fcd2dbea-0afd-4b92-a2db-c59d58ce8831",
    status__c: "Error",
    quote_response_external_id__c:
      "Foremost Signature_Home_Farmers_Infrastructure_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Foremost Signature-Home",
    error_messages__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Blanket Property Guaranteed Rebuild\n M-",
    extended_status__c: null,
    remarks__c:
      "Farmers-Infrastructure-CAPSUAT: ExtendedStatusCd - DataInvalid, ExtendedStatusDesc - Foremost Insurance unable to provide quote due to Technical error in Rating\n M-Defaulted COVA Building Property Loss Settlement to Blanket Property Guaranteed Rebuild\n M-Defaulted COVB Building Property Loss Settlement to Blanket Property Guaranteed Rebuild\n M-Defaulted Prior Carrier to Other\n M-Defaulted Education to College Degree\n M-Defaulted Replacement Cost to 100%\n M-Defaulted Occupation Type to Other\n M-Defaulted Do you occupy the dwelling full time to True\n M-Defaulted Personal Property Loss Settlement to Blanket Property Replacement Cost\n M-Defaulted Galvanized or PB Plumbing to False\n M-Defaulted Payment Schedule to Pay in Full\n M-Defaulted Oil Tank to False\n M-Defaulted Cov A Coinsurance to 80%\n M-Defaulted Roof Loss Settlement to Replacement Cost\n M-Defaulted Payment Method to Bill Mortgagee\n M-Defaulted SPP Loss Settlement to Agreed Value",
    marketing_messages__c: null,
    company_url_long__c: "www.agentautohome.com",
    company_url__c: "www.agentautohome.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "0760d917-dff1-46d6-9a67-33b59d017df8",
    term__c: "12",
    aop_deductible__c: "1.0",
    w_h_deductible__c: "2.0",
    dwelling_limit__c: 479520,
    personal_property__c: 359640,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: "119880",
    loss_of_use__c: null,
    liability__c: "500000",
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5R5IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Invalid Input",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: 1000,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Foremost",
    flood_zone__c: null,
    company_client_id__c: "999",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "This risk was declined due to location.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Foremost_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Foremost-Home",
    error_messages__c: "This risk was declined due to location.",
    extended_status__c: null,
    remarks__c: "This risk was declined due to location.",
    marketing_messages__c: null,
    company_url_long__c: "http://www.foremoststar.com",
    company_url__c: "http://www.foremoststar.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "d2e9c303-3e8c-4a15-a4fb-57f36efa5ca4",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000000ZIFpAAO",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Location Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Nationwide",
    flood_zone__c: null,
    company_client_id__c: "447",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP PUT on resource 'https://da-integrations-dev.goosehead.com:443/dev-postgres-sys/api/v1/carriers/quotes/responses' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Nationwide_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-No",
    carrier_number__c: null,
    name: "Ta-Nationwide-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP PUT on resource 'https://da-integrations-dev.goosehead.com:443/dev-postgres-sys/api/v1/carriers/quotes/responses' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    company_url__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "364726fb-dc97-496d-929d-ddc660f7f271",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC00000099MW7IAM",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Travelers",
    flood_zone__c: null,
    company_client_id__c: "13",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/travelers/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Travelers_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Travelers-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/travelers/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://logon.travelers.com/TravelersLogin.asp",
    company_url__c: "https://logon.travelers.com/TravelersLogin.asp",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "064ecf45-354b-4eac-884f-7c64eae0895d",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5XiIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Progressive",
    flood_zone__c: null,
    company_client_id__c: "25",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Home",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/progressive/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "ASI_Home_943d8a41-0e30-4c00-9af9-fcff7e043456_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-ASI-Home",
    error_messages__c:
      "Your login is either not valid or is not set up with this carrier. Please check your credentials or reach out to Operations for assistance",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/home/progressive/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://www.foragentsonly.com",
    company_url__c: "https://www.foragentsonly.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "943d8a41-0e30-4c00-9af9-fcff7e043456",
    heroku_id: "9aabe3ff-6c21-4a0f-8550-e2d471f1571d",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H54zIAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Goosehead",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4180,
    premium_total__c: 3889.12,
    premium_total_ppm__c: 323.67,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Bristol West",
    flood_zone__c: null,
    company_client_id__c: "52",
    company_quote_number__c: "91C8FB03-F2D2-1004-85B4-34785166E291",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Transaction Successful",
    rquid__c: null,
    status__c: "Success",
    quote_response_external_id__c:
      "Bristol West_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta - Bristol - Auto",
    error_messages__c: "Transaction Successful",
    extended_status__c: "Success - Transaction Successful",
    remarks__c: "Transaction Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "http://www5.bwproducers.com/Producers/FMLogIn.aspx?reqProgram=ACC&mco=43&quoteId=Q02-3641963-00&sourceKey=91C8FB03-F2D2-1004-85B4-34785166E291&state=TX&ProducerCode=4299999&lob=APV",
    company_url__c:
      "http://www5.bwproducers.com/Producers/FMLogIn.aspx?reqProgram=ACC&mco=43&quoteId=Q02-3641963-00&sourceKey=91C8FB03-F2D2-1004-85B4-34785166E291&state=TX&ProducerCode=4299999&lob=APV",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "f9f5dec3-40f2-4955-bb2c-46dea3944c64",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5G2IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4180,
    premium_total__c: 3889.12,
    premium_total_ppm__c: 323.67,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Bristol West",
    flood_zone__c: null,
    company_client_id__c: "52",
    company_quote_number__c: "CEECB5FC-F2D2-1004-80E7-4C068854D036",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Transaction Successful",
    rquid__c: null,
    status__c: "Success",
    quote_response_external_id__c:
      "Bristol West_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta - Bristol - Auto",
    error_messages__c: "Transaction Successful",
    extended_status__c: "Success - Transaction Successful",
    remarks__c: "Transaction Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "http://www5.bwproducers.com/Producers/FMLogIn.aspx?reqProgram=ACC&mco=43&quoteId=Q02-3641962-00&sourceKey=CEECB5FC-F2D2-1004-80E7-4C068854D036&state=TX&ProducerCode=4299999&lob=APV",
    company_url__c:
      "http://www5.bwproducers.com/Producers/FMLogIn.aspx?reqProgram=ACC&mco=43&quoteId=Q02-3641962-00&sourceKey=CEECB5FC-F2D2-1004-80E7-4C068854D036&state=TX&ProducerCode=4299999&lob=APV",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "66a60237-ad7d-4f69-b6ac-b89cee628633",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5G2IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 3758,
    premium_total__c: 2180.85,
    premium_total_ppm__c: 363.48,
    effective_date__c: "2024-03-11",
    request_date__c: "2024-03-04",
    carrier__c: "Branch",
    flood_zone__c: null,
    company_client_id__c: "994",
    company_quote_number__c: "c59e4059-5122-4abc-9922-f32711646e75",
    company_url_text__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    line_of_business__c: "Auto",
    quote_response_rquid__c: "75c27b52-14ef-4a8c-8b1e-d00d3aaf8095",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Quote Successful",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Branch_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Branch-Auto",
    error_messages__c: "Quote Successful",
    extended_status__c: "SUCCESS",
    remarks__c: "Quote Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    company_url__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "6d6a0fa3-1381-4157-a11e-ebd2e106cb8f",
    term__c: "6",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008Hv6hAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: 913.18,
    discounts__c:
      '{"bundleDiscount":469,"paperless":97,"discountDriverScore":false}',
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: "RC1",
    error_responsibility__c: null,
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4357.06,
    premium_total__c: 2178.53,
    premium_total_ppm__c: 409.84,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Clearcover",
    flood_zone__c: null,
    company_client_id__c: "532",
    company_quote_number__c: "Q0000-0273-4100",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: "Q0000-0273-4100",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "SUCCESS - 6 Month",
    rquid__c: "30d93952-8595-46d4-807b-849f5adf3ce3",
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Clearcover_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta - Clearcover - Auto",
    error_messages__c: "SUCCESS - 6 Month",
    extended_status__c: null,
    remarks__c: "SUCCESS - 6 Month",
    marketing_messages__c: null,
    company_url_long__c:
      "https://apply.clearcoverpartner.com/ad8adfd4-1447-4745-bdc8-0a1c5c8f3963",
    company_url__c:
      "https://apply.clearcoverpartner.com/ad8adfd4-1447-4745-bdc8-0a1c5c8f3963",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "ac72768c-f001-47d0-b2b6-7785b2408827",
    term__c: "6",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TgL8AAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: "9afa32a9-ac51-400f-b488-101fc4ec9a6f",
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 4574,
    premium_total__c: 2650.05,
    premium_total_ppm__c: 441.68,
    effective_date__c: "2024-03-11",
    request_date__c: "2024-03-04",
    carrier__c: "Branch",
    flood_zone__c: null,
    company_client_id__c: "994",
    company_quote_number__c: "c59e4059-5122-4abc-9922-f32711646e75",
    company_url_text__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    line_of_business__c: "Auto",
    quote_response_rquid__c: "99d901f7-1785-432a-afcc-a792570da0e2",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Quote Successful",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Branch_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Branch-Auto",
    error_messages__c: "Quote Successful",
    extended_status__c: "SUCCESS",
    remarks__c: "Quote Successful",
    marketing_messages__c: null,
    company_url_long__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    company_url__c:
      "https://www.ourbranch.com/staff/offer/c59e4059-5122-4abc-9922-f32711646e75",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "9c898a94-b65f-4dd6-b9dd-1d2b61d55037",
    term__c: "6",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008Hv6hAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: 1110.38,
    discounts__c: '{"paperless":97,"discountDriverScore":false}',
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: "RC1",
    error_responsibility__c: null,
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 6053,
    premium_total__c: 6053,
    premium_total_ppm__c: 504.41,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "National General",
    flood_zone__c: null,
    company_client_id__c: "201",
    company_quote_number__c: "564393",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: "c2900583-9ad3-486a-bd94-105c1abb29fa",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Success - 12 Month",
    rquid__c: "c2900583-9ad3-486a-bd94-105c1abb29fa",
    status__c: "Success",
    quote_response_external_id__c:
      "Natgen_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Natgen-Auto",
    error_messages__c: "Success - 12 Month",
    extended_status__c: "Success",
    remarks__c: "Success - 12 Month",
    marketing_messages__c: null,
    company_url_long__c:
      "https://devpl.nationalgeneral.com/RC/PLGateway.aspx?bridge=kv3V8lfDOBs-5k5M0GDFzrJyV54hNoQHXTn9685HiZnxDh2oiNINBjPQfaN2MOwC2vx3IGyZ0SCuGSFryYmHWZleLPHJp5in0",
    company_url__c:
      "https://devpl.nationalgeneral.com/RC/PLGateway.aspx?bridge=kv3V8lfDOBs-5k5M0GDFzrJyV54hNoQHXTn9685HiZnxDh2oiNINBjPQfaN2MOwC2vx3IGyZ0SCuGSFryYmHWZleLPHJp5in0",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "5375e072-0526-49c2-81f9-ed4469f9e9b0",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H8TUIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 6053,
    premium_total__c: 6053,
    premium_total_ppm__c: 504.41,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "National General",
    flood_zone__c: null,
    company_client_id__c: "201",
    company_quote_number__c: "564392",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: "051c800d-58ef-4dad-996b-82f208079d8e",
    quote_summary_url_text__c: null,
    remarks_truncated__c: "Success - 12 Month",
    rquid__c: "051c800d-58ef-4dad-996b-82f208079d8e",
    status__c: "Success",
    quote_response_external_id__c:
      "Natgen_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Natgen-Auto",
    error_messages__c: "Success - 12 Month",
    extended_status__c: "Success",
    remarks__c: "Success - 12 Month",
    marketing_messages__c: null,
    company_url_long__c:
      "https://devpl.nationalgeneral.com/RC/PLGateway.aspx?bridge=kv3V8lfDOBs-5k5M0GDFzrJyV54hNoQHXTn9685HiZn4-hPiy-kBrnWzeYYlMyzzo1pPFvSQZU-6tBJNQ_LFw_UndiSsOZsd0",
    company_url__c:
      "https://devpl.nationalgeneral.com/RC/PLGateway.aspx?bridge=kv3V8lfDOBs-5k5M0GDFzrJyV54hNoQHXTn9685HiZn4-hPiy-kBrnWzeYYlMyzzo1pPFvSQZU-6tBJNQ_LFw_UndiSsOZsd0",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "331e9cd2-9c3a-481c-8ea1-9355988323a8",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H8TUIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 7947,
    premium_total__c: 7947,
    premium_total_ppm__c: 662.25,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Foremost Signature-Farmers Infrastructure",
    flood_zone__c: null,
    company_client_id__c: "479",
    company_quote_number__c: "HVQ1000563863",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,(PC) At least one driver listed as married/civil union with no spouse identified.,Product Offering is Enhanced Plus,PFM ",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Foremost Signature_Auto_Farmers_Infrastructure_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Foremost Signature-Farmers Infrastructure-Auto",
    error_messages__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,(PC) At least one driver listed as married/civil union with no spouse identified.,Product Offering is Enhanced Plus,PFM ",
    extended_status__c: null,
    remarks__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,(PC) At least one driver listed as married/civil union with no spouse identified.,Product Offering is Enhanced Plus,PFM Level is NK,Collision Symbol is 21 for Veh1,Comp Symbol is 15 for Veh1,Good Driving Discount applied for $762.19,Paperless Discount applied for $10,Bundle Discount applied for $160.31,Home Ownership Discount applied for $328.89,Payment Plan Discount applied for $172.92,Length of Time at Current Residence Discount applied for $39.75,Early Quoting Discount applied for $240.10,Multi-Policy Discount applied for $694.81M-Bumped Roadside Assistance Limit coverage limit from 120 to 200 for 2014 NISSAN SENTRA in Texas \n M-Bumped Rental Car Reimbursement Limit coverage limit from 30/900 to 40/1200 for 2014 NISSAN SENTRA in Texas \n D-Underinsured Motorists - Bodily Injury Limit each person / each accident covg removed from quote due to Product Model limitations. Personal Auto Line\n M-Defaulted Vehicle Purchase Dt to 2014-01-01\n M-Defaulted Prior Policy Exp Date to EffectiveDate\n M-Defaulted Paperless Ind to Electronic\n M-Defaulted Annual Miles to 11500\n M-Defaulted Age First Licensed to 16\n M-Defaulted Age First Licensed to 16\n M-Defaulted Years At Current Residence to 3\n M-Defaulted Payment Schedule to Pay by Installments\n M-Defaulted Parking Type to Off-Street\n M-Defaulted Driver Address Validation to True\n M-Defaulted Driver Address Validation to True\n M-Defaulted Vehicle History Score to 550\n M-Defaulted Payment Method to Bank Account\n M-Defaulted Vehicle Use to school_or_work_ml",
    marketing_messages__c: null,
    company_url_long__c:
      "https://fwsia.oktapreview.com/app/fwsia_guidewirecloud_1/exk3m7ez89hipdU371d7/sso/saml?RelayState=https://producer-engage-farmers-fwsdev-capsuat.dev-1.us-east-1.guidewire.net/producer-engage/hvq/100662070",
    company_url__c:
      "https://fwsia.oktapreview.com/app/fwsia_guidewirecloud_1/exk3m7ez89hipdU371d7/sso/saml?RelayState=https://producer-engage-farmers-fwsdev-capsuat.dev-1.us-east-1.guidewire.net/producer-engage/hvq/100662070",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "7159ee15-d936-4636-8c24-ded2faf760c3",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5R5IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: 9230.87,
    premium_total__c: 9230.87,
    premium_total_ppm__c: 769.24,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Foremost Signature-Farmers Infrastructure",
    flood_zone__c: null,
    company_client_id__c: "479",
    company_quote_number__c: "HVQ1000563862",
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,Preliminary review indicates this risk may fall outside of underwriting guidelines.,(PC) At least one driver listed as m",
    rquid__c: null,
    status__c: "SUCCESS",
    quote_response_external_id__c:
      "Foremost Signature_Auto_Farmers_Infrastructure_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Foremost Signature-Farmers Infrastructure-Auto",
    error_messages__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,Preliminary review indicates this risk may fall outside of underwriting guidelines.,(PC) At least one driver listed as m",
    extended_status__c: null,
    remarks__c:
      "Farmers-Infrastructure-CAPSUAT: Due to third party data that will be ordered on the carrier portal, the premium may vary once bridged.,Preliminary review indicates this risk may fall outside of underwriting guidelines.,(PC) At least one driver listed as married/civil union with no spouse identified.,Product Offering is GrandProtect®,PFM Level is NK,Collision Symbol is 21 for Veh1,Comp Symbol is 15 for Veh1,Coverage Code COMP Safety Glass Deductible Waiver included.,Good Driving Discount applied for $887.46,Paperless Discount applied for $10,Payment Plan Discount applied for $203.60,Home Ownership Discount applied for $384.77,Early Quoting Discount applied for $279.41,Length of Time at Current Residence Discount applied for $45.71M-Bumped Roadside Assistance Limit coverage limit from 120 to 200 for 2014 NISSAN SENTRA in Texas \n M-Bumped Rental Car Reimbursement Limit coverage limit from 30/900 to 40/1200 for 2014 NISSAN SENTRA in Texas \n D-Underinsured Motorists - Bodily Injury Limit each person / each accident covg removed from quote due to Product Model limitations. Personal Auto Line\n M-Defaulted Vehicle Purchase Dt to 2014-01-01\n M-Defaulted Prior Policy Exp Date to EffectiveDate\n M-Defaulted Paperless Ind to Electronic\n M-Defaulted Annual Miles to 11500\n M-Defaulted Age First Licensed to 16\n M-Defaulted Age First Licensed to 16\n M-Defaulted Years At Current Residence to 3\n M-Defaulted Payment Schedule to Pay by Installments\n M-Defaulted Parking Type to Off-Street\n M-Defaulted Driver Address Validation to True\n M-Defaulted Driver Address Validation to True\n M-Defaulted Vehicle History Score to 550\n M-Defaulted Payment Method to Bank Account\n M-Defaulted Vehicle Use to school_or_work_ml",
    marketing_messages__c: null,
    company_url_long__c:
      "https://fwsia.oktapreview.com/app/fwsia_guidewirecloud_1/exk3m7ez89hipdU371d7/sso/saml?RelayState=https://producer-engage-farmers-fwsdev-capsuat.dev-1.us-east-1.guidewire.net/producer-engage/hvq/100662068",
    company_url__c:
      "https://fwsia.oktapreview.com/app/fwsia_guidewirecloud_1/exk3m7ez89hipdU371d7/sso/saml?RelayState=https://producer-engage-farmers-fwsdev-capsuat.dev-1.us-east-1.guidewire.net/producer-engage/hvq/100662068",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "7d73818e-0e84-4f92-9e57-c19601194c33",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5R5IAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Success",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Safeco Insurance",
    flood_zone__c: null,
    company_client_id__c: "35",
    company_quote_number__c: "8d7b3483-15f3-4bec-93bf-154a3b627fdf",
    company_url_text__c: "This is a link to the quote at the Safeco web site.",
    line_of_business__c: "Auto",
    quote_response_rquid__c: "3c6db08a-af5b-4fab-be67-7a1a763f53e3",
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefor",
    rquid__c: "e0c2e975-747a-47bd-adb0-dc8bd9bd8a8f",
    status__c: "Rejected",
    quote_response_external_id__c:
      "Safeco_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Safeco-Auto",
    error_messages__c:
      "Safeco has determined that you did not meet their eligibility requirements",
    extended_status__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefore they were unable to provide you with the automobile insurance quote you requested.",
    remarks__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefore they were unable to provide you with the automobile insurance quote you requested.-GD-399Rejected - NoHit",
    marketing_messages__c: "",
    company_url_long__c:
      "https://safesite.qa.safeco.com/client/tacrc/Client/OpenActivity.aspx?l=o&p=8d7b3483-15f3-4bec-93bf-154a3b627fdf",
    company_url__c:
      "https://safesite.qa.safeco.com/client/tacrc/Client/OpenActivity.aspx?l=o&p=8d7b3483-15f3-4bec-93bf-154a3b627fdf",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "20f46f51-7fe6-4d7d-95cd-b11df258e5d8",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5m4IAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Driver Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: "2024-03-04",
    carrier__c: "Safeco Insurance",
    flood_zone__c: null,
    company_client_id__c: "35",
    company_quote_number__c: "5ef56507-5e9d-4705-b7da-c661ade2bd13",
    company_url_text__c: "This is a link to the quote at the Safeco web site.",
    line_of_business__c: "Auto",
    quote_response_rquid__c: "d6803f91-75e8-411a-8eb3-649dc038d151",
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefor",
    rquid__c: "3fb93a15-364c-4707-ab71-9fd8e4dd16c4",
    status__c: "Rejected",
    quote_response_external_id__c:
      "Safeco_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Safeco-Auto",
    error_messages__c:
      "Safeco has determined that you did not meet their eligibility requirements",
    extended_status__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefore they were unable to provide you with the automobile insurance quote you requested.",
    remarks__c:
      "The process of preparing a quote includes a review of many factors, such as your driving record, loss history and other information you provided. Based on this review, Safeco has determined that you did not meet their eligibility requirements and therefore they were unable to provide you with the automobile insurance quote you requested.-GD-399Rejected - NoHit",
    marketing_messages__c: "",
    company_url_long__c:
      "https://safesite.qa.safeco.com/client/tacrc/Client/OpenActivity.aspx?l=o&p=5ef56507-5e9d-4705-b7da-c661ade2bd13",
    company_url__c:
      "https://safesite.qa.safeco.com/client/tacrc/Client/OpenActivity.aspx?l=o&p=5ef56507-5e9d-4705-b7da-c661ade2bd13",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "18549fc4-7375-4c42-abe4-86d4b34fff83",
    term__c: "12",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5m4IAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Driver Ineligible",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Stillwater",
    flood_zone__c: null,
    company_client_id__c: "404",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Exception : Timeout waiting to read transaction Rtrn_SessDta in queue FRIQ132Q for RqUID CE003696-9B96-4EEC-AC21-A105FB3D68D6 | Transaction: Rtrn_SessDta | Record Format: FRIQ120SES",
    rquid__c: null,
    status__c: "Error",
    quote_response_external_id__c:
      "Stillwater_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Stillwater-Auto",
    error_messages__c:
      "Stillwater is experiencing technical difficulties, please try again later.",
    extended_status__c: "Error",
    remarks__c:
      "Exception : Timeout waiting to read transaction Rtrn_SessDta in queue FRIQ132Q for RqUID CE003696-9B96-4EEC-AC21-A105FB3D68D6 | Transaction: Rtrn_SessDta | Record Format: FRIQ120SES",
    marketing_messages__c: null,
    company_url_long__c: "https://stillwaterinsurance.com/SalesPortal/login",
    company_url__c: "https://stillwaterinsurance.com/SalesPortal/login",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "58234c52-9413-4f71-a7f7-1a0ebc9c58a4",
    term__c: "",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TeowAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Carrier Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Mercury",
    flood_zone__c: null,
    company_client_id__c: "17",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Mercury_Insurance_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta -Mercury-Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    marketing_messages__c: null,
    company_url_long__c: "",
    company_url__c: "",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "fcd92a9b-e50a-46b9-a448-eebba3959705",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5OsIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Nationwide",
    flood_zone__c: null,
    company_client_id__c: "447",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Nationwide_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Nationwide-Auto",
    error_messages__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    extended_status__c: null,
    remarks__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    marketing_messages__c: null,
    company_url_long__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    company_url__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "65978415-3222-4f0e-94d1-a39178139fe6",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: null,
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Ineligible/Approval Needed",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Progressive",
    flood_zone__c: null,
    company_client_id__c: "25",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/progressive/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Progressive_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta - Progressive - Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/progressive/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://www.foragentsonly.com",
    company_url__c: "https://www.foragentsonly.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "d29bf5cd-cfd1-490d-9058-d8ac90dc16a6",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5TIIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Elephant",
    flood_zone__c: null,
    company_client_id__c: "574",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "<!DOCTYPE html PUBLIC '-//W3C//DTD XHTML 1.0 Transitional//EN' 'http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd'>\n<html xmlns='http://www.w3.org/1999/xhtml'>\n\n<head>\n    <meta content='text/html; charset=utf-8' http-equiv='content-type' />\n    <st",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Elephant_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Elephant-Auto",
    error_messages__c:
      "This risk was declined due to carrier underwriting guidelines.",
    extended_status__c: null,
    remarks__c:
      "<!DOCTYPE html PUBLIC '-//W3C//DTD XHTML 1.0 Transitional//EN' 'http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd'>\n<html xmlns='http://www.w3.org/1999/xhtml'>\n\n<head>\n    <meta content='text/html; charset=utf-8' http-equiv='content-type' />\n    <style type='text/css'>\n        body {\n            font-family: Arial;\n            margin-left: 40px;\n        }\n\n        img {\n            border: 0 none;\n        }\n\n        #content {\n            margin-left: auto;\n            margin-right: auto\n        }\n\n        #message h2 {\n            font-size: 20px;\n            font-weight: normal;\n            color: #000000;\n            margin: 34px 0px 0px 0px\n        }\n\n        #message p {\n            font-size: 13px;\n            color: #000000;\n            margin: 7px 0px 0px0px\n        }\n\n        #errorref {\n            font-size: 11px;\n            color: #737373;\n            margin-top: 41px\n        }\n    </style>\n    <title>Service unavailable</title>\n</head>\n\n<body>\n    <div id='content'>\n        <div id='message'>\n            <h2>The request is blocked.</h2>\n        </div>\n        <div id='errorref'>\n            <span>20240304T203324Z-xhebe360m12sbahbzbgc09h0qw00000001b0000000009tzg            </span>\n        </div>\n    </div>\n</body>\n</html>\n",
    marketing_messages__c: null,
    company_url_long__c: "https://agencynew.elephant.com/",
    company_url__c: "https://agencynew.elephant.com/",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "fc8a4dc4-5513-49db-a719-8cc68b3d3c32",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000009S50pAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Ineligible/Approval Needed",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Progressive",
    flood_zone__c: null,
    company_client_id__c: "25",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/progressive/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Progressive_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta - Progressive - Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/progressive/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://www.foragentsonly.com",
    company_url__c: "https://www.foragentsonly.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "c31c2b84-b011-4a41-ba1c-87c6945bdc1c",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5TIIA0",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Travelers",
    flood_zone__c: null,
    company_client_id__c: "13",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/travelers/quote' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Travelers_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-No",
    carrier_number__c: null,
    name: "Ta-Travelers-Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "HTTP POST on resource 'https://da-integrations-dev.goosehead.com:443/dev-carriers-sys/api/v1/auto/travelers/quote' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://logon.travelers.com/TravelersLogin.asp",
    company_url__c: "https://logon.travelers.com/TravelersLogin.asp",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "67ef6366-020f-4380-aee0-0807318ad32d",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5XiIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Stillwater",
    flood_zone__c: null,
    company_client_id__c: "404",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Exception : Timeout waiting to read transaction Rtrn_SessDta in queue FRIQ132Q for RqUID DEC180C6-A39A-4D91-8591-26A09A4D0485 | Transaction: Rtrn_SessDta | Record Format: FRIQ120SES",
    rquid__c: null,
    status__c: "Error",
    quote_response_external_id__c:
      "Stillwater_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Stillwater-Auto",
    error_messages__c:
      "Stillwater is experiencing technical difficulties, please try again later.",
    extended_status__c: "Error",
    remarks__c:
      "Exception : Timeout waiting to read transaction Rtrn_SessDta in queue FRIQ132Q for RqUID DEC180C6-A39A-4D91-8591-26A09A4D0485 | Transaction: Rtrn_SessDta | Record Format: FRIQ120SES",
    marketing_messages__c: null,
    company_url_long__c: "https://stillwaterinsurance.com/SalesPortal/login",
    company_url__c: "https://stillwaterinsurance.com/SalesPortal/login",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "2fe560b9-fe13-40e4-b57d-461bf28dce53",
    term__c: "",
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TeowAAC",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Carrier Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Mercury",
    flood_zone__c: null,
    company_client_id__c: "17",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Mercury_Insurance_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta -Mercury-Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    marketing_messages__c: null,
    company_url_long__c: "",
    company_url__c: "",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "9bcbb3ec-77d2-49bd-82dd-a02088ae5e69",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2gC0000008H5OsIAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Nationwide",
    flood_zone__c: null,
    company_client_id__c: "447",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Nationwide_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Nationwide-Auto",
    error_messages__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    extended_status__c: null,
    remarks__c:
      "Nationwide requires pre-quote documentation for this risk, and unfortunately did not return a rate.",
    marketing_messages__c: null,
    company_url_long__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    company_url__c: "https://agentcenter.nationwide.com/WorkspaceAC/login",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "9efd58fb-a394-4ae9-87ad-f155bdeb9252",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: null,
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Ineligible/Approval Needed",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
  {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: "2024-03-14",
    request_date__c: null,
    carrier__c: "Clearcover",
    flood_zone__c: null,
    company_client_id__c: "532",
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: "Auto",
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c:
      "HTTP PUT on resource 'https://da-integrations-dev.goosehead.com:443/dev-postgres-sys/api/v1/carriers/quotes/responses' failed: unauthorized (401).",
    rquid__c: null,
    status__c: "ERROR",
    quote_response_external_id__c:
      "Clearcover_Auto_ee2ba546-9fb0-44a9-808a-d53b30938f4e_QAP-Yes",
    carrier_number__c: null,
    name: "Ta-Clearcover-Auto",
    error_messages__c:
      "The User ID is invalid for this carrier. Please reach out to Operations to correct this.",
    extended_status__c: null,
    remarks__c:
      "HTTP PUT on resource 'https://da-integrations-dev.goosehead.com:443/dev-postgres-sys/api/v1/carriers/quotes/responses' failed: unauthorized (401).",
    marketing_messages__c: null,
    company_url_long__c: "https://agents.clearcover.com",
    company_url__c: "https://agents.clearcover.com",
    quote_summary_url__c: null,
    quote_request_heroku_id: "ee2ba546-9fb0-44a9-808a-d53b30938f4e",
    heroku_id: "1308f05f-ed42-41c0-bfd8-0113f062e384",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: true,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "a2g3c000008TgL8AAK",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Login Error",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: "Carrier",
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
  },
];

export const DUMMY_HOME_COVERAGES: HomeCoverages = {
  dwelling: 479000,
  personal_liability: 500000,
  wind: "2%",
  hurricane: null,
  aop: "1%",
  personal_property: 287712,
  suddenWaterDamage: "Yes",
  waterBackup: "Included",
  replacementDwelling: "Yes",
  replacementContents: "Yes",
  homeEffectiveDate: "2024-03-14",
  bodily_injury: "250/500",
  property_damage: "100000",
  uim: "250/500",
  uim_property_damage: "100000",
  pip: "2500",
  pipDeductible: null,
  pipType: null,
  wageLoss: null,
  pipLimit: null,
  pipStacking: false,
  medPay: "5000",
  tort: null,
  apip: null,
  pipWorkLossWaiver: "No Coverage",
  stackedUM: false,
  stackedUIM: false,
  medBenefits: null,
  priorCarrier: "Other - Standard",
  yearsWithPrior: 3,
  monthsWithPrior: 0,
  priorLimits: "100/300",
  autoEffectiveDate: "2024-03-14",
  otherStructures: 47000,
  contents: 239000,
  lossOfUse: 95000,
  liability: 500000,
  wind_deductible: "2%",
  hurricane_deductible: null,
  base_deductible: "1%",
};

export const DUMMY_AUTO_COVERAGES: AutoCoverages = {
  liability: "250/500/100",
  umuim: "250/500",
  umuimumpd: "250/500/100",
  dwelling: 479520,
  personal_liability: 500000,
  wind: "2%",
  hurricane: null,
  aop: "1%",
  personal_property: 60,
  suddenWaterDamage: "Yes",
  waterBackup: "Included",
  replacementDwelling: "Yes",
  replacementContents: "Yes",
  homeEffectiveDate: "2024-03-14",
  bodily_injury: "250/500",
  property_damage: "100000",
  uim: "250/500",
  uim_property_damage: "100000",
  pip: "2500",
  pipDeductible: null,
  pipType: null,
  wageLoss: null,
  pipLimit: null,
  pipStacking: false,
  medPay: "5000",
  tort: null,
  apip: null,
  pipWorkLossWaiver: "No Coverage",
  stackedUM: false,
  stackedUIM: false,
  medBenefits: null,
  priorCarrier: "Other - Standard",
  yearsWithPrior: 3,
  monthsWithPrior: 0,
  priorLimits: "100/300",
  autoEffectiveDate: "2024-03-14",
  rental: "30/900",
  towing: "Yes",
};
