import { clearAllEventStreams, isDwelling, setFieldsEdited } from "../utils";
import {
  getAddress,
  hasAutoQuote,
  hasBundles,
  hasPropertyQuote,
  saveSfAccountId,
  sessionLob,
} from "../utilsMisc";

import { ReactComponent as DriversVehicles } from "../assets/images/components/toolbar/DriversVehicles.svg";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as HomeDetails } from "../assets/images/components/toolbar/HomeDetails.svg";
import { ReactComponent as LeftCaret } from "../assets/images/components/toolbar/LeftCaret.svg";
import { ReactComponent as LocationPin } from "../assets/images/components/toolbar/LocationPin.svg";
import { ReactComponent as Person } from "../assets/images/components/toolbar/Person.svg";
import { ReactComponent as PersonalInfo } from "../assets/images/components/toolbar/PersonalInfo.svg";
import { ReactComponent as PolicyType } from "../assets/images/components/toolbar/PolicyType.svg";
import QuoteTools from "./QuoteTools";
import { addContact } from "../redux/actions/contact";
import { capitalizeFirstChars } from "../utils/strings";
import { getPagenumber } from "../utils/strings";
import { handleUpdateSession } from "../services/utils";
import { url as salesforceDomain } from "../utils/sales-force";
import styled from "styled-components";
import { updateIsFetchingQuotes } from "../redux/actions/fetchingQuotes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "../redux/hooks";
import { useEffect } from "react";

const StyledToolbar = styled.div`
  display: flex;
  position: relative;
  height: 40px;
  color: #2d2c2a;
  font-family: "Rethink Sans Variable", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border-bottom: solid 1px #d3d3d3;
  background-color: white;

  & button,
  & a {
    border: none;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    /* override Bootstrap CSS */
    border-radius: 0;
    background: transparent !important;
    color: inherit !important;

    &:focus,
    &:active,
    &:hover {
      /* override Bootstrap CSS */
      background: #f7f7f7 !important;
      border-color: none !important;
      color: inherit !important;
      box-shadow: none !important;
    }
  }
  & .nav-button {
    width: 140px;
    height: 100%;
    position: relative;
  }
  & .nav-button.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #018d32;
  }
  & .back {
    position: relative;
    /* override default Bootstrap styles */
    border-right: solid 1px #d3d3d3 !important;
    & svg {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-55%);
    }
    &::after {
      display: none;
    }
  }
  & .info {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    & a,
    & span {
      display: flex;
      gap: 3px;
      align-items: center;
      padding: 0 12px;
      & svg {
        transform: translateY(-5%);
      }
    }
    & span {
      color: #999999;
      cursor: wait;
    }
  }
  .dropdown-menu {
    padding: 0;
    border: none;
    /* WARNING: this is a hack to override bootstrap */
    /* change this value if you need to change the CSS */
    transform: translateY(40px) !important;
  }
  .dropdown {
    height: 100%;
  }
  .show.dropdown {
    background-color: #f7f7f7;
  }
`;

const StyledDropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  position: absolute;
  width: 140px;
  border: solid 1px #d5d5d5;
  border-top: none;
  padding: 10px 0 5px;
  color: #2d2c2a;
  z-index: 10;
  & a {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
    color: #565656;
    font-family: "Rethink Sans Variable", sans-serif;
    font-weight: 500;
    text-decoration: none;
  }
`;

const commonTabs = [
  {
    pageName: "PolicyType",
    title: "Policy",
    icon: <PolicyType />,
    lobs: ["Home"],
  },
  {
    pageName: "PropertyInfo",
    title: "Details",
    icon: <HomeDetails />,
    lobs: ["Home"],
  },
  {
    pageName: "AboutYou",
    title: "Info",
    icon: <PersonalInfo />,
    lobs: isDwelling() ? ["Home"] : ["Home", "Auto"],
  },
];

// Additional tabs for non-dwelling scenario
const autoTabs = isDwelling()
  ? []
  : [
      {
        pageName: "Drivers",
        title: "Drivers",
        icon: <DriversVehicles />,
        lobs: ["Auto"],
      },
      {
        pageName: "Vehicles",
        title: "Vehicles",
        icon: <DriversVehicles />,
        lobs: ["Auto"],
      },
      {
        pageName: "AutoDetails",
        title: "Details",
        icon: <DriversVehicles />,
        lobs: ["Auto"],
      },
    ];

const backButtonTabs = [...commonTabs, ...autoTabs];

const Toolbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);
  const { firstName, lastName, accountId, activeTab } = contact;

  const { data: sfAccountId } = useQuery({
    queryKey: ["sfAccountId", accountId],
    queryFn: saveSfAccountId,
    retry: 5,
    retryDelay: 2000,
    staleTime: Infinity,
  });
  const sfAccountUrl = sfAccountId
    ? `${salesforceDomain}${sfAccountId}`
    : undefined;

  const address = getAddress("property");

  const handleClick = async (pageName: string) => {
    if (pageName === "PolicyType") {
      setFieldsEdited();
    }
    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: sessionLob(),
      page_stopped: getPagenumber(sessionLob(), pageName),
    });
    const { quoteEvents } = window as unknown as Window & {
      quoteEvents: any[];
    };

    clearAllEventStreams(quoteEvents);

    dispatch(updateIsFetchingQuotes(false));

    navigate(`/agent/${sessionLob()}/${pageName}`);
  };

  // ** Update the document title with the contact's name
  useEffect(() => {
    const oldTitle = document.title;

    if (contact) {
      document.title = `${contact.firstName} ${contact.lastName}`;
    }

    return () => {
      document.title = oldTitle;
    };
  }, [contact]);

  return (
    <StyledToolbar>
      <Dropdown>
        <Dropdown.Toggle className="nav-button back">
          <LeftCaret />
          <span>Back</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <StyledDropdownBody>
            {backButtonTabs.map((tab) => {
              const showTab =
                (hasPropertyQuote() && tab.lobs.includes("Home")) ||
                (hasAutoQuote() && tab.lobs.includes("Auto"));

              return (
                <>
                  {showTab && (
                    <Dropdown.Item
                      key={tab.pageName}
                      onClick={() => handleClick(tab.pageName)}
                    >
                      {tab.icon}
                      {tab.title}
                    </Dropdown.Item>
                  )}
                </>
              );
            })}
          </StyledDropdownBody>
        </Dropdown.Menu>
      </Dropdown>

      {hasBundles() && (
        <>
          <button
            className={`nav-button ${activeTab === "package" && "active"}`}
            onClick={() => {
              dispatch(
                addContact({
                  activeTab: "package",
                })
              );
            }}
          >
            Package View
          </button>
          <button
            className={`nav-button ${activeTab === "split" && "active"}`}
            onClick={() => {
              dispatch(
                addContact({
                  activeTab: "split",
                })
              );
            }}
          >
            Split View
          </button>
        </>
      )}
      <div className="info">
        {firstName &&
          (sfAccountUrl ? (
            <a href={sfAccountUrl} target="_blank" rel="noreferrer">
              <Person />
              {firstName} {lastName}
            </a>
          ) : (
            <span>
              <Person />
              {firstName} {lastName}
            </span>
          ))}
        {address && (
          <a
            href={`https://www.google.com/search?q=${address}`}
            target="_blank"
            rel="noreferrer"
          >
            <LocationPin />
            {address}
          </a>
        )}
      </div>
      <QuoteTools />
    </StyledToolbar>
  );
};

export default Toolbar;
