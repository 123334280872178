import { useState } from "react";

import { CustomText } from "./styled/typography";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useEffect } from "react";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label className="ms-3">{props.label}</label>
      </components.Option>
    </div>
  );
};

const CustomReactSelect = ({
  label,
  selectOptions,
  handleSelectedQuote,
  lob,
  labelPosition,
}) => {
  const [optionSelected, setOptionSelected] = useState([]);
  const [options, setOptions] = useState([]);

  const handleChange = (selected) => {
    setOptionSelected(selected);
    handleSelectedQuote(selected.value, lob);
  };

  useEffect(() => {
    let reactSelectOptions = selectOptions.sort().map((option) => {
      return { value: option, label: option };
    });
    setOptions(reactSelectOptions);
  }, [selectOptions]);

  return (
    <div
      className={`${
        labelPosition === "left"
          ? "d-flex justify-content-between align-items-center"
          : "d-flex flex-column align-items-start"
      }`}
    >
      {label && (
        <div className={`${labelPosition === "left" ? "me-4" : "ms-2"}`}>
          <CustomText fontSize="small" fontFamily="font_secondary">
            {label}
          </CustomText>
        </div>
      )}
      <ReactSelect
        options={options}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelected}
        styles={{
          option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted #6CB545",
            paddingLeft: "10px",
            backgroundColor: state.isSelected ? "white" : "white",
            color: "black",
          }),
          control: (style, state) => ({
            ...style,
            minHeight: "50px",
            width: "400px",
            fontSize: "rem(15px)",
            fontWeight: "400",
            borderRadius: "25px",
            border: state.isFocused ? "2px solid #6CB545" : "1px solid #6CB545",
            padding: "5px",
            boxShadow: 0,
            outline: "none",
            "&:hover": {
              border: state.isFocused
                ? "2px solid #6CB545"
                : "1px solid #6CB545",
            },
          }),
          dropdownIndicator: (style) => ({ ...style, color: "#6CB545" }),
          clearIndicator: (style) => ({ ...style, color: "#6CB545" }),
        }}
      />
    </div>
  );
};

export default CustomReactSelect;
