import axios from "axios";
import queryString from "query-string";

export const createQuote = (payload) =>
  axios.post("/api/createsfquote", payload);

export const updateQuote = (payload) => axios.put("/api/updatequote", payload);

export const deleteQuote = (quoteId) =>
  axios.delete(`/api/deletequote/${quoteId}`);

export const sendQuoteIdToMs = (lob, quoteId) =>
  axios.get(`/api/quotes?lob=${lob}&quoteId=${quoteId}&rater=Aviator`);

export const getQuoteRequest = (quoteId) =>
  axios.get(`/api/quoterequest/${quoteId}`);

export const updateQuoteResponseRecommendation = (payload) =>
  axios.put("/api/quoteresponserecommendation", payload);

export const updateQuoteResponse = (payload) =>
  axios.put("/api/quoteresponse", payload);

/**
 * @param {{
 *   quoteResponseId: string;
 *   sfAgentId?: string;
 * }} payload
 * @returns
 */
export const resubmitQuoteResponse = (payload) =>
  axios.post("/api/quoteresponse/resubmit", payload);

/**
 * @param {{
 *   quoteRequestId: string;
 *   carrierId: string;
 *   isPackageable: boolean;
 *   sfAgentId: string;
 * }} payload
 * @returns
 */
export const checkResubmitQuoteResponseStatus = (payload) =>
  axios.get(
    `/api/quoteresponse/resubmit/status?${queryString.stringify(payload)}`
  );

/** @param {string} quoteResponseId */
export const deleteQuoteResponse = (quoteResponseId) =>
  axios.delete(
    `/api/quoteresponse?${queryString.stringify({ quoteResponseId })}`
  );

/** @param {string} quoteResponseId */
export const deleteQuoteResponses = (quoteResponseId) =>
  axios.delete(`/api/quoteresponse/${quoteResponseId}`);

export const getResponsesByQuoteRequestId = (quoteRequestId) =>
  axios.get(`/api/quoteresponses/${quoteRequestId}`);

export const getDummyQuoteResponses = (state, lob) =>
  axios.get("/api/dummy-quoteresponses/", { params: { state, lob } });

export const getRaterDefaults = (state, zipcode) =>
  axios.get("/api/raterdefaults", { params: { state, zipcode } });

export const updateQuoteRequestInSF = (payload) =>
  axios.put("/api/sfQuoteRequest", payload);

export const switchPrimary = ({ lob, quoteRequestId, carrierId }) =>
  axios.put("/api/switch-primary", {
    lob,
    queryId: quoteRequestId,
    carrierId,
    rater: "Aviator",
  });

/**
 * @param {{
 *   carrierId: string;
 *   homeQuoteId?: string;
 *   autoQuoteId?: string;
 * }} payload
 * @returns {Promise<import("../types").CopilotCarrierConfig>}
 */
export const getCopilotCarrierConfig = async ({
  carrierId,
  homeQuoteId,
  autoQuoteId,
}) => {
  const domain = process.env.REACT_APP_MONOREPO_API_DOMAIN;
  const response = await axios.get(
    `${domain}/chrome-extension/carrier-config`,
    {
      params: {
        carrierId,
        homeQuoteId,
        autoQuoteId,
      },
    }
  );
  return response.data;
};

export const syncQuoteResponses = async (payload) => {
  axios.post("/api/syncquoteresponses", payload);
};
