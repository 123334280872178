import { useLayoutEffect, useState } from "react";

import debounce from "../utils/debounce";

export const useWindowHeight = () => {
  const [windowheight, setWindowHeight] = useState();
  const [windowWidth, setWindowWidth] = useState();
  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth !== windowWidth) {
        setWindowHeight(window.innerHeight);
        setWindowWidth(window.innerWidth);
      }
    };

    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener("resize", debouncedHandleResize);
    handleResize();
    return () => window.removeEventListener("resize", debouncedHandleResize);
    // eslint-disable-next-line
  }, []);
  return windowheight;
};
