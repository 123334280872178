import { capitalizeFirstChars } from "./strings";
import { trimStringToMaxLength } from "../utilsMisc";

export const getIsSpouse = (quote) =>
  quote?.quote_response_external_id__c?.includes("Spouse");

const formatContactName = (contact, isSpouse, maxLength = 12) => {
  const firstName = isSpouse ? contact.spousefirstName : contact.firstName;

  const firstNameTrimmed = trimStringToMaxLength(firstName, maxLength);

  return firstNameTrimmed;
};

export default formatContactName;
