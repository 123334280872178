import type {
  CarrierKnowledgeBaseResponse,
  ManualQuoteResponse,
  QuoteBundle,
  Quote as QuoteResponse,
} from "../types";
import { Col, Row } from "react-bootstrap";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  PACKAGE_REQUIRED,
  PACKAGE_REQUIRED_BUNDLE_PAGE,
  PACKAGE_REQUIRED_NOT_AVAILABLE,
  RATE_LOB_ORDER,
  VIVINT_SMART_HOME_QUOTE,
  auto,
  bundle,
  companyIds,
  flood,
  home,
  jewelry,
  life,
  lifePrimary,
  lifeSpouse,
  qtiAutoCarriers,
  qtiHomeCarriers,
  security,
  umbrella,
} from "../constants";
import {
  QuotesContainer,
  RecommendedQuotesContainer,
} from "./styled/QuotesContainer";
import {
  calculateQuoteListMarginBottom,
  filterQuotes,
  getCoverages,
  getHomeQuotesTitle,
  validateStateToDisplay,
} from "../utils/quotes";
import {
  getMonolineQuotes,
  mapManualQuote,
  useIsNewResponsePageDesign,
} from "../utilsMisc";
import { getRateListHeader, isDwelling } from "../utils";

import { BundleQuoteListContainer } from "./new_design/BundleQuoteListContainer";
import { LobButton } from "./styled/buttons/misc";
import ManualQuote from "./ManualQuote";
import { MonolineQuoteListContainer } from "./new_design/MonolineQuoteListContainer";
import Price from "./Price";
import Quote from "./Quote";
import { QuoteSeperator } from "./styled/seperator";
import QuotesFilter from "./QuotesFilter";
import RateListFrame from "./RateListFrame";
import RecommendedQuotes from "./RecommendedQuotes";
import { StyledRatesContainer } from "./new_design/styled";
import { getCKBs } from "../services/carrier";
import { getLineOfBusiness } from "../utils/strings";
import { getNonRaterCarriers } from "../services/user";
import stateConfigsJson from "../constants.state-configs.json";
import styled from "styled-components";
import { useFeatureFlag } from "../services/featureFlag";
import { useSelector } from "../redux/hooks";

export const BundleTotalText = styled.div`
  color: #2d424d;
  font-size: 24px;
  font-weight: bold;
  font-family: ${(props) => props.theme.font_header_base};
`;

const RateListFrameText = styled.div`
  color: #2d424d;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  padding: 22.5px 0 7.5px 0;
`;

interface CarrierConfig {
  carrierId: number;
  carrierName: string;
  isMonolineAcceptable: boolean;
  isMonolineIneligibleInCertainCounties: boolean;
  monolineIneligibleCounties: null | string[];
  monolineNotes?: string;
  packageDiscountType: string;
}

interface StateConfig {
  auto: { [key: string]: CarrierConfig };
  home: { [key: string]: CarrierConfig };
}

interface StateConfigs {
  [state: string]: StateConfig;
}

const Rates = ({
  setShowSmartHomePackageSelectionModal,
}: {
  setShowSmartHomePackageSelectionModal: () => void;
}) => {
  const featureFlags = useFeatureFlag();
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session);
  const property = useSelector((store) => store.property);
  const quote = useSelector((store) => store.quote);
  const quotes = quote?.quotes;
  const isDwellingQuote = isDwelling();
  const hasBundle = !!quotes?.[bundle]?.length && !isDwellingQuote;
  const isNewResponseDesign = useIsNewResponsePageDesign();

  const vivintSmartHomeReferralFlag = featureFlags.getFlag(
    "vivintSmartHomeReferral"
  );
  const { __meta, ...rest } = stateConfigsJson;
  const stateConfigs: StateConfigs = rest as StateConfigs;

  const showVivintSmartHomeQuote =
    contact.homeQuoteId && vivintSmartHomeReferralFlag;

  const isMultiLine = (session.line_of_business?.indexOf("Auto") ?? 0) > 0;

  const isDwellingOnly = !isMultiLine && isDwelling();

  const filteredHomeCarriers = contact?.filteredHomeCarriers;
  const filteredAutoCarriers = contact?.filteredAutoCarriers;

  const [selectedHomeCarriers, setSelectedHomeCarriers] =
    useState(filteredHomeCarriers);
  const [selectedAutoCarriers, setSelectedAutoCarriers] =
    useState(filteredAutoCarriers);
  // if value is null/undefined, all carriers are selected

  const [nonRaterCarriers, setNonRaterCarriers] = useState<{
    home: ManualQuoteResponse[];
    auto: ManualQuoteResponse[];
  }>({
    home: [],
    auto: [],
  });

  const { filteredBundledQuotes, filteredHomeQuotes, filteredAutoQuotes } =
    filterQuotes(quotes, filteredHomeCarriers, filteredAutoCarriers);

  // TODO: Change type from any to actual types
  const filteredQuotes: Record<string, any[] | undefined> = useMemo(
    () => ({
      home: validateStateToDisplay(filteredHomeQuotes) ?? [],
      auto: validateStateToDisplay(filteredAutoQuotes) ?? [],
      bundle: isDwellingQuote ? [] : filteredBundledQuotes ?? [],
      life: [...(quotes?.[lifePrimary] ?? []), ...(quotes?.[lifeSpouse] ?? [])],
      flood: quotes?.[flood] ?? [],
      jewelry: quotes?.[jewelry] ?? [],
      umbrella: quotes?.[umbrella] ?? [],
      lifePrimary: quotes?.[lifePrimary],
      lifeSpouse: quotes?.[lifeSpouse],
      ...(contact.homeQuoteId ? { security: [VIVINT_SMART_HOME_QUOTE] } : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredAutoQuotes, filteredBundledQuotes, filteredHomeQuotes, quotes]
  );

  let initialActiveQuote =
    RATE_LOB_ORDER.find((lob) => (filteredQuotes[lob] ?? []).length !== 0) ??
    "";

  // Life quotes are merged into 1 tab
  if ([lifePrimary, lifeSpouse].includes(initialActiveQuote)) {
    initialActiveQuote = life;
  }

  const [activeQuote, setActiveQuote] = useState(initialActiveQuote);

  useEffect(() => {
    setActiveQuote(initialActiveQuote);
  }, [initialActiveQuote]);

  const [ckbs, setCKBs] = useState<CarrierKnowledgeBaseResponse>();

  useEffect(() => {
    const handleCKBs = async () => {
      if (property.administrative_area_level_1) {
        const data = (await getCKBs(property.administrative_area_level_1))
          ?.data;
        setCKBs(data);
      }
    };
    handleCKBs();
  }, [property.administrative_area_level_1]);

  useEffect(() => {
    const manualQuoteCarriers = async () => {
      try {
        if (
          (contact.user || session.agent_sfid) &&
          property.administrative_area_level_1
        ) {
          const lob = getLineOfBusiness(session.line_of_business || "");
          let homeNonRaterCarriers: ManualQuoteResponse[] = [];
          let autoNonRaterCarriers: ManualQuoteResponse[] = [];
          if (lob === "HomeAuto" || lob === "Home") {
            homeNonRaterCarriers = (
              await getNonRaterCarriers(
                "home",
                property.administrative_area_level_1,
                (contact.user || session.agent_sfid)!
              )
            ).data;
          }
          if (lob === "HomeAuto" || lob === "Auto") {
            autoNonRaterCarriers = (
              await getNonRaterCarriers(
                "auto",
                property.administrative_area_level_1,
                (contact.user || session.agent_sfid)!
              )
            ).data;
          }
          setNonRaterCarriers({
            home: homeNonRaterCarriers,
            auto: autoNonRaterCarriers,
          });
        }
      } catch (e) {
        setNonRaterCarriers({
          home: [],
          auto: [],
        });
      }
    };

    manualQuoteCarriers();
    // eslint-disable-next-line
  }, [contact.accountId, property.administrative_area_level_1]);

  // TODO: remove this after old design is removed
  const raterCarrierQuotes = filteredQuotes[activeQuote] ?? [];
  const homeRaterCarrierQuotes = filteredQuotes["home"] ?? [];
  const autoRaterCarrierQuotes = filteredQuotes["auto"] ?? [];

  // TODO: remove this after old design is removed
  const monolineEligibleQuotes = raterCarrierQuotes.filter(
    ({ package_rate__c }) => !package_rate__c
  );
  // TODO: remove this after old design is removed
  const monolineIneligibleQuotes = raterCarrierQuotes.filter(
    ({ package_rate__c }) => package_rate__c
  );

  const state = property?.administrative_area_level_1 ?? "";
  const county =
    property?.administrative_area_level_2?.replace(" County", "") ?? "";

  const monolineHomeQuotes = getMonolineQuotes(
    homeRaterCarrierQuotes,
    "home",
    isMultiLine,
    stateConfigs,
    state,
    county
  );
  const monolineAutoQuotes = getMonolineQuotes(
    autoRaterCarrierQuotes,
    "auto",
    isMultiLine,
    stateConfigs,
    state,
    county
  );

  const isHomeAutoQuote = activeQuote === home || activeQuote === auto;
  const nonRaterCarrierQuotes =
    nonRaterCarriers[activeQuote as "home" | "auto"] ?? [];
  const nonRaterCarrierHomeQuotes = (nonRaterCarriers["home"] ?? [])
    .map((manualQuote) => mapManualQuote(manualQuote, "home"))
    .sort((a, b) => a.carrier__c.localeCompare(b.carrier__c)); // sort alphabetically
  const nonRaterCarrierAutoQuotes = (nonRaterCarriers["auto"] ?? [])
    .map((manualQuote) => mapManualQuote(manualQuote, "auto"))
    .sort((a, b) => a.carrier__c.localeCompare(b.carrier__c)); // sort alphabetically

  const marginBottom = calculateQuoteListMarginBottom(
    raterCarrierQuotes?.length
  );

  const showMonoIneligibleOutsideBundleTab =
    !!monolineIneligibleQuotes.length && (isDwellingQuote || !hasBundle);

  const homeAutoQuotesHelpTxt = () => {
    if (!isDwellingQuote && isMultiLine) {
      return PACKAGE_REQUIRED_BUNDLE_PAGE;
    } else if (!monolineIneligibleQuotes.length) {
      return PACKAGE_REQUIRED_NOT_AVAILABLE;
    } else return "";
  };

  const hasHomeOrAutoQuotes =
    quotes?.home?.length !== 0 || quotes?.auto?.length !== 0;

  return (
    <>
      {isNewResponseDesign ? (
        hasHomeOrAutoQuotes && (
          <StyledRatesContainer isDwellingOnly={isDwellingOnly}>
            <div className="width-container">
              {contact.activeTab === "package" && (
                // TODO: remove this casting by assigning correct types
                <section className="bundle-section">
                  <BundleQuoteListContainer
                    quoteBundles={filteredQuotes[bundle] as QuoteBundle[]}
                  />
                </section>
              )}
              {contact.activeTab === "split" && (
                <>
                  <section className="monoline-section">
                    <MonolineQuoteListContainer
                      lob={home}
                      quotesByType={{
                        Monoline: monolineHomeQuotes,
                        "Quote Manually": nonRaterCarrierHomeQuotes,
                      }}
                      key={home}
                    />
                  </section>
                  {!isDwellingOnly && (
                    <section className="monoline-section">
                      <MonolineQuoteListContainer
                        lob={auto}
                        quotesByType={{
                          Monoline: monolineAutoQuotes,
                          "Quote Manually": nonRaterCarrierAutoQuotes,
                        }}
                        key={auto}
                      />
                    </section>
                  )}
                </>
              )}

              <section className="additional-section">
                <MonolineQuoteListContainer
                  lob="jewelry"
                  quotesByType={{
                    Monoline: (
                      filteredQuotes[jewelry] as QuoteResponse[]
                    ).filter(({ package_rate__c }) => !package_rate__c),
                    // TODO: remove this filter after old design is removed and no duplicates exist
                    "Quote Manually": [],
                  }}
                />
                <MonolineQuoteListContainer
                  lob={umbrella}
                  quotesByType={{
                    Monoline: (
                      filteredQuotes[umbrella] as QuoteResponse[]
                    ).filter(({ package_rate__c }) => !package_rate__c),
                    // TODO: remove this filter after old design is removed and no duplicates exist
                    "Quote Manually": [],
                  }}
                />
                <MonolineQuoteListContainer
                  lob="flood"
                  quotesByType={{
                    Monoline: (filteredQuotes[flood] as QuoteResponse[]).filter(
                      ({ package_rate__c }) => !package_rate__c
                    ),
                    // TODO: remove this filter after old design is removed and no duplicates exist
                    "Quote Manually": [],
                  }}
                />
                <MonolineQuoteListContainer
                  lob="life"
                  quotesByType={{
                    Monoline: (filteredQuotes[life] as QuoteResponse[]).filter(
                      ({ package_rate__c }) => !package_rate__c
                    ),
                    // TODO: remove this filter after old design is removed and no duplicates exist
                    "Quote Manually": [],
                  }}
                />
                {showVivintSmartHomeQuote && (
                  <MonolineQuoteListContainer
                    lob="security"
                    quotesByType={{
                      Monoline: filteredQuotes[security] as QuoteResponse[],
                      "Quote Manually": [],
                    }}
                  />
                )}
              </section>
              {isDwellingOnly && (
                <section className="monoline-section"></section>
              )}
            </div>
          </StyledRatesContainer>
        )
      ) : (
        <div className="mb-5 mt-3" style={{ marginBottom }}>
          <RecommendedQuotes
            quotes={filteredQuotes}
            ckbs={ckbs}
            setShowSmartHomePackageSelectionModal={
              setShowSmartHomePackageSelectionModal
            }
          />
          <QuotesFilter
            selectedHomeCarriers={selectedHomeCarriers}
            selectedAutoCarriers={selectedAutoCarriers}
            setSelectedHomeCarriers={setSelectedHomeCarriers}
            setSelectedAutoCarriers={setSelectedAutoCarriers}
          />
          <Row className="d-flex justify-content-center px-0 no-gutters mt-5">
            {hasBundle && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === bundle}
                  onClick={() => setActiveQuote(bundle)}
                >
                  Bundles
                </LobButton>
              </Col>
            )}
            {!!quotes?.[home]?.length && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === home}
                  onClick={() => setActiveQuote(home)}
                >
                  {getHomeQuotesTitle()}
                </LobButton>
              </Col>
            )}
            {!!quotes?.[auto]?.length && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === auto}
                  onClick={() => setActiveQuote(auto)}
                >
                  Auto
                </LobButton>
              </Col>
            )}
            {!!quotes?.[jewelry]?.length && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === jewelry}
                  onClick={() => setActiveQuote(jewelry)}
                >
                  Jewelry
                </LobButton>
              </Col>
            )}
            {!!quotes?.[flood]?.length && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === flood}
                  onClick={() => setActiveQuote(flood)}
                >
                  Flood
                </LobButton>
              </Col>
            )}
            {(!!quotes?.[lifePrimary]?.length ||
              !!quotes?.[lifeSpouse]?.length) && (
              <Col xs={2}>
                <LobButton
                  // @ts-ignore TODO: give the component the correct types
                  active={activeQuote === life}
                  onClick={() => setActiveQuote(life)}
                >
                  Life
                </LobButton>
              </Col>
            )}
          </Row>
          {activeQuote === bundle ? (
            <RecommendedQuotesContainer>
              {(filteredQuotes[bundle] ?? []).map((bundledQuote, index) => {
                let quoteToBind = "";
                if (
                  Object.keys((bundledQuote || {}).homeQuote || {}).length &&
                  Object.keys((bundledQuote || {}).autoQuote || {}).length
                ) {
                  const bundledHomeQuote = bundledQuote.homeQuote;
                  const bundledAutoQuote = bundledQuote.autoQuote;

                  if (
                    bundledHomeQuote.company_client_id__c ===
                      companyIds["Branch"] &&
                    bundledAutoQuote.company_client_id__c ===
                      companyIds["Branch"]
                  ) {
                    quoteToBind = "bundle";
                  }
                  if (
                    bundledHomeQuote.company_client_id__c ===
                      bundledAutoQuote.company_client_id__c &&
                    // TODO: give this object a correct type to avoid casting
                    featureFlags.getFlag(
                      (qtiHomeCarriers as Record<string, string>)[
                        bundledHomeQuote.company_client_id__c
                      ]
                    ) &&
                    featureFlags.getFlag(
                      (qtiAutoCarriers as Record<string, string>)[
                        bundledAutoQuote.company_client_id__c
                      ]
                    )
                  ) {
                    quoteToBind = "bundle";
                  }
                }
                return (
                  <QuotesContainer className="py-2 px-4" key={index}>
                    <Quote
                      ckb={ckbs}
                      quote={bundledQuote.homeQuote}
                      lob={home}
                      key={bundledQuote.homeQuote.heroku_id}
                      // @ts-ignore this component won't be used anyways
                      index={index}
                      coverages={getCoverages(home, bundledQuote.homeQuote)}
                      quoteToBind={quoteToBind}
                    />
                    <QuoteSeperator />
                    <Quote
                      ckb={ckbs}
                      quote={bundledQuote.autoQuote}
                      // @ts-ignore this component won't be used anyways
                      otherQuote
                      lob={auto}
                      key={bundledQuote.autoQuote.heroku_id}
                      index={index}
                      coverages={getCoverages(auto)}
                      quoteToBind={quoteToBind}
                    />
                    <QuoteSeperator />
                    <BundleTotalText className="me-3 mt-2">
                      <span>{`Total: `}</span>
                      {/* @ts-ignore this component won't be used anyways */}
                      <Price
                        value={bundledQuote.bundledPrice}
                        currency={"$"}
                        frequency="yearly"
                        color="#2D424D"
                      />
                    </BundleTotalText>
                  </QuotesContainer>
                );
              })}
            </RecommendedQuotesContainer>
          ) : (
            <>
              {monolineEligibleQuotes.length > 0 && (
                // @ts-ignore this component won't be used anyways
                <RateListFrame
                  // @ts-ignore this component won't be used anyways
                  header={getRateListHeader("Monoline Eligible", activeQuote)}
                  lob={activeQuote}
                >
                  {monolineEligibleQuotes.map((quote, index) => {
                    return (
                      <Fragment key={quote.quote_response_external_id__c}>
                        <Quote
                          // @ts-ignore this component won't be used anyways
                          quote={quote}
                          // @ts-ignore this component won't be used anyways
                          otherQuote
                          lob={activeQuote}
                          index={index}
                          ckb={ckbs}
                          coverages={getCoverages(activeQuote, quote)}
                        />
                        {/* @ts-ignore this component won't be used anyways */}
                        {quotes.length - 1 !== index && <QuoteSeperator />}
                      </Fragment>
                    );
                  })}
                </RateListFrame>
              )}
              {isHomeAutoQuote && (
                // @ts-ignore this component won't be used anyways
                <RateListFrame
                  header={PACKAGE_REQUIRED}
                  lob={activeQuote}
                  isDwelling={isDwellingQuote}
                >
                  <RateListFrameText>
                    {homeAutoQuotesHelpTxt()}
                  </RateListFrameText>
                  {showMonoIneligibleOutsideBundleTab &&
                    monolineIneligibleQuotes.map((quote, index) => (
                      <Fragment key={quote.quote_response_external_id__c}>
                        <Quote
                          quote={quote}
                          // @ts-ignore this component won't be used anyways
                          otherQuote
                          lob={activeQuote}
                          index={index}
                          ckb={ckbs}
                          coverages={getCoverages(activeQuote, quote)}
                        />
                        {/* @ts-ignore this component won't be used anyways */}
                        {quotes.length - 1 !== index && <QuoteSeperator />}
                      </Fragment>
                    ))}
                </RateListFrame>
              )}
              {nonRaterCarrierQuotes.length > 0 && (
                // @ts-ignore this component won't be used anyways
                <RateListFrame
                  // @ts-ignore this component won't be used anyways
                  header={getRateListHeader("Quote Manually", activeQuote)}
                  lob={activeQuote}
                >
                  {nonRaterCarrierQuotes.map((quote, index) => {
                    return (
                      // @ts-ignore this component won't be used anyways
                      <Fragment key={quote.quote_response_external_id__c}>
                        <ManualQuote
                          // @ts-ignore this component won't be used anyways
                          ckbs={ckbs}
                          lob={activeQuote}
                          quote={quote || {}}
                        />
                        {/* @ts-ignore this component won't be used anyways */}
                        {nonRaterCarriers[activeQuote].length - 1 !== index && (
                          <QuoteSeperator />
                        )}
                      </Fragment>
                    );
                  })}
                </RateListFrame>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Rates;
