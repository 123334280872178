import "./RecommendedQuotes.css";

import { Col, Row } from "react-bootstrap";
import {
  auto,
  bundle,
  companyIds,
  flood,
  home,
  jewelry,
  life,
  lifePrimary,
  lifeSpouse,
  security,
} from "../constants";
import { getAutoPrice, isDwelling } from "../utils";
import { getLineOfBusiness, getPremiumPerMonth } from "../utils/strings";
import { useEffect, useState } from "react";

import { BundleTotalText } from "./Rates";
import { CustomText } from "./styled/typography";
import Price from "./Price";
import PropTypes from "prop-types";
import Quote from "./Quote";
import QuotePlaceHolder from "./QuotePlaceHolder";
import QuoteSelect from "./QuoteSelect";
import { QuoteSeperator } from "./styled/seperator";
import { QuotesContainer } from "./styled/QuotesContainer";
import { getCoverages } from "../utils/quotes";
import getPrimaryAndSpouseLifeQuotes from "../utils/getPrimaryAndSpouseLifeQuotes";
import { isEmpty } from "lodash";
import recommendedUnderline from "../assets/images/components/recommendedUnderline.svg";
import styled from "styled-components";
import { useFeatureFlag } from "../services/featureFlag";
import { useSelector } from "react-redux";

export const RecommendedUnderline = styled.img`
  margin-left: 14px;
`;

const RecommendedQuotes = ({
  quotes,
  ckbs,
  setShowSmartHomePackageSelectionModal,
}) => {
  const contact = useSelector((store) => store.contact);
  const session = useSelector((store) => store.session);
  const [featuredQuotes, setFeaturedQuotes] = useState({
    auto: {},
    home: {},
    flood: {},
    jewelry: {},
    lifeSpouse: {},
    lifePrimary: {},
  });

  const [quoteToBind, setQuoteToBind] = useState("");

  const featureFlags = useFeatureFlag();

  const vivintSmartHomeReferralFlag = featureFlags.getFlag(
    "vivintSmartHomeReferral"
  );

  const showVivintSmartHomeQuote =
    featuredQuotes.security &&
    contact.homeQuoteId &&
    vivintSmartHomeReferralFlag;

  // This means if the result page has the quote section or not
  const hasHomeQuote = Boolean(contact.homeQuoteId);
  const hasAutoQuote = Boolean(contact.autoQuoteId);

  // This means there's a featured quote available on top
  const hasFeaturedHomeQuote =
    featuredQuotes.home && Object.keys(featuredQuotes[home]).length !== 0;
  const hasFeaturedAutoQuote =
    featuredQuotes.auto && Object.keys(featuredQuotes[auto]).length !== 0;

  const shouldHideAutoRow = !hasFeaturedAutoQuote && isDwelling();

  const getRecommendedCarrierList = (lob) => {
    if (lob === home) {
      let homeCarriers = new Set();
      if (contact.autoQuoteId) {
        if (hasFeaturedAutoQuote) {
          quotes[bundle].forEach((quote) => {
            if (quote.autoQuote.carrier__c === featuredQuotes[auto].carrier__c)
              homeCarriers.add(quote.homeQuote.carrier__c);
          });
        } else {
          quotes[bundle].forEach((quote) =>
            homeCarriers.add(quote.homeQuote.carrier__c)
          );
        }
      } else
        quotes[home].forEach((quote) => homeCarriers.add(quote.carrier__c));
      return Array.from(homeCarriers);
    }
    if (lob === auto) {
      let autoCarriers = new Set();
      if (contact.homeQuoteId) {
        if (hasFeaturedHomeQuote) {
          quotes[bundle].forEach((quote) => {
            if (quote.homeQuote.carrier__c === featuredQuotes[home].carrier__c)
              autoCarriers.add(quote.autoQuote.carrier__c);
          });
        } else {
          quotes[bundle].forEach((quote) =>
            autoCarriers.add(quote.autoQuote.carrier__c)
          );
        }
      } else
        quotes[auto].forEach((quote) => autoCarriers.add(quote.carrier__c));
      return Array.from(autoCarriers);
    }
    if ([flood, lifePrimary, lifeSpouse, jewelry, security].includes(lob)) {
      let carriers = new Set();
      quotes[lob].forEach((quote) => carriers.add(quote.carrier__c));
      return Array.from(carriers);
    }
  };

  const handleRemoveQuote = (quoteType) => {
    if (quoteType === "home")
      setFeaturedQuotes({
        ...featuredQuotes,
        auto: hasFeaturedAutoQuote
          ? quotes.auto.find(
              (autoQuote) =>
                autoQuote.carrier__c === featuredQuotes[auto].carrier__c
            )
            ? quotes.auto.find(
                (autoQuote) =>
                  autoQuote.carrier__c === featuredQuotes[auto].carrier__c
              )
            : {}
          : {},
        home: {},
      });
    else if (quoteType === "auto")
      setFeaturedQuotes({
        ...featuredQuotes,
        home: hasFeaturedHomeQuote
          ? quotes.home.find(
              (homeQuote) =>
                homeQuote.carrier__c === featuredQuotes[home].carrier__c
            )
            ? quotes.home.find(
                (homeQuote) =>
                  homeQuote.carrier__c === featuredQuotes[home].carrier__c
              )
            : {}
          : {},
        auto: {},
      });
    else setFeaturedQuotes({ ...featuredQuotes, [quoteType]: {} });
  };

  const handleSelectedQuote = (selectedCarrier, lob) => {
    if (lob === home) {
      if (contact.autoQuoteId) {
        if (Object.keys(featuredQuotes[auto] || {}).length) {
          quotes[bundle].forEach((quote) => {
            if (
              quote.autoQuote.carrier__c === featuredQuotes[auto].carrier__c &&
              quote.homeQuote.carrier__c === selectedCarrier
            ) {
              setFeaturedQuotes({
                ...featuredQuotes,
                home: quote.homeQuote,
                auto: quote.autoQuote,
              });
            }
          });
        } else {
          quotes[bundle].forEach((quote) => {
            if (quote.homeQuote.carrier__c === selectedCarrier) {
              setFeaturedQuotes({ ...featuredQuotes, home: quote.homeQuote });
            }
          });
        }
      } else {
        quotes[home].forEach((quote) => {
          if (quote.carrier__c === selectedCarrier) {
            setFeaturedQuotes({ ...featuredQuotes, home: quote });
          }
        });
      }
    }
    if (lob === auto) {
      if (contact.homeQuoteId) {
        if (Object.keys(featuredQuotes[home] || {}).length) {
          quotes[bundle].forEach((quote) => {
            if (
              quote.homeQuote.carrier__c === featuredQuotes[home].carrier__c &&
              quote.autoQuote.carrier__c === selectedCarrier
            ) {
              setFeaturedQuotes({
                ...featuredQuotes,
                home: quote.homeQuote,
                auto: quote.autoQuote,
              });
            }
          });
        } else {
          quotes[bundle].forEach((quote) => {
            if (quote.autoQuote.carrier__c === selectedCarrier) {
              setFeaturedQuotes({ ...featuredQuotes, auto: quote.autoQuote });
            }
          });
        }
      } else {
        quotes[auto].forEach((quote) => {
          if (quote.carrier__c === selectedCarrier) {
            setFeaturedQuotes({ ...featuredQuotes, auto: quote });
          }
        });
      }
    }
    if ([flood, lifePrimary, lifeSpouse, jewelry, security].includes(lob)) {
      quotes[lob].forEach((quote) => {
        if (quote.carrier__c === selectedCarrier) {
          setFeaturedQuotes({ ...featuredQuotes, [lob]: quote });
        }
      });
    }
  };

  const getBundleTotal = () => {
    let total = 0;
    if (featuredQuotes[home] && Object.keys(featuredQuotes[home]).length) {
      total +=
        featuredQuotes[home].premium_total_pif__c ||
        getPremiumPerMonth(featuredQuotes[home]) * 12;
    }
    if (featuredQuotes[auto] && Object.keys(featuredQuotes[auto]).length) {
      total += getAutoPrice(featuredQuotes[auto]);
    }
    if (featuredQuotes[flood] && Object.keys(featuredQuotes[flood]).length) {
      total +=
        featuredQuotes[flood].premium_total__c ||
        getPremiumPerMonth(featuredQuotes[flood]) * 12;
    }
    if (
      featuredQuotes[lifePrimary] &&
      Object.keys(featuredQuotes[lifePrimary]).length
    ) {
      total +=
        featuredQuotes[lifePrimary].premium_total__c * 12 ||
        featuredQuotes[lifePrimary].premium_total_ppm__c * 12;
    }
    if (
      featuredQuotes[lifeSpouse] &&
      Object.keys(featuredQuotes[lifeSpouse]).length
    ) {
      total +=
        featuredQuotes[lifeSpouse].premium_total__c * 12 ||
        featuredQuotes[lifeSpouse].premium_total_ppm__c * 12;
    }
    if (
      featuredQuotes[jewelry] &&
      Object.keys(featuredQuotes[jewelry]).length
    ) {
      total +=
        featuredQuotes[jewelry].premium_total__c ||
        featuredQuotes[jewelry].premium_total_ppm__c * 12;
    }
    if (
      featuredQuotes.security &&
      !isEmpty(featuredQuotes.security) &&
      showVivintSmartHomeQuote
    ) {
      total += featuredQuotes.security.premium_total_ppm__c * 12;
    }
    return total;
  };

  const getFeaturedQuote = (lob) => {
    try {
      const unBundledHomeQuote = quotes[home].length ? quotes[home][0] : null;
      const unBundledAutoQuote = quotes[auto].length ? quotes[auto][0] : null;
      const isBundledQuote =
        getLineOfBusiness(session.line_of_business) === "HomeAuto";
      let featuredQuote = null;
      if (lob === home)
        featuredQuote = isBundledQuote
          ? quotes[bundle].length
            ? quotes[bundle][0].homeQuote
            : null
          : unBundledHomeQuote;
      else if (lob === auto)
        featuredQuote = isBundledQuote
          ? quotes[bundle].length
            ? quotes[bundle][0].autoQuote
            : null
          : unBundledAutoQuote;
      else if (lob === lifePrimary || lob === lifeSpouse) {
        const lifeQuotes = getPrimaryAndSpouseLifeQuotes(quotes);
        featuredQuote = lifeQuotes[lob][0];
      } else {
        featuredQuote = quotes[lob]?.length ? quotes[lob][0] : null;
        return featuredQuote;
      }
      if (featuredQuote && featuredQuote.premium_total__c) return featuredQuote;
      else return null;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setFeaturedQuotes({
      home: getFeaturedQuote(home),
      auto: getFeaturedQuote(auto),
      flood: getFeaturedQuote(flood),
      lifePrimary: getFeaturedQuote(lifePrimary),
      lifeSpouse: getFeaturedQuote(lifeSpouse),
      jewelry: getFeaturedQuote(jewelry),
      security: getFeaturedQuote(security),
    });
    // eslint-disable-next-line
  }, [quotes]);

  useEffect(() => {
    if (
      Object.keys(getFeaturedQuote(home) || {}).length &&
      Object.keys(getFeaturedQuote(auto) || {}).length
    ) {
      const featuredHomeQuote = featuredQuotes[home];
      const featuredAutoQuote = featuredQuotes[auto];

      if (
        featuredHomeQuote?.company_client_id__c === companyIds["Branch"] &&
        featuredAutoQuote?.company_client_id__c === companyIds["Branch"]
      ) {
        setQuoteToBind("bundle");
      } else {
        setQuoteToBind("");
      }
    }
    // eslint-disable-next-line
  }, [quotes, featuredQuotes.home, featuredQuotes.auto]);

  return (
    <Col xs={12} className="mt-5">
      <CustomText fontSize="medium" fontWeight={550}>
        Recommended
      </CustomText>
      <div className="d-flex justify-content-left Recommended">
        <RecommendedUnderline src={recommendedUnderline} />
      </div>

      <QuotesContainer className="mb-5">
        {hasHomeQuote ? (
          hasFeaturedHomeQuote ? (
            <Quote
              ckb={ckbs}
              quote={featuredQuotes.home}
              handleRemoveQuote={handleRemoveQuote}
              lob={home}
              featuredQuote
              coverages={getCoverages(home, featuredQuotes.home)}
              quoteToBind={quoteToBind}
            />
          ) : (
            <QuoteSelect
              lob={home}
              lobDescription="Home"
              getRecommendedCarrierList={getRecommendedCarrierList}
              handleSelectedQuote={handleSelectedQuote}
            />
          )
        ) : (
          <QuotePlaceHolder lob={home} />
        )}
        {!shouldHideAutoRow && <QuoteSeperator />}
        {!shouldHideAutoRow &&
          (hasAutoQuote ? (
            hasFeaturedAutoQuote ? (
              <Quote
                ckb={ckbs}
                quote={featuredQuotes.auto}
                lob={auto}
                handleRemoveQuote={handleRemoveQuote}
                featuredQuote
                coverages={getCoverages(auto)}
                quoteToBind={quoteToBind}
              />
            ) : (
              <QuoteSelect
                lob={auto}
                lobDescription="Auto"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )
          ) : (
            <QuotePlaceHolder lob={auto} />
          ))}
        {featuredQuotes.flood && (
          <>
            <QuoteSeperator />
            {Object.keys(featuredQuotes[flood]).length ? (
              <Quote
                ckb={ckbs}
                quote={featuredQuotes.flood}
                lob={flood}
                featuredQuote
                handleRemoveQuote={handleRemoveQuote}
                coverages={getCoverages(flood, featuredQuotes.flood)}
                shouldDisplayFloodZone
              />
            ) : (
              <QuoteSelect
                lob={flood}
                lobDescription="Flood"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )}
          </>
        )}
        {featuredQuotes.lifePrimary && (
          <>
            <QuoteSeperator />
            {Object.keys(featuredQuotes[lifePrimary]).length ? (
              <Quote
                quote={featuredQuotes.lifePrimary}
                lob={lifePrimary}
                ckb={ckbs}
                handleRemoveQuote={handleRemoveQuote}
                featuredQuote
                coverages={getCoverages(life, featuredQuotes.lifePrimary)}
              />
            ) : (
              <QuoteSelect
                lob={lifePrimary}
                lobDescription="Life Primary"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )}
          </>
        )}
        {featuredQuotes.lifeSpouse && (
          <>
            <QuoteSeperator />
            {Object.keys(featuredQuotes[lifeSpouse]).length ? (
              <Quote
                quote={featuredQuotes.lifeSpouse}
                lob={lifeSpouse}
                handleRemoveQuote={handleRemoveQuote}
                featuredQuote
                ckb={ckbs}
                coverages={getCoverages(life, featuredQuotes.lifeSpouse)}
              />
            ) : (
              <QuoteSelect
                lob={lifeSpouse}
                lobDescription="Life Spouse"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )}
          </>
        )}
        {featuredQuotes.jewelry && (
          <>
            <QuoteSeperator />
            {Object.keys(featuredQuotes[jewelry]).length ? (
              <Quote
                quote={featuredQuotes.jewelry}
                lob={jewelry}
                ckb={ckbs}
                handleRemoveQuote={handleRemoveQuote}
                featuredQuote
                coverages={getCoverages(home, featuredQuotes.jewelry)}
              />
            ) : (
              <QuoteSelect
                lob={jewelry}
                lobDescription="Jewelry"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )}
          </>
        )}
        {showVivintSmartHomeQuote && (
          <>
            <QuoteSeperator />
            {!isEmpty(featuredQuotes[security]) ? (
              <Quote
                quote={featuredQuotes.security}
                lob={security}
                handleRemoveQuote={handleRemoveQuote}
                featuredQuote
                setShowSmartHomePackageSelectionModal={
                  setShowSmartHomePackageSelectionModal
                }
              />
            ) : (
              <QuoteSelect
                lob={security}
                lobDescription="SmartHome"
                getRecommendedCarrierList={getRecommendedCarrierList}
                handleSelectedQuote={handleSelectedQuote}
              />
            )}
          </>
        )}
        <QuoteSeperator />
        <Row>
          <Col xs={10} className="text-end">
            <BundleTotalText className="me-3 mt-2">
              <span>{`Total: `}</span>
              <Price
                className="d-none"
                value={getBundleTotal()}
                currency={"$"}
                frequency="yearly"
                color="#2D424D"
              />
            </BundleTotalText>
          </Col>
        </Row>
      </QuotesContainer>
    </Col>
  );
};

RecommendedQuotes.propTypes = {
  quotes: PropTypes.object.isRequired,
};

export default RecommendedQuotes;
