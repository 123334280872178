/* eslint-disable no-template-curly-in-string */

import * as yup from "yup";

import {
  MAX_DWELLING_COVERAGE,
  MAX_DRIVERS_ALLOWED,
  MAX_JEWLERY_VALUE,
  MIN_DWELLING_COVERAGE,
  MAX_CHARS_AGENT_NOTES,
  NAME_REGEX,
} from "../constants";
import {
  convertToCurrency,
  convertToDollars,
  validEmailRegex,
  validPhoneRegex,
} from "./strings";

import { isDwelling } from "../utils";
import { isOlderThan30 } from "./misc";
import moment from "moment";
import store from "../redux/store";

const thisYear = moment().year();
const today = new Date(moment().toDate().toDateString());
const sixtyDaysInFuture = moment().add(60, "days").toDate();

const getRoofValidations = (isHome = false) => {
  return isHome
    ? {
        roofMaterial: yup.string().required("Please update Roof Materials"),
        roofReplaced: yup
          .number()
          .required("Please enter the year")
          .max(
            thisYear,
            "Roof Constructed / Replaced year cannot be in the future"
          )
          .test({
            name: "yearBuiltTooLate",
            exclusive: false,
            message:
              "Year Roof Constructed / Replaced cannot be prior to Year Built.",
            test(value) {
              return value >= this.parent.yearBuilt;
            },
          }),
      }
    : {};
};

export const getPropertyValidationSchema = ({
  isHome,
  isRenterPolicy,
  isCondoPolicy,
  showFoundationfield = false,
}) => {
  const contact = store.getState().contact;
  if (isHome || isCondoPolicy) {
    return yup.object().shape({
      squareFoot: yup
        .number()
        .min(1, "This value must be greater than zero")
        .max(99999, "Please enter sq. footage less than or equal to 99999")
        .required("Please update Square Footage"),
      exteriorWalls: yup.string().required("Please update Exterior Walls"),
      yearBuilt: yup
        .number()
        .required("Please update Year Built")
        .min(1600, "Year Built cannot be prior to ${min}")
        .max(thisYear, "Year Built cannot be in the future"),
      ...getRoofValidations(isHome),
      numStories: yup.string().required("Please update Number of Stories"),
      electrical: yup.number().when("yearBuilt", {
        is: (yearBuilt) => isOlderThan30(yearBuilt),
        then: (schema) =>
          schema
            .required("Please enter the year")
            .max(thisYear, "Year cannot be in the future")
            .test({
              name: "yearBuiltTooLate",
              exclusive: false,
              message:
                "Year Electrical replaced cannot be prior to Year Built.",
              test(value) {
                return value >= this.parent.yearBuilt;
              },
            }),
      }),
      plumbing: yup.number().when("yearBuilt", {
        is: (yearBuilt) => isOlderThan30(yearBuilt),
        then: (schema) =>
          schema
            .required("Please enter the year")
            .max(thisYear, "Year cannot be in the future")
            .test({
              name: "yearBuiltTooLate",
              exclusive: false,
              message: "Year Plumbing replaced cannot be prior to Year Built.",
              test(value) {
                return value >= this.parent.yearBuilt;
              },
            }),
      }),
      heating: yup.number().when("yearBuilt", {
        is: (yearBuilt) => isOlderThan30(yearBuilt),
        then: (schema) =>
          schema
            .required("Please enter the year")
            .max(thisYear, "Year cannot be in the future")
            .test({
              name: "yearBuiltTooLate",
              exclusive: false,
              message: "Year Heating replaced cannot be prior to Year Built.",
              test(value) {
                return value >= this.parent.yearBuilt;
              },
            }),
      }),
      effectiveDate: yup
        .date()
        // so that the error message can display correctly if value is null
        .nullable()
        .required("Please enter Effective Date (MM/DD/YYYY)")
        .min(today, "Effective Date cannot be in past")
        .max(
          sixtyDaysInFuture,
          "Effective Date cannot be more than 60 days in the future"
        ),
      ...dwellingCoverageValidation({ isHome, isCondoPolicy }),
      valueJewelry: yup.number().when("yearBuilt", {
        is: () => !isDwelling(),
        then: (schema) =>
          schema.max(
            MAX_JEWLERY_VALUE,
            `Jewelry Value cannot exceed ${MAX_JEWLERY_VALUE}`
          ),
      }),
      aopDeductible: yup.string().required("Please update AOP Deductible."),
      windHailDeductible: yup
        .string()
        .required("Please update Wind/Hail Deductible"),
      occupancy: yup.string().required("Please update Occupancy"),
      ...(!contact.isReshop
        ? {
            newPurchase: yup
              .string()
              .nullable()
              .required(`Please update New ${contact.selectedPolicy} Purchase`),
          }
        : {}),
      foundationType:
        showFoundationfield &&
        !isCondoPolicy &&
        yup.string().required("Please update Foundation Type"),
    });
  } else {
    // renter's or dwelling policy
    return yup.object().shape({
      effectiveDate: yup
        .date()
        // so that the error message can display correctly if value is null
        .nullable()
        .required("Please enter Effective Date (MM/DD/YYYY)")
        .min(today, "Effective Date cannot be in past")
        .max(
          sixtyDaysInFuture,
          "Effective Date cannot be more than 60 days in the future"
        ),

      valueJewelry: yup
        .number()
        .max(
          MAX_JEWLERY_VALUE,
          `Jewelry Value cannot exceed ${MAX_JEWLERY_VALUE}`
        ),
      personalPropertyCoverage: yup
        .number()
        .required("Please enter Personal Property")
        .min(
          20_000,
          ({ min }) =>
            `Personal Property Coverage cannot be less than ${convertToDollars(
              min
            )}`
        )
        .max(
          200_000,
          ({ max }) =>
            `Personal Property Coverage cannot exceed ${convertToDollars(max)}`
        ),

      ...(!isRenterPolicy && !contact.isReshop
        ? {
            newPurchase: yup
              .string()
              .nullable()
              .required(`Please update New ${contact.selectedPolicy} Purchase`),
          }
        : {}),

      ...dwellingCoverageValidation({ isHome, isCondoPolicy }),
    });
  }
};

const dwellingCoverageValidation = ({ isHome, isCondoPolicy }) => {
  return isHome || isCondoPolicy
    ? {
        dwellingCoverage: yup
          .number()
          .min(
            MIN_DWELLING_COVERAGE,
            `Dwelling Coverage cannot be less than $${convertToCurrency(
              MIN_DWELLING_COVERAGE
            )}`
          )
          .max(
            MAX_DWELLING_COVERAGE,
            `Dwelling Coverage cannot exceed $${convertToCurrency(
              MAX_DWELLING_COVERAGE
            )}`
          )
          .required("Please update Dwelling Coverage"),
      }
    : {};
};

export const autoDetailsValidationSchema = yup.object().shape({
  prior_carrier: yup.string().required("Please select Prior Carrier"),
  years_with_prior_carrier: yup.number().when("prior_carrier", {
    is: (prior_carrier) => prior_carrier !== "No Prior Insurance",
    then: yup
      .number()
      .required("Please enter Years with Carrier")
      .min(0, "Years with Carrier cannot be negative")
      .max(99, "Years with Carrier cannot be this long"),
  }),
  prior_liability_limits: yup.string().when("prior_carrier", {
    is: (prior_carrier) => prior_carrier !== "No Prior Insurance",
    then: yup.string().required("Please select Prior Liability Limits"),
  }),
  effective_date: yup
    .date()
    // so that the error message can display correctly if value is null
    .nullable()
    .required("Please enter Effective Date")
    .min(today, "Effective Date cannot be in the past")
    .max(
      sixtyDaysInFuture,
      "Effective Date cannot be more than 60 days in the future"
    ),
  bodily_injury: yup
    .string()
    .required("Please select Bodily Injury")
    .nullable(),
  incidents: yup.array().of(
    yup
      .object()
      .nullable()
      .shape({
        date_of_incident__c: yup
          .date("Please enter Date")
          .nullable()
          .required("Please enter Date")
          .max(today, "Future date invalid"),
        driver__c: yup.string().required("Please select Driver").nullable(),
        amount__c: yup
          .number()
          .nullable()
          .when("type", {
            is: "Comp Loss",
            then: (schema) =>
              schema
                .required("Please enter Amount")
                .min(0, "Amount cannot be negative"),
          }),
        property_damage_amount__c: yup
          .number()
          .nullable()
          .when("type", {
            is: "Accident",
            then: (schema) =>
              schema
                .min(0, "Property Damage Amount cannot be negative")
                .when(
                  [
                    "bodily_injury_amount__c",
                    "collision_amount__c",
                    "medpay_amount__c",
                  ],
                  {
                    is: (
                      bodily_injury_amount__c,
                      collision_amount__c,
                      medpay_amount__c
                    ) =>
                      !bodily_injury_amount__c &&
                      !collision_amount__c &&
                      !medpay_amount__c,
                    then: (schema) =>
                      schema
                        .typeError("Please enter one of the Amounts")
                        .required("Please enter one of the Amounts"),
                  }
                ),
          }),
      })
  ),
});

// test if the combination of keys of an array of objects is unique
const uniquePropertiesCombinationTest = function ({
  value,
  propertyNames,
  message,
  errorPropertyName,
  serializationOptions = {},
}) {
  if (
    this.parent
      // don't compare to self (by reference)
      .filter((item) => item !== value)
      .some((item) =>
        propertyNames.every((propertyName) => {
          // do not detect duplicates if either value is falsy
          if (!value[propertyName] || !item[propertyName]) return false;
          const defaultSerialize =
            typeof value[propertyName] === "string" &&
            typeof item[propertyName] === "string"
              ? (input) => input.trim()
              : (input) => input;
          const serialize =
            serializationOptions[propertyName] ?? defaultSerialize;
          return (
            serialize(item[propertyName]) === serialize(value[propertyName])
          );
        })
      )
  ) {
    throw this.createError({
      // where to put the error in the error object
      path: `${this.path}.${errorPropertyName ?? propertyNames[0]}`,
      message,
    });
  }
  return true;
};

// custom validation method
// https://github.com/jquense/yup#addmethodschematype-schema-name-string-method--schema-void
yup.addMethod(
  yup.object,
  // method name to add to object schema
  "uniquePropertyCombination",
  function ({
    propertyNames,
    message,
    errorPropertyName,
    serializationOptions,
  }) {
    return this.test("unique", message, function (value) {
      return uniquePropertiesCombinationTest.call(this, {
        value,
        propertyNames,
        message,
        errorPropertyName,
        serializationOptions,
      });
    });
  }
);

export const driverValidationSchema = yup.object({
  items: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          firstName: yup
            .string()
            .required("Please update First Name")
            // Matches backend validation for now
            // to update to support non-English names in the future
            .test({
              name: "validName",
              exclusive: false,
              message: "Names cannot contain special characters.",
              test(value) {
                return NAME_REGEX.test(value);
              },
            }),
          lastName: yup
            .string()
            .required("Please update Last Name")
            // Matches backend validation for now
            // to update to support non-English names in the future
            .test({
              name: "validName",
              exclusive: false,
              message: "Names cannot contain special characters.",
              test(value) {
                return NAME_REGEX.test(value);
              },
            }),
          dob: yup
            .date()
            // so that 2 digit years are interpreted correctly
            // https://github.com/jquense/yup/issues/325#issuecomment-534941499
            .transform((_, rawValue) => {
              const isValidDate = moment(rawValue, ["YYYY-MM-DD"]).isValid();
              return !isValidDate
                ? undefined
                : moment(rawValue, ["YYYY-MM-DD"]).toDate();
            })
            .required("Please update Date of Birth")
            .min("1901-01-01", "Driver cannot be born before 1901")
            .max(today, "Date of Birth cannot be in the future")
            .test({
              name: "minAge",
              exclusive: false,
              message: "Driver must be 14 or older",
              test(value) {
                return moment().diff(value, "years") >= 14;
              },
            }),
          gender: yup.string().nullable().required("Please select Gender"),
          dLState: yup
            .string()
            .nullable()
            .required("Please select License State"),
          education: yup
            .string()
            .nullable()
            .required("Please select Education"),
          occupation: yup
            .string()
            .nullable()
            .required("Please select Occupation"),
        })
        .uniquePropertyCombination({
          propertyNames: ["firstName", "dob"],
          message: "Duplicate First Name & DOB found",
          serializationOptions: {
            dob: (dob) => moment(dob).format("YYYY-MM-DD"),
          },
        })
        .uniquePropertyCombination({
          propertyNames: ["driverLicense"],
          message: "Drivers License must be unique",
        })
    )
    .max(
      MAX_DRIVERS_ALLOWED,
      `You have reached the limit of ${MAX_DRIVERS_ALLOWED} Drivers`
    ),
});

// displayYearMakeModelEntry: the fields Year Make Model are displayed
export const vehicleValidationSchema = yup.object({
  items: yup.array().of(
    yup
      .object()
      .shape({
        Vin: yup
          .string()
          .nullable()
          .required("Please enter a valid 17-character VIN")
          .length(17, "Please enter a valid 17-character VIN")
          .when(["isVinFound", "Year", "Make", "Model"], {
            is: (isVinFound, Year, Make, Model) =>
              !isVinFound && !(Year && Make && Model),
            then: (schema) =>
              // if the user searches a VIN but it's not found, it's invalid
              // user must enter year make and model manually
              schema.oneOf([], "VIN not found"),
          }),
        Year: yup
          .string()
          .nullable()
          .when("displayYearMakeModelEntry", {
            is: true,
            then: (schema) => schema.required("Please select Year"),
          }),
        Make: yup
          .string()
          .nullable()
          .when("displayYearMakeModelEntry", {
            is: true,
            then: (schema) => schema.required("Please select Make"),
          }),
        Model: yup
          .string()
          .nullable()
          .when("displayYearMakeModelEntry", {
            is: true,
            then: (schema) => schema.required("Please select Model"),
          }),
      })
      .uniquePropertyCombination({
        propertyNames: ["Vin"],
        message: "VIN must be unique",
      })
  ),
});

export const vivintContactDataValidationSchema = yup.object({
  contactId: yup.string().required("Please select a contact"),
  phone: yup
    .string()
    .required("Please enter a valid mobile number")
    .matches(validPhoneRegex, "Please enter a valid mobile number"),
  email: yup
    .string()
    .required("Please enter a valid email address")
    .matches(validEmailRegex, "Please enter a valid email address"),
});

export const notesValidationSchema = yup.object({
  agentNotes: yup
    .string()
    .max(
      MAX_CHARS_AGENT_NOTES,
      `You have reached the character limit. Only the first ${Intl.NumberFormat(
        "en-US"
      ).format(MAX_CHARS_AGENT_NOTES)} characters will be saved.`
    ),
});

const dateValidationForResubmit = yup
  .date()
  .nullable()
  .required("Please enter a valid date")
  .min(today, "Date cannot be in past")
  .max(sixtyDaysInFuture, "Date cannot be more than 60 days in the future");

export const getResubmitValidationSchema = ({
  isHomePolicySelected,
  isAutoPolicySelected,
}) => {
  if (isHomePolicySelected && isAutoPolicySelected) {
    return yup.object().shape({
      homeEffectiveDate: dateValidationForResubmit,
      autoEffectiveDate: dateValidationForResubmit,
    });
  }
  if (isHomePolicySelected) {
    return yup.object().shape({
      homeEffectiveDate: dateValidationForResubmit,
    });
  }
  if (isAutoPolicySelected) {
    return yup.object().shape({
      autoEffectiveDate: dateValidationForResubmit,
    });
  }
};
