import styled from "styled-components";
import { Spinner as BootstrapSpinner } from "react-bootstrap";

const SpinnerWrapper = styled.div`
  color: ${(props) => props.theme.tertiary};
`;

const Spinner = () => (
  <SpinnerWrapper>
    <BootstrapSpinner animation="border" size="small" />
  </SpinnerWrapper>
);

export default Spinner;
