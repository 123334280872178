import { ADD_CONTACT, CLEAR_CONTACT } from "../constants";

import { ssnPattern } from "../../constants";

export const addContact = (contact) => {
  return {
    type: ADD_CONTACT,
    payload: {
      ...contact,
      ...(contact.ssn && ssnPattern.test(contact.ssn)
        ? {
            ssn: contact.ssn,
          }
        : {}),
      ...(contact.spouseSsn && ssnPattern.test(contact.spouseSsn)
        ? {
            spouseSsn: contact.spouseSsn,
          }
        : {}),
    },
  };
};

export const clearContact = () => {
  return {
    type: CLEAR_CONTACT,
  };
};
