// @ts-ignore
import * as AppTypes from "./app.d.ts"; // eslint-disable-line no-unused-vars

import {
  ClientReferral,
  DEFAULT_PRIOR_CARRIER,
  ERROR_TYPES_SORTED_IN_RESPONSES,
  HOME_LOB_NAMES,
  INCIDENT_TYPE_OPTIONS_MAP,
  INCIDENT_TYPE_RECORD_ID_MAP,
  LOB_ICON_MAP,
  NO_PRIOR_INSURANCE,
  QUOTE_TYPES,
  ReferralPartner,
  STATES_WITH_NON_BINARY_GENDER_OPTIONS,
  TABS_LOB,
  TAB_ICON_MAP,
  bodilyInjuryToPropertyDamageMap,
  mapOccupancyToPolicyForm,
  policyFormTypes,
  priorCarrierNames,
  security,
} from "./constants";
import { capitalizeFirstChars, getUMPDCoverage } from "./utils/strings";
import { getAccountDetailsinSf, updateAccountinPg } from "./services/account";
import { getQuoteRequest, updateQuote } from "./services/quote";

import { addContact } from "./redux/actions/contact";
import { addCoverages } from "./redux/actions/coverages";
import { addDrivers } from "./redux/actions/driver";
import { addProperty } from "./redux/actions/property";
import { addSession } from "./redux/actions/session";
import { addVehicles } from "./redux/actions/vehicle";
import capitalize from "lodash/capitalize";
import { getVehicleByType } from "./services/vehicles";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import store from "./redux/store";

/**
 * @param {Pick<
 *   AppTypes.QuoteResponse,
 *   "error_type__c" | "premium_total__c"
 * >} [quote]
 */
export const isQuoteSuccessful = (quote) => {
  if (!quote) return false;
  if (!quote.error_type__c) return true;
  return (
    quote.error_type__c.toLowerCase() === "success" &&
    quote.premium_total__c !== 0
  );
};

/** @param {Pick<AppTypes.QuoteResponse, "line_of_business__c">} input */
export const isAuto = ({ line_of_business__c }) =>
  /auto/i.test(line_of_business__c);

/** @param {AppTypes.QuoteResponse[]} [quotes] */
export const sortQuotes = (quotes) => quotes?.sort(compareQuoteResponses);

/**
 * @param {AppTypes.QuoteResponse} [a]
 * @param {AppTypes.QuoteResponse} [b]
 */
export const compareQuoteResponses = (a, b) => {
  // handle nulls
  if (!a && !b) return 0;
  if (a && !b) return -1;
  if (!a && b) return 1;

  // compare successes
  if (isQuoteSuccessful(a) && !isQuoteSuccessful(b)) return -1;
  if (!isQuoteSuccessful(a) && isQuoteSuccessful(b)) return 1;

  // compare prices
  if (isAuto(a) && isAuto(b)) {
    return compareNumbers(getAutoPrice(a), getAutoPrice(b));
  }

  return compareNumbers(getPrice(a), getPrice(b));
};

/**
 * @param {Pick<
 *   AppTypes.QuoteResponse,
 *   "premium_total__c" | "term__c" | "premium_total_ppm__c"
 * >} quote
 */
export const getAutoPrice = (quote) => {
  const totalTerm = quote.term__c ? parseInt(quote.term__c) : null;

  if (quote.premium_total__c && totalTerm) {
    return (quote.premium_total__c / totalTerm) * 12;
  } else {
    return quote.premium_total_ppm__c * 12;
  }
};

/**
 * @param {Pick<
 *   AppTypes.QuoteResponse,
 *   "premium_total_pif__c" | "premium_total__c"
 * >} input
 */
export const getPrice = ({ premium_total_pif__c, premium_total__c }) =>
  premium_total_pif__c ?? premium_total__c;

/**
 * @param {number} [numberA=Infinity] Default is `Infinity`
 * @param {number} [numberB=Infinity] Default is `Infinity`
 */
export const compareNumbers = (numberA = Infinity, numberB = Infinity) => {
  numberA = numberA ?? Infinity;
  numberB = numberB ?? Infinity;

  return numberA - numberB;
};

const sortQuotesByErrorType = (quotes) => {
  return quotes.sort((a, b) => {
    const aHomeIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
      (a.homeQuote.error_type__c ?? "success").includes(status)
    );
    const aAutoIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
      (a.autoQuote.error_type__c ?? "success").includes(status)
    );
    const bHomeIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
      (b.homeQuote.error_type__c ?? "success").includes(status)
    );
    const bAutoIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
      (b.autoQuote.error_type__c ?? "success").includes(status)
    );

    const aMinIndex = Math.min(aHomeIndex, aAutoIndex);
    const bMinIndex = Math.min(bHomeIndex, bAutoIndex);

    return aMinIndex - bMinIndex;
  });
};

export const sortErrorTypeInQuotes = (quotes) => {
  const newQuotes = { ...quotes };

  for (const key in quotes) {
    if (key === "bundle") {
      newQuotes[key] = sortQuotesByErrorType([...quotes[key]]);
    } else if (key === "homeCoverages" || key === "autoCoverages") {
      continue;
    } else {
      newQuotes[key] = [...quotes[key]].sort((a, b) => {
        const aIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
          (a.error_type__c ?? "success").includes(status)
        );
        const bIndex = ERROR_TYPES_SORTED_IN_RESPONSES.findIndex((status) =>
          (b.error_type__c ?? "success").includes(status)
        );

        return aIndex - bIndex;
      });
    }
  }
  return newQuotes;
};

const getNumUnsuccessfulQuotes = ({ homeQuote, autoQuote }) =>
  !isQuoteSuccessful(homeQuote) + !isQuoteSuccessful(autoQuote);

export const bundlesSortComparison = (a, b) => {
  const numSuccessfulQuotesDiff =
    getNumUnsuccessfulQuotes(a) - getNumUnsuccessfulQuotes(b);
  if (numSuccessfulQuotesDiff !== 0) return numSuccessfulQuotesDiff;
  return a.bundledPrice - b.bundledPrice;
};

export const checkForNullOrUndefinedSessionData = (sessionData) => {
  if (!sessionData.coverages_data) sessionData.coverages_data = {};
  if (!sessionData.contact_data) sessionData.contact_data = {};
  if (!sessionData.property_data) sessionData.property_data = [];
  if (!sessionData.vehicles_data) sessionData.vehicles_data = [];
  if (!sessionData.drivers_data) sessionData.drivers_data = [];
  if (!sessionData.final_quotes_data) sessionData.final_quotes_data = [];
};

export const isValidJSONString = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};

export const mapSessionDataToStore = (sessionData) => {
  const sessionDataKeyObj = [
    { key: "coverages_data", action: addCoverages },
    { key: "contact_data", action: addContact },
    { key: "property_data", action: addProperty },
    { key: "drivers_data", action: addDrivers },
    { key: "vehicles_data", action: addVehicles },
  ];
  sessionDataKeyObj.forEach(({ key, action }) => {
    if (sessionData[key] && isValidJSONString(sessionData[key])) {
      sessionData[key] = JSON.parse(sessionData[key]);
      store.dispatch(action(sessionData[key]));
    }
  });
  store.dispatch(addSession(sessionData));
};

export const formatSSN = (ssn) => {
  if (/^\d{3}-\d{2}-\d{4}$/.test(ssn)) {
    return ssn;
  }
  if (/^\d{9}$/.test(ssn)) {
    return `${ssn.substr(0, 3)}-${ssn.substr(3, 2)}-${ssn.substr(5)}`;
  }
  return null;
};

export const isDwelling = () => {
  const { property } = store.getState();
  return (
    mapOccupancyToPolicyForm[property?.occupancy?.toLowerCase()] ===
    policyFormTypes.DP3
  );
};

export const getLobIcon = (lob) => {
  const session = store.getState().session;
  const sessionLob = session?.line_of_business;
  switch (lob) {
    case "home":
      if (sessionLob?.includes("Home")) {
        return isDwelling() ? LOB_ICON_MAP["dwelling"] : LOB_ICON_MAP["home"];
      } else if (sessionLob?.includes("Renter")) {
        return LOB_ICON_MAP["renters"];
      } else if (sessionLob?.includes("Condo")) {
        return LOB_ICON_MAP["condo"];
      } else {
        return LOB_ICON_MAP["home"];
      }
    case security:
      return LOB_ICON_MAP[security];
    default:
      return LOB_ICON_MAP[lob];
  }
};

const getHomeDetailsIcon = () => {
  const session = store.getState().session;
  if (session.line_of_business.includes("Home")) {
    return isDwelling() ? "Dwelling" : "Home";
  } else if (session.line_of_business.includes("Renter")) {
    return "Renters";
  } else if (session.line_of_business.includes("Condo")) {
    return "Condo";
  } else {
    return "Home";
  }
};

export const getTabIcon = (tab, status) =>
  tab === "Home Details"
    ? TAB_ICON_MAP[status][tab][getHomeDetailsIcon()]
    : TAB_ICON_MAP[status][tab];

export const getPageNameFromUrl = () => {
  const currentUrl = window.location.href;
  const pageName = currentUrl
    .substring(currentUrl.lastIndexOf("/") + 1)
    // remove queries and id in URL
    .split("?")[0]
    .split("#")[0];
  return pageName;
};

export const getTabStatus = ({ tab, index, currentIndex }) => {
  const {
    session: { line_of_business: lob },
  } = store.getState();
  const contact = store.getState().contact;

  const {
    isReshop,
    isHomePolicySelected,
    addSkippedAutoQuote,
    addSkippedHomeQuote,
  } = contact;

  // special case for reshop flow policy tab
  if (isReshop && isHomePolicySelected && tab === "Policy") return "disabled";

  if (
    !HOME_LOB_NAMES.some((name) => lob?.toLowerCase().includes(name)) &&
    TABS_LOB.home.includes(tab)
  ) {
    return "disabled";
  }
  if (
    lob &&
    !lob.toLowerCase().includes("auto") &&
    TABS_LOB.auto.includes(tab)
  ) {
    return "disabled";
  }

  const isCurrent = index === currentIndex;
  let isDisabled = index > currentIndex;

  // none of the LOBs is skipped
  const isSkippedLobAdded = addSkippedHomeQuote || addSkippedAutoQuote;

  // going back to the previous tabs shouldn't disable tabs unless something is changed
  if (!isSkippedLobAdded && contact.quotesSubmitted && !contact.fieldsEdited) {
    isDisabled = false;
  }
  const status = isDisabled ? "disabled" : isCurrent ? "current" : "normal";
  return status;
};

export const getKeyInMapByValue = (map, input) => {
  for (let [key, value] of map.entries()) {
    if (value === input) {
      return key;
    }
  }
  return null;
};

export const getGenderOptions = () => {
  const state = store.getState().contact.administrative_area_level_1;
  const genderOptions = [
    { label: "- Select -", value: "" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  for (const { value, states } of STATES_WITH_NON_BINARY_GENDER_OPTIONS) {
    if (states.includes(state)) {
      genderOptions.push({ label: value, value });
    }
  }
  return genderOptions;
};

export const calculateBundlePrice = (homeQuote, autoQuote) => {
  const isSuccess = ({ error_type__c }) => /^success$/i.test(error_type__c);

  if (!isSuccess(homeQuote) || !isSuccess(autoQuote)) {
    return "Not Available";
  }
  const homeTotal =
    homeQuote.premium_total_pif__c || homeQuote.premium_total__c;
  const autoTotal = getAutoPrice(autoQuote);
  return homeTotal + autoTotal;
};

export const getPriorInsuranceInfo = async () => {
  const { autoQuoteId } = store.getState().contact;
  const data = (await getQuoteRequest(autoQuoteId)).data[0];
  return [
    { name: "Carrier", value: data.prior_policy_insurer_name__c },
    {
      name: "Expiration Date",
      value: data.data_source_current_auto_end_date__c,
    },
    { name: "BI", value: data.data_source_prior_bi__c },
    { name: "PD", value: data.data_source_prior_pd__c },
    { name: "UMBI", value: data.data_source_prior_umbi__c },
    { name: "PIP", value: data.data_source_prior_pip__c },
  ];
};

export const getDriverOptions = () => {
  const { drivers } = store.getState();
  return drivers
    .filter((d) => d.selected)
    .map((driver) => {
      return {
        value: driver.driverId,
        label: `${driver.firstName} (${moment().diff(driver.dob, "years")})`,
      };
    });
};

export const getDefaultIncident = (type) => {
  const driverOptions = getDriverOptions();
  const { contact, vehicles } = store.getState();
  const recordType = INCIDENT_TYPE_RECORD_ID_MAP[type];
  let additionalValues = {};
  if (type === "Accident") {
    additionalValues = {
      property_damage_amount__c: 0,
      bodily_injury_amount__c: 0,
      collision_amount__c: 0,
      medpay_amount__c: 0,
    };
  } else if (type === "Comp Loss") {
    additionalValues = {
      amount__c: 0,
    };
  }
  return {
    type,
    date_of_incident__c: "",
    driver__c: driverOptions.length ? driverOptions[0].value : "",
    description__c: INCIDENT_TYPE_OPTIONS_MAP[type][0].value,
    recordtypeid: recordType,
    vehicle_involved__c: vehicles[0].vehicleId,
    quote_request__c: contact.autoQuoteId,
    ...additionalValues,
  };
};

export const getDefaultEffectiveDate = () =>
  moment().add(15, "d").format("YYYY-MM-DD");

export const fetchAutoDetails = async () => {
  const { autoQuoteId, quotesSubmitted, fieldsEdited, isReshop } =
    store.getState().contact;
  const { autoCoveragesForReshop } = store.getState().coverages;
  const results = (await getQuoteRequest(autoQuoteId)).data;
  const hasQuoteSubmittedOrEdited = quotesSubmitted || fieldsEdited;
  if (!results.length) return {};

  // https://goosehead.atlassian.net/browse/HQ-3468
  // added if sentence to load prior carrier info from last policy if it's a reshop
  // and no quotes are submitted and no fields are edited
  if (isReshop && !hasQuoteSubmittedOrEdited) {
    const carrierNamesFiltered = priorCarrierNames.filter(
      (carrier) =>
        carrier !== NO_PRIOR_INSURANCE && carrier !== DEFAULT_PRIOR_CARRIER
    );

    const carrierNamePreSelected =
      carrierNamesFiltered.find(
        (carrier) => carrier === autoCoveragesForReshop?.prior_insurance_carrier
      ) ?? DEFAULT_PRIOR_CARRIER;

    return {
      prior_carrier: carrierNamePreSelected,
      years_with_prior_carrier:
        autoCoveragesForReshop.years_with_prior_carrier ?? "",
      prior_liability_limits:
        autoCoveragesForReshop?.prior_liability_limits ?? "",
      months_with_prior_carrier:
        autoCoveragesForReshop?.months_with_prior_carrier ?? "",
    };
  }

  if (hasQuoteSubmittedOrEdited) {
    return {
      prior_carrier:
        results[0]?.prior_policy_insurer_name__c ??
        results[0]?.data_source_current_auto_carrier__c ??
        "",
      years_with_prior_carrier:
        results[0].years_with_prior_auto_carrier__c ?? "",
      prior_liability_limits: results[0].prior_liability_limits__c ?? "",
    };
  }

  return {
    prior_carrier: results[0].data_source_current_auto_carrier__c ?? "",
    years_with_prior_carrier: "",
    prior_liability_limits: "",
  };
};

export const getAutoQuotePayload = (values) => {
  const state = store.getState()?.property.administrative_area_level_1 ?? "";
  const noPriorInsurance = values.prior_carrier === NO_PRIOR_INSURANCE;
  return {
    data_source_current_auto_carrier__c: values.prior_carrier,
    prior_policy_insurer_name__c: values.prior_carrier,
    years_with_prior_auto_carrier__c: noPriorInsurance
      ? 0
      : values.years_with_prior_carrier || 0,
    months_with_prior_auto_carrier__c: noPriorInsurance
      ? 0
      : values.months_with_prior_carrier || 1,
    prior_liability_limits__c: noPriorInsurance
      ? ""
      : values.prior_liability_limits ?? "",
    effective_date__c: values.effective_date,
    bodily_injury__c: values.bodily_injury,
    uninsured_motorist__c: values.bodily_injury,
    property_damage__c: bodilyInjuryToPropertyDamageMap[values.bodily_injury],
    expiration_date__c: moment(values.effective_date)
      .add(1, "year")
      .format("YYYY-MM-DD"),
    prior_policy_expiration_date__c: values.effective_date,
    uninsured_motorist_property_damage__c: getUMPDCoverage(
      state,
      values.bodily_injury
    ),
  };
};

export const mapIncidentResponseToForm = ({
  date_of_violation__c,
  date_of_accident__c,
  date_of_comp_loss__c,
  ...incident
}) => {
  if (incident.recordtypeid === INCIDENT_TYPE_RECORD_ID_MAP["Violation"]) {
    return {
      ...incident,
      type: "Violation",
      date_of_incident__c: date_of_violation__c,
    };
  }
  if (incident.recordtypeid === INCIDENT_TYPE_RECORD_ID_MAP["Accident"]) {
    return {
      ...incident,
      type: "Accident",
      date_of_incident__c: date_of_accident__c,
    };
  }
  if (incident.recordtypeid === INCIDENT_TYPE_RECORD_ID_MAP["Comp Loss"]) {
    return {
      ...incident,
      type: "Comp Loss",
      date_of_incident__c: date_of_comp_loss__c,
    };
  }
  return null;
};

export const mapIncidentFormToPayload = ({
  date_of_incident__c,
  type,
  ...incident
}) => {
  if (type === "Violation") {
    return {
      ...incident,
      date_of_violation__c: date_of_incident__c,
    };
  }
  if (type === "Accident") {
    return {
      ...incident,
      date_of_accident__c: date_of_incident__c,
    };
  }
  if (type === "Comp Loss") {
    return {
      ...incident,
      date_of_comp_loss__c: date_of_incident__c,
    };
  }
  return null;
};

export const filterIncident = (incident) => {
  if (!incident) return false;
  const effectiveDate = moment(store.getState().property.effectiveDate);
  const fiveYearsBeforeEffectiveDate = effectiveDate.add(-5, "years");

  if (incident.type === "Violation") return true;
  if (incident.type === "Accident") {
    if (!incident.date_of_accident__c) return true;
    const incidentDate = moment(incident.date_of_accident__c);
    return incidentDate.isAfter(fiveYearsBeforeEffectiveDate);
  }
  if (incident.type === "Comp Loss") {
    if (!incident.date_of_comp_loss__c) return true;
    const incidentDate = moment(incident.date_of_comp_loss__c);
    return incidentDate.isAfter(fiveYearsBeforeEffectiveDate);
  }
  return true;
};

export const mapDriversToInput = (driver, fieldsToRemove = []) => {
  const { contact } = store.getState();

  const results = {
    education: "",
    occupation: "",
    ...driver,
    dob: moment(driver.dob).format("YYYY-MM-DD"),
    driverLicense: driver.driverLicense || "",
    dLState: driver.dLState ?? contact.administrative_area_level_1,
  };

  fieldsToRemove.forEach((field) => delete results[field]);

  return results;
};

export const mapVehiclesToInput = (vehicle) => {
  return {
    ...vehicle,
    Year: vehicle.Year.toString(),
    Model: vehicle.Model.toString(),
    displayYearMakeModelEntry: true,
    isVinFound: true,
  };
};

export const getDriverName = (driver) => {
  const suffix = driver.suffix ? `, ${capitalize(driver.suffix)}` : "";
  const name = `${driver.firstName} ${driver.lastName}${suffix}`;
  return name.trim();
};

export const getVehicleName = ({ Year, Make, Model }) => {
  if (!Year || !Make || !Model) return undefined;
  return `${Year} ${Make} ${Model}`
    .toUpperCase()
    .replace(/&amp;/g, "&")
    .replace(/amp;/g, "");
};

// TODO: simplify API response and change API method
export const getVehicleMakes = async (year) => {
  const options = (
    await getVehicleByType({
      requestType: "Makes",
      year,
    })
  ).data.vehicleData.getAutoMakesResponse.getAutoMakesResult.string;
  return options ?? [];
};

export const getVehicleModels = async (year, make) => {
  const options = (
    await getVehicleByType({
      requestType: "Models",
      year,
      make,
    })
  ).data.vehicleData.getAutoModelsResponse.getAutoModelsResult.string;
  if (!options) return [];
  // could be a list of strings or one string
  return typeof options === "string" ? [options] : options;
};

export const getDummyVin = async (year, make, model, existingVins = []) => {
  let options = (
    await getVehicleByType({
      requestType: "SubModels",
      year,
      make,
      model,
    })
  ).data.vehicleData.getAutoSubModelsResponse.getAutoSubModelsResult.string;
  if (!options) return "";
  // could be a list of strings or one string
  if (typeof options === "string") {
    options = [options];
  }
  let dummyVin = options[0].split("|")[1];

  // update dummy vin if it already exists
  // TODO: this is a temporary solution
  // as backend needs "111111" to identify dummy vin
  // need to find a better way to generate dummy vin with random digits
  // and stop identifying dummy vins with "111111"
  if (existingVins.includes(dummyVin)) {
    const vinsWithSameYearMakeModel = existingVins.filter(
      (vin) => vin.slice(0, -7) === dummyVin.slice(0, -7)
    );
    // potential issue on the back end if there are more than 9 cars
    // with the same year, make, model
    const largestLast7Digits = Math.max(
      ...vinsWithSameYearMakeModel.map((vin) => parseInt(vin.slice(-7)))
    );
    dummyVin = dummyVin.slice(0, -7) + (largestLast7Digits + 1).toString();
  }
  return dummyVin;
};
export const isVehicleEmpty = ({ Year, Make, Model }) =>
  !(Year && Make && Model);

export const setFieldsEdited = () => {
  const contact = store.getState().contact;
  if (contact.quotesSubmitted) {
    store.dispatch(addContact({ fieldsEdited: true, quotesSubmitted: false }));
  }
};

export const isFromContactPage = (driver) => {
  if (!driver.contactId) return false;
  const { contactId, spouseId } = store.getState().contact;
  return [contactId, spouseId].includes(driver.contactId);
};

export const handleUpdateAccount = async () => {
  const contact = store.getState().contact;
  const updatedAccount = {};
  const { leadSource, referredBy, mortgageeBinds = [] } = contact;

  if (leadSource) {
    if ([ReferralPartner, ClientReferral].includes(leadSource) && referredBy) {
      updatedAccount.referred_by__c = referredBy;

      if (contact.sfAccountId) {
        const sfAccountDetails = (
          await getAccountDetailsinSf(contact.sfAccountId)
        ).data;
        if (sfAccountDetails.referred_by__c) {
          updatedAccount.referred_by__c = sfAccountDetails.referred_by__c;
        }
      }
    }
  }

  if (mortgageeBinds.length) {
    updatedAccount.mortgagee_bind__c = mortgageeBinds.filter(
      (mb) =>
        !!mb.mortgagee_street_address__c &&
        !!mb.mortgagee_title__c &&
        !!mb.mortgagee_zip__c
    );
  }

  if (contact.agentNotes) {
    updatedAccount.quote_discovery_notes__c = contact.agentNotes;
  }
  if (contact.agentFeedback) {
    updatedAccount.rater_feedback__c = contact.agentFeedback;
  }
  if (!isEmpty(updatedAccount)) {
    await updateAccountinPg(contact.accountId, updatedAccount);
  }
};

export const handleUpdateHomeQuotes = async () => {
  const { contact, session } = store.getState();

  if (contact.homeQuoteId) {
    if (session.line_of_business.includes("Auto")) {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        quote_as_package__c: "Yes",
      });
    } else {
      await updateQuote({
        quoteId: contact.homeQuoteId,
        quote_as_package__c: "No",
      });
    }
  }
};

export const handleUpdateQuote = async (values) => {
  const { contact, session } = store.getState();

  await handleUpdateHomeQuotes();

  if (contact.autoQuoteId) {
    let autoQuotePayload = {
      quoteId: contact.autoQuoteId,
    };

    autoQuotePayload = {
      ...autoQuotePayload,
      ...getAutoQuotePayload(values),
    };

    if (session.line_of_business.indexOf("Auto") > 0 && !isDwelling()) {
      await updateQuote({
        ...autoQuotePayload,
        quote_as_package__c: "Yes",
      });
    } else {
      await updateQuote({
        ...autoQuotePayload,
        quote_as_package__c: "No",
      });
    }
  }
};

export const getQuoteTypeForResubmit = (
  isHomePolicySelected,
  isAutoPolicySelected
) => {
  if (isHomePolicySelected && isAutoPolicySelected) return QUOTE_TYPES.homeAuto;
  if (isAutoPolicySelected) return QUOTE_TYPES.auto;
  if (isHomePolicySelected) return QUOTE_TYPES.home;
};

export const getQuoteType = () => {
  const { session } = store.getState();
  const { line_of_business: lob } = session;
  const hasAuto = lob?.toLowerCase().includes("auto");
  const hasHome = HOME_LOB_NAMES.some((name) =>
    lob?.toLowerCase().includes(name)
  );
  if (hasAuto && hasHome) return QUOTE_TYPES.homeAuto;
  if (hasAuto) return QUOTE_TYPES.auto;
  if (hasHome) return QUOTE_TYPES.home;
};

/**
 * @param {string} header
 * @param {string | null} lob
 * @param {boolean} hasBundle
 * @returns {string | undefined}
 */
export const getRateListHeader = (header, lob) => {
  if (!lob) return header;
  if (lob.toLowerCase() === "home") return header;
  if (lob.toLowerCase() === "auto") return header;
  return;
};

/**
 * @param {| "Monoline Eligible"
 *   | "Package Required"
 *   | "Quote Manually"
 *   | "Package Required for Dwelling"
 *   | null} key
 * @param {string} lob
 * @returns {string}
 */
export const getQuoteHeaderTooltip = (key, lob) => {
  // notice: this is the *other* lob.
  // if it's auto, use home, and vice versa
  const otherLob = lob.toLowerCase() === "auto" ? "Home" : "Auto";
  const map = {
    "Monoline Eligible":
      "These quotes are eligible to be sold as monolines. No “package discount” is included in these rates.",
    "Package Required": `These quotes require a supporting ${otherLob} policy be issued. “Package discounts” are included in these rates.`,
    "Package Required for Dwelling":
      'These carriers require supporting business. Click "CKB" under the carrier’s logo to view Carrier Guidelines for more details.',
    "Dwelling Package Required":
      'These carriers require supporting business. Click "CKB" under the carrier’s logo to view Carrier Guidelines for more details.',
    "Quote Manually":
      "These carriers are not paired with Aviator. Click “Quote” to open their websites and being a quote from scratch.",
  };
  return map[key];
};

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

export const prependHttp = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
};

/**
 * @param {T[]} list
 * @param {(item: T) => boolean} condition
 * @returns {number[]}
 */
export const getIndicesWithCondition = (list, condition) =>
  list.reduce((acc, curr, index) => {
    if (condition(curr)) acc.push(index);
    return acc;
  }, []);

/**
 * @param {number[]} indices
 * @returns {string}
 */
export const getDriverErrorMessagesWithIndices = (indices) => {
  let indicesInEnglish = "";
  if (indices.length === 0) return "";
  if (indices.length === 1) {
    indicesInEnglish = `${indices[0] + 1}`;
  } else if (indices.length === 2) {
    indicesInEnglish = `${indices[0] + 1} and ${indices[1] + 1}`;
  } else {
    indicesInEnglish = `${indices
      .slice(0, -1)
      .map((index) => index + 1)
      // Oxford comma, baby
      .join(", ")}, and ${indices.at(-1) + 1}`;
  }
  const isPlural = indices.length > 1;
  const driver = isPlural ? "Drivers" : "Driver";
  return `Please complete info for ${driver} ${indicesInEnglish}.`;
};

export const identicalContactsFound = (driver = {}, spouseDriver = {}) => {
  const namesmatch =
    driver.firstName &&
    spouseDriver.firstName &&
    driver.firstName.toLowerCase() === spouseDriver.firstName.toLowerCase();
  const dobMatch =
    driver.dob &&
    spouseDriver.dob &&
    moment(driver.dob).toLocaleString() ===
      moment(spouseDriver.dob).toLocaleString();
  return !!namesmatch && !!dobMatch;
};

export const clearAllEventStreams = (quoteEvents) => {
  if (Array.isArray(quoteEvents) && quoteEvents.length) {
    window.quoteEvents.forEach((event) => event.close());
  }
};
